import React from 'react';
import { FileText, Download, Eye } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const RecentDocuments = () => {
  const documents = [
    {
      id: '1',
      title: 'Facture - Mars 2024',
      type: 'invoice',
      date: '2024-03-15',
      status: 'pending'
    },
    {
      id: '2',
      title: 'Convention de soins',
      type: 'contract',
      date: '2024-03-10',
      status: 'signed'
    }
  ];

  return (
    <div className="space-y-4">
      {documents.map((document) => (
        <div
          key={document.id}
          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center space-x-3">
            <div className="h-10 w-10 rounded-lg bg-client-100 flex items-center justify-center">
              <FileText className="h-5 w-5 text-client-600" />
            </div>
            <div>
              <p className="font-medium">{document.title}</p>
              <p className="text-sm text-gray-500">
                {format(new Date(document.date), 'PP', { locale: fr })}
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <button
              className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              title="Voir"
            >
              <Eye className="h-4 w-4" />
            </button>
            <button
              className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              title="Télécharger"
            >
              <Download className="h-4 w-4" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentDocuments;