import React, { createContext, useContext, useState } from 'react';
import type { Mission, MissionRequest } from '../types/mission';

interface MissionContextType {
  missions: Mission[];
  missionRequests: MissionRequest[];
  addMission: (mission: Mission) => void;
  updateMission: (id: string, mission: Partial<Mission>) => void;
  deleteMission: (id: string) => void;
  addMissionRequest: (request: MissionRequest) => void;
  updateMissionRequest: (id: string, request: Partial<MissionRequest>) => void;
  deleteMissionRequest: (id: string) => void;
  getMissionsByStaff: (staffId: string) => Mission[];
  getMissionsByClient: (clientId: string) => Mission[];
  getMissionRequestsByClient: (clientId: string) => MissionRequest[];
}

const MissionContext = createContext<MissionContextType | undefined>(undefined);

export const MissionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [missions, setMissions] = useState<Mission[]>([]);
  const [missionRequests, setMissionRequests] = useState<MissionRequest[]>([]);

  const addMission = (mission: Mission) => {
    setMissions(prev => [...prev, mission]);
  };

  const updateMission = (id: string, missionData: Partial<Mission>) => {
    setMissions(prev => prev.map(mission =>
      mission.id === id ? { ...mission, ...missionData } : mission
    ));
  };

  const deleteMission = (id: string) => {
    setMissions(prev => prev.filter(mission => mission.id !== id));
  };

  const addMissionRequest = (request: MissionRequest) => {
    setMissionRequests(prev => [...prev, request]);
  };

  const updateMissionRequest = (id: string, requestData: Partial<MissionRequest>) => {
    setMissionRequests(prev => prev.map(request =>
      request.id === id ? { ...request, ...requestData } : request
    ));
  };

  const deleteMissionRequest = (id: string) => {
    setMissionRequests(prev => prev.filter(request => request.id !== id));
  };

  const getMissionsByStaff = (staffId: string) => {
    return missions.filter(m => m.staffId === staffId);
  };

  const getMissionsByClient = (clientId: string) => {
    return missions.filter(m => m.clientId === clientId);
  };

  const getMissionRequestsByClient = (clientId: string) => {
    return missionRequests.filter(r => r.clientId === clientId);
  };

  return (
    <MissionContext.Provider
      value={{
        missions,
        missionRequests,
        addMission,
        updateMission,
        deleteMission,
        addMissionRequest,
        updateMissionRequest,
        deleteMissionRequest,
        getMissionsByStaff,
        getMissionsByClient,
        getMissionRequestsByClient,
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export const useMission = () => {
  const context = useContext(MissionContext);
  if (context === undefined) {
    throw new Error('useMission must be used within a MissionProvider');
  }
  return context;
};