import { userStorage } from './storage';
import type { AuthUser, LoginCredentials } from '../types/auth';

export const authUtils = {
  validatePassword: (password: string): boolean => {
    // Au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  },

  hashPassword: async (password: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(password + 'healthcare-salt');  // Ajout d'un sel statique
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  },

  verifyPassword: async (password: string, hash: string): Promise<boolean> => {
    const hashedPassword = await authUtils.hashPassword(password);
    return hashedPassword === hash;
  },

  login: async (credentials: LoginCredentials): Promise<AuthUser> => {
    const { email, password, role } = credentials;
    
    const users = userStorage.getUsers();
    const user = users.find(u => u.email.toLowerCase() === email.toLowerCase());

    if (!user) {
      throw new Error('Email ou mot de passe incorrect');
    }

    if (role && user.role !== role) {
      throw new Error(`Ce compte n'est pas un compte ${role}`);
    }

    if (!user.passwordHash) {
      throw new Error('Erreur de configuration du compte');
    }

    const isValid = await authUtils.verifyPassword(password, user.passwordHash);
    if (!isValid) {
      throw new Error('Email ou mot de passe incorrect');
    }

    if (user.status !== 'active') {
      throw new Error('Ce compte est désactivé');
    }

    // Remove sensitive data before returning
    const { passwordHash, ...safeUser } = user;
    return safeUser;
  }
};