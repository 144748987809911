import React, { useState } from 'react';
import { Clock, MapPin, Calendar, AlertTriangle, CreditCard, CheckCircle, XCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import TimeEntryModal from './TimeEntryModal';
import type { Mission, TimeEntry } from '../../types/mission';

interface MissionCardProps {
  mission: Mission;
  onTimeEntry: (entry: Partial<TimeEntry>) => Promise<void>;
}

const MissionCard: React.FC<MissionCardProps> = ({ mission, onTimeEntry }) => {
  const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [timeEntryType, setTimeEntryType] = useState<'start' | 'end'>('start');

  const handleStartMission = () => {
    setTimeEntryType('start');
    setShowTimeEntryModal(true);
  };

  const handleEndMission = () => {
    setTimeEntryType('end');
    setShowTimeEntryModal(true);
  };

  const isStarted = mission.timeEntries?.some(
    entry => entry.type === 'start' && entry.status !== 'invalid'
  );

  const isCompleted = mission.timeEntries?.some(
    entry => entry.type === 'end' && entry.status !== 'invalid'
  );

  const getStatusIcon = () => {
    if (isCompleted) return <CheckCircle className="h-5 w-5 text-green-500" />;
    if (isStarted) return <Clock className="h-5 w-5 text-yellow-500" />;
    return <XCircle className="h-5 w-5 text-gray-400" />;
  };

  const getStatusText = () => {
    if (isCompleted) return 'Mission terminée';
    if (isStarted) return 'Mission en cours';
    return 'Mission à venir';
  };

  const calculateDuration = () => {
    const start = new Date(mission.startDate);
    const end = new Date(mission.endDate);
    const diffHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
    return `${diffHours} heure${diffHours > 1 ? 's' : ''}`;
  };

  const calculateEarnings = () => {
    const duration = (new Date(mission.endDate).getTime() - new Date(mission.startDate).getTime()) / (1000 * 60 * 60);
    return (duration * (mission.ratePerHour || 0)).toFixed(2);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <div>
          <div className="flex items-center space-x-2">
            {getStatusIcon()}
            <h3 className="font-medium text-lg">{mission.title}</h3>
          </div>
          <div className="flex items-center space-x-2 text-sm text-gray-500 mt-1">
            <Calendar className="h-4 w-4" />
            <span>
              {format(new Date(mission.startDate), 'PPp', { locale: fr })}
            </span>
          </div>
        </div>
        <div className={`px-3 py-1 rounded-full text-sm font-medium ${
          mission.priority === 'high'
            ? 'bg-red-100 text-red-800'
            : mission.priority === 'medium'
            ? 'bg-yellow-100 text-yellow-800'
            : 'bg-green-100 text-green-800'
        }`}>
          {mission.priority === 'high' ? 'Prioritaire' : 
           mission.priority === 'medium' ? 'Normal' : 'Basse'}
        </div>
      </div>

      <div className="space-y-3">
        <div className="flex items-center space-x-2 text-gray-600">
          <MapPin className="h-5 w-5" />
          <span>{mission.address.street}, {mission.address.city}</span>
        </div>
        <div className="flex items-center space-x-2 text-gray-600">
          <Clock className="h-5 w-5" />
          <span>
            {format(new Date(mission.startDate), 'HH:mm')} - {format(new Date(mission.endDate), 'HH:mm')}
            <span className="ml-2 text-gray-500">({calculateDuration()})</span>
          </span>
        </div>
        <div className="flex items-center space-x-2 text-gray-600">
          <CreditCard className="h-5 w-5" />
          <span>{mission.ratePerHour}€/h ({calculateEarnings()}€)</span>
        </div>
        {mission.description && (
          <div className="flex items-start space-x-2 text-gray-600">
            <AlertTriangle className="h-5 w-5 flex-shrink-0 mt-0.5" />
            <p>{mission.description}</p>
          </div>
        )}
      </div>

      <div className="mt-6 flex justify-between items-center">
        <span className="text-sm text-gray-500">{getStatusText()}</span>
        <div className="flex space-x-4">
          {!isStarted && (
            <button
              onClick={handleStartMission}
              className="btn bg-staff-600 text-white hover:bg-staff-700"
            >
              Débuter la mission
            </button>
          )}
          {isStarted && !isCompleted && (
            <button
              onClick={handleEndMission}
              className="btn bg-staff-600 text-white hover:bg-staff-700"
            >
              Terminer la mission
            </button>
          )}
        </div>
      </div>

      <TimeEntryModal
        isOpen={showTimeEntryModal}
        onClose={() => setShowTimeEntryModal(false)}
        mission={mission}
        type={timeEntryType}
        onSubmit={onTimeEntry}
      />
    </div>
  );
};

export default MissionCard;