import React, { useState } from 'react';
import { Filter } from 'lucide-react';
import HistoryList from '../../components/client/history/HistoryList';
import HistoryFilters from '../../components/client/history/HistoryFilters';
import type { Mission } from '../../types/mission';

const ClientHistory = () => {
  const [showFilters, setShowFilters] = useState(false);

  // Données mockées pour l'exemple
  const completedMissions: Mission[] = [
    {
      id: '1',
      title: 'Soins infirmiers quotidiens',
      clientId: '1',
      staffId: '1',
      startDate: '2024-02-01T09:00:00',
      endDate: '2024-02-28T10:00:00',
      status: 'completed',
      type: 'medical_care',
      priority: 'medium',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013',
        country: 'France'
      },
      description: 'Soins post-opératoires',
      ratePerHour: 35,
      timeEntries: [
        {
          id: '1',
          missionId: '1',
          staffId: '1',
          type: 'start',
          timestamp: '2024-02-01T09:00:00',
          location: {
            latitude: 48.8566,
            longitude: 2.3522,
            accuracy: 10,
            timestamp: '2024-02-01T09:00:00'
          },
          status: 'valid'
        },
        {
          id: '2',
          missionId: '1',
          staffId: '1',
          type: 'end',
          timestamp: '2024-02-28T10:00:00',
          location: {
            latitude: 48.8566,
            longitude: 2.3522,
            accuracy: 10,
            timestamp: '2024-02-28T10:00:00'
          },
          status: 'valid'
        }
      ]
    }
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Historique des missions</h1>
          <p className="text-sm text-gray-600 mt-1">
            Consultez l'historique de vos missions terminées
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      {showFilters && <HistoryFilters />}
      
      <HistoryList missions={completedMissions} />
    </div>
  );
};

export default ClientHistory;