import React from 'react';
import { Search, Calendar } from 'lucide-react';

const HistoryFilters = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une mission..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
              placeholder="Date de début"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
              placeholder="Date de fin"
            />
          </div>
        </div>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500">
          <option value="">Tous les types</option>
          <option value="medical_care">Soins médicaux</option>
          <option value="home_assistance">Aide à domicile</option>
        </select>
      </div>
    </div>
  );
};

export default HistoryFilters;