import React from 'react';
import { User, Edit2, Trash2 } from 'lucide-react';
import type { AdminUser } from '../../types/user';

interface AdminListProps {
  admins: AdminUser[];
  onEdit: (admin: AdminUser) => void;
  onDelete: (id: string) => void;
}

const AdminList: React.FC<AdminListProps> = ({ admins, onEdit, onDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {admins.map((admin) => (
        <div
          key={admin.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-primary-100 flex items-center justify-center">
                {admin.avatar ? (
                  <img
                    src={admin.avatar}
                    alt={`${admin.firstName} ${admin.lastName}`}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                ) : (
                  <User className="h-6 w-6 text-primary-600" />
                )}
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">
                  {admin.firstName} {admin.lastName}
                </h3>
                <p className="text-sm text-gray-500">{admin.email}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => onEdit(admin)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Modifier"
              >
                <Edit2 className="h-4 w-4" />
              </button>
              <button
                onClick={() => onDelete(admin.id)}
                className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                title="Supprimer"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-gray-500">Département</p>
              <p className="font-medium">{admin.department || 'Non spécifié'}</p>
            </div>
            <div>
              <p className="text-gray-500">Téléphone</p>
              <p className="font-medium">{admin.phoneNumber || 'Non spécifié'}</p>
            </div>
            <div className="col-span-2">
              <p className="text-gray-500">Contact d'urgence</p>
              <p className="font-medium">
                {admin.emergencyContact
                  ? `${admin.emergencyContact.name} (${admin.emergencyContact.relationship})`
                  : 'Non spécifié'}
              </p>
            </div>
          </div>

          <div className="mt-4 pt-4 border-t border-gray-200">
            <p className="text-sm text-gray-500">
              Dernière connexion: {admin.lastLogin
                ? new Date(admin.lastLogin).toLocaleDateString('fr-FR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                : 'Jamais'}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminList;