import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { Calendar, Download } from 'lucide-react';
import type { Mission } from '../../types/mission';

interface ModernCalendarProps {
  missions: Mission[];
  staffAvailabilities: {
    [staffId: string]: {
      name: string;
      role: string;
      availabilities: {
        [date: string]: {
          morning: boolean;
          afternoon: boolean;
          night: boolean;
        };
      };
    };
  };
  onEventClick: (info: any) => void;
  onDateSelect: (info: any) => void;
}

const ModernCalendar: React.FC<ModernCalendarProps> = ({
  missions,
  staffAvailabilities,
  onEventClick,
  onDateSelect,
}) => {
  const [showSyncModal, setShowSyncModal] = useState(false);

  // Générer une couleur unique pour chaque membre du personnel
  const getStaffColor = (staffId: string) => {
    const colors = [
      '#F6207F', // Rose (couleur principale)
      '#10B981', // Vert
      '#3B82F6', // Bleu
      '#F59E0B', // Orange
      '#8B5CF6', // Violet
      '#EC4899'  // Rose foncé
    ];
    const index = parseInt(staffId.replace('staff-', '')) % colors.length;
    return colors[index];
  };

  // Convertir les missions en événements
  const missionEvents = missions.map(mission => ({
    id: mission.id,
    title: `${mission.title} - ${mission.staffName || 'Non assigné'}`,
    start: mission.startDate,
    end: mission.endDate,
    backgroundColor: mission.staffId ? getStaffColor(mission.staffId) : '#94A3B8',
    borderColor: mission.staffId ? getStaffColor(mission.staffId) : '#94A3B8',
    textColor: '#FFFFFF',
    extendedProps: {
      type: 'mission',
      mission,
    },
  }));

  // Convertir les disponibilités en événements
  const availabilityEvents = Object.entries(staffAvailabilities).flatMap(([staffId, staffData]) => {
    return Object.entries(staffData.availabilities).flatMap(([date, periods]) => {
      const events = [];
      const staffColor = getStaffColor(staffId);

      if (periods.morning) {
        events.push({
          title: `${staffData.name} - Matin`,
          start: `${date}T08:00:00`,
          end: `${date}T12:00:00`,
          backgroundColor: `${staffColor}33`,
          borderColor: staffColor,
          textColor: staffColor,
          extendedProps: {
            type: 'availability',
            staffId,
            staffName: staffData.name,
            period: 'morning',
          },
        });
      }

      if (periods.afternoon) {
        events.push({
          title: `${staffData.name} - Après-midi`,
          start: `${date}T14:00:00`,
          end: `${date}T18:00:00`,
          backgroundColor: `${staffColor}33`,
          borderColor: staffColor,
          textColor: staffColor,
          extendedProps: {
            type: 'availability',
            staffId,
            staffName: staffData.name,
            period: 'afternoon',
          },
        });
      }

      if (periods.night) {
        events.push({
          title: `${staffData.name} - Nuit`,
          start: `${date}T20:00:00`,
          end: `${date}T23:59:59`,
          backgroundColor: `${staffColor}33`,
          borderColor: staffColor,
          textColor: staffColor,
          extendedProps: {
            type: 'availability',
            staffId,
            staffName: staffData.name,
            period: 'night',
          },
        });
      }

      return events;
    });
  });

  // Combiner tous les événements
  const events = [...missionEvents, ...availabilityEvents];

  const handleExportCalendar = () => {
    setShowSyncModal(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        {/* Légende du personnel */}
        <div className="flex flex-wrap gap-2">
          {Object.entries(staffAvailabilities).map(([staffId, staffData]) => (
            <div
              key={staffId}
              className="flex items-center space-x-2 px-3 py-1 rounded-full text-sm"
              style={{
                backgroundColor: `${getStaffColor(staffId)}33`,
                color: getStaffColor(staffId),
                borderColor: getStaffColor(staffId)
              }}
            >
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: getStaffColor(staffId) }}
              />
              <span>{staffData.name}</span>
            </div>
          ))}
        </div>

        <button
          onClick={handleExportCalendar}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Calendar className="h-5 w-5" />
          <span>Exporter</span>
        </button>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        locale={frLocale}
        events={events}
        eventClick={onEventClick}
        select={onDateSelect}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        allDaySlot={false}
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        height="auto"
        eventContent={({ event }) => (
          <div className="p-1">
            <div className="font-medium">{event.title}</div>
            {event.extendedProps.type === 'mission' && (
              <div className="text-xs opacity-75">
                {new Date(event.start!).toLocaleTimeString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' - '}
                {new Date(event.end!).toLocaleTimeString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}
          </div>
        )}
      />

      {showSyncModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-md w-full p-6">
              <h3 className="text-lg font-medium mb-4">Exporter le calendrier</h3>
              <div className="space-y-4">
                <button
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/api/calendar/export';
                    link.download = 'calendar.ics';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  className="w-full flex items-center justify-between p-4 bg-white border rounded-lg hover:bg-gray-50"
                >
                  <span>Télécharger le fichier ICS</span>
                  <Download className="h-5 w-5 text-gray-600" />
                </button>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => setShowSyncModal(false)}
                  className="w-full btn btn-secondary"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModernCalendar;