import React from 'react';
import type { SystemSettings } from '../../types/settings';

interface NotificationSettingsProps {
  settings: SystemSettings['notifications'];
  onChange: (settings: SystemSettings['notifications']) => void;
}

const NotificationSettings: React.FC<NotificationSettingsProps> = ({ settings, onChange }) => {
  const handleChange = (field: string, value: boolean | number) => {
    onChange({
      ...settings,
      [field]: value
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900 mb-4">Paramètres des notifications</h2>
        
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Notifications par email
              </label>
              <p className="text-sm text-gray-500">
                Recevoir les notifications par email
              </p>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.emailNotifications}
                onChange={(e) => handleChange('emailNotifications', e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Notifications SMS
              </label>
              <p className="text-sm text-gray-500">
                Recevoir les notifications par SMS
              </p>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.smsNotifications}
                onChange={(e) => handleChange('smsNotifications', e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Notifications push
              </label>
              <p className="text-sm text-gray-500">
                Recevoir les notifications push dans le navigateur
              </p>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.pushNotifications}
                onChange={(e) => handleChange('pushNotifications', e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <label className="text-sm font-medium text-gray-700">
                Résumé quotidien
              </label>
              <p className="text-sm text-gray-500">
                Recevoir un résumé quotidien des activités
              </p>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={settings.dailyDigest}
                onChange={(e) => handleChange('dailyDigest', e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Délai de rappel (heures)
            </label>
            <input
              type="number"
              min="1"
              max="72"
              value={settings.reminderDelay}
              onChange={(e) => handleChange('reminderDelay', parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
            <p className="mt-1 text-sm text-gray-500">
              Délai avant l'envoi d'un rappel pour les tâches non effectuées
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;