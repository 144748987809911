import React, { createContext, useContext, useState } from 'react';
import type { Notification, NotificationType } from '../types/notification';

interface NotificationContextType {
  notifications: Notification[];
  createNotification: (notification: {
    type: NotificationType;
    title: string;
    message: string;
    category: string;
    userId?: string;
    role?: 'admin' | 'staff' | 'client';
    metadata?: Record<string, any>;
  }) => void;
  markAsRead: (id: string) => void;
  deleteNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const createNotification = ({
    type,
    title,
    message,
    category,
    userId,
    role,
    metadata
  }: {
    type: NotificationType;
    title: string;
    message: string;
    category: string;
    userId?: string;
    role?: 'admin' | 'staff' | 'client';
    metadata?: Record<string, any>;
  }) => {
    const newNotification: Notification = {
      id: Math.random().toString(36).substr(2, 9),
      type,
      category,
      title,
      message,
      timestamp: new Date().toISOString(),
      read: false,
      metadata,
    };

    setNotifications(prev => [newNotification, ...prev]);
  };

  const markAsRead = (id: string) => {
    setNotifications(prev =>
      prev.map(notification =>
        notification.id === id
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  const deleteNotification = (id: string) => {
    setNotifications(prev =>
      prev.filter(notification => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        createNotification,
        markAsRead,
        deleteNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};