import { userStorage } from '../utils/storage';
import { authUtils } from '../utils/auth';
import type { AuthUser } from '../types/auth';

const defaultUsers = [
  {
    email: 'admin@hopecareinterim.fr',
    password: 'Admin123!',
    firstName: 'Admin',
    lastName: 'System',
    role: 'admin',
    status: 'active'
  },
  {
    email: 'staff@hopecareinterim.fr',
    password: 'Staff123!',
    firstName: 'Marie',
    lastName: 'Dubois',
    role: 'staff',
    status: 'active'
  },
  {
    email: 'client@hopecareinterim.fr',
    password: 'Client123!',
    firstName: 'Jean',
    lastName: 'Martin',
    role: 'client',
    status: 'active',
    organization: 'Centre Médical Saint-Jean'
  }
];

export const initializeDefaultAccounts = async () => {
  try {
    // Clear existing data to ensure clean state
    userStorage.clearAll();
    
    console.log('Initializing default accounts...');
    
    const users: AuthUser[] = await Promise.all(
      defaultUsers.map(async (user) => {
        const passwordHash = await authUtils.hashPassword(user.password);
        
        return {
          id: crypto.randomUUID(),
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role as AuthUser['role'],
          status: user.status as 'active' | 'inactive',
          organization: user.organization,
          passwordHash,
          createdAt: new Date().toISOString()
        };
      })
    );
    
    userStorage.setUsers(users);
    console.log('Default accounts created successfully');
  } catch (error) {
    console.error('Error initializing default accounts:', error);
  }
};