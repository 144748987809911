import React from 'react';
import { Clock, MapPin, CheckCircle, XCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { TimeEntry } from '../../../types/mission';

interface TimeEntriesListProps {
  timeEntries: TimeEntry[];
}

const TimeEntriesList: React.FC<TimeEntriesListProps> = ({ timeEntries }) => {
  const groupByDate = (entries: TimeEntry[]) => {
    const groups: { [key: string]: TimeEntry[] } = {};
    entries.forEach(entry => {
      const date = format(new Date(entry.timestamp), 'yyyy-MM-dd');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(entry);
    });
    return groups;
  };

  const groupedEntries = groupByDate(timeEntries);

  return (
    <div className="space-y-6">
      {Object.entries(groupedEntries).map(([date, entries]) => (
        <div key={date}>
          <h3 className="text-lg font-medium mb-4">
            {format(new Date(date), 'EEEE d MMMM yyyy', { locale: fr })}
          </h3>
          <div className="space-y-4">
            {entries.map((entry) => (
              <div
                key={entry.id}
                className="bg-white rounded-lg shadow-sm p-6"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-4">
                    <div className={`h-10 w-10 rounded-full flex items-center justify-center ${
                      entry.status === 'valid'
                        ? 'bg-green-100'
                        : 'bg-red-100'
                    }`}>
                      {entry.status === 'valid' ? (
                        <CheckCircle className="h-5 w-5 text-green-600" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-600" />
                      )}
                    </div>
                    <div>
                      <p className="font-medium">
                        {entry.type === 'start' ? 'Début de mission' : 'Fin de mission'}
                      </p>
                      <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <Clock className="h-4 w-4" />
                        <span>
                          {format(new Date(entry.timestamp), 'HH:mm', { locale: fr })}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      entry.status === 'valid'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {entry.status === 'valid' ? 'Validé' : 'Invalide'}
                    </span>
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center space-x-2 text-sm text-gray-600">
                    <MapPin className="h-4 w-4" />
                    <span>
                      {entry.location.latitude.toFixed(6)}, {entry.location.longitude.toFixed(6)}
                    </span>
                  </div>
                  {entry.notes && (
                    <p className="text-sm text-gray-600">{entry.notes}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeEntriesList;