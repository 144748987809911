import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Home, 
  Users, 
  Briefcase, 
  Calendar, 
  FileText, 
  Bell, 
  Settings,
  MessageSquare,
  Receipt,
  X
} from 'lucide-react';

interface SidebarProps {
  onClose?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose }) => {
  const menuItems = [
    { icon: Home, label: 'Tableau de bord', path: '/' },
    { icon: Users, label: 'Collaborateurs', path: '/staff' },
    { icon: Users, label: 'Clients', path: '/clients' },
    { icon: Briefcase, label: 'Missions', path: '/missions' },
    { icon: Calendar, label: 'Planning', path: '/planning' },
    { icon: FileText, label: 'Documents', path: '/documents' },
    { icon: Receipt, label: 'Facturation', path: '/billing' },
    { icon: MessageSquare, label: 'Messages', path: '/messages' },
    { icon: Bell, label: 'Notifications', path: '/notifications' },
    { icon: Settings, label: 'Paramètres', path: '/settings' }
  ];

  return (
    <div className="h-full flex flex-col bg-primary-900 text-white">
      <div className="flex items-center justify-between p-4">
        <h1 className="text-xl font-bold">Hope-Care Intérim</h1>
        {onClose && (
          <button 
            onClick={onClose}
            className="lg:hidden p-2 rounded-md text-primary-200 hover:text-white hover:bg-primary-800"
          >
            <X className="h-6 w-6" />
          </button>
        )}
      </div>

      <nav className="flex-1 px-2 py-4 space-y-1">
        {menuItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            onClick={onClose}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-sm rounded-lg transition-colors ${
                isActive
                  ? 'bg-primary-800 text-white'
                  : 'text-primary-100 hover:bg-primary-800 hover:text-white'
              }`
            }
          >
            <item.icon className="h-5 w-5 mr-3" />
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;