import React from 'react';
import { Clock, Calendar, CheckCircle, XCircle } from 'lucide-react';
import type { TimeEntry } from '../../../types/mission';

interface TimeEntryStatsProps {
  timeEntries: TimeEntry[];
}

const TimeEntryStats: React.FC<TimeEntryStatsProps> = ({ timeEntries }) => {
  const totalEntries = timeEntries.length;
  const validEntries = timeEntries.filter(entry => entry.status === 'valid').length;
  const invalidEntries = timeEntries.filter(entry => entry.status === 'invalid').length;

  const calculateTotalHours = () => {
    let totalMinutes = 0;
    const entriesByMission: { [key: string]: TimeEntry[] } = {};

    // Grouper les entrées par mission
    timeEntries.forEach(entry => {
      if (!entriesByMission[entry.missionId]) {
        entriesByMission[entry.missionId] = [];
      }
      entriesByMission[entry.missionId].push(entry);
    });

    // Calculer la durée pour chaque mission
    Object.values(entriesByMission).forEach(missionEntries => {
      const start = missionEntries.find(entry => entry.type === 'start');
      const end = missionEntries.find(entry => entry.type === 'end');

      if (start && end) {
        const duration = new Date(end.timestamp).getTime() - new Date(start.timestamp).getTime();
        totalMinutes += duration / (1000 * 60);
      }
    });

    return Math.round(totalMinutes / 60);
  };

  const totalHours = calculateTotalHours();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Total des heures</p>
            <p className="text-2xl font-semibold mt-2">{totalHours}h</p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <Clock className="h-6 w-6 text-staff-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Pointages</p>
            <p className="text-2xl font-semibold mt-2">{totalEntries}</p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <Calendar className="h-6 w-6 text-staff-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Pointages validés</p>
            <p className="text-2xl font-semibold mt-2">{validEntries}</p>
          </div>
          <div className="p-3 rounded-full bg-green-100">
            <CheckCircle className="h-6 w-6 text-green-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Pointages invalides</p>
            <p className="text-2xl font-semibold mt-2">{invalidEntries}</p>
          </div>
          <div className="p-3 rounded-full bg-red-100">
            <XCircle className="h-6 w-6 text-red-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeEntryStats;