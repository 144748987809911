import React, { useState } from 'react';
import { Calendar, Download, Filter } from 'lucide-react';
import PaymentsList from '../../components/staff/payments/PaymentsList';
import PaymentStats from '../../components/staff/payments/PaymentStats';
import PaymentFilters from '../../components/staff/payments/PaymentFilters';

const StaffPayments = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  // Données mockées pour l'exemple
  const payments = [
    {
      id: '1',
      period: 'Mars 2024',
      amount: 3250.00,
      status: 'paid',
      paidAt: '2024-03-15T10:00:00',
      details: {
        totalHours: 120,
        regularHours: 100,
        overtimeHours: 20,
        regularRate: 25,
        overtimeRate: 37.50,
        missions: 18
      }
    },
    {
      id: '2',
      period: 'Février 2024',
      amount: 2800.00,
      status: 'paid',
      paidAt: '2024-02-15T10:00:00',
      details: {
        totalHours: 105,
        regularHours: 95,
        overtimeHours: 10,
        regularRate: 25,
        overtimeRate: 37.50,
        missions: 15
      }
    }
  ];

  const handleDownloadInvoice = (paymentId: string) => {
    console.log('Download invoice:', paymentId);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Mes paiements</h1>
          <p className="text-sm text-gray-600 mt-1">
            Suivi des paiements et factures
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      <PaymentStats payments={payments} />
      
      {showFilters && <PaymentFilters />}
      
      <PaymentsList
        payments={payments}
        onDownload={handleDownloadInvoice}
      />
    </div>
  );
};

export default StaffPayments;