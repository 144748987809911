import React, { useState, useRef } from 'react';
import { User, Mail, Phone, MapPin, Calendar, Award, Building2, CreditCard, FileText, Edit2, Camera } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase/client';
import ProfileEditModal from '../../components/staff/ProfileEditModal';

const StaffProfile = () => {
  const { user, updateUser } = useAuth();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Mock data for example
  const staffProfile = {
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone || '+33 6 12 34 56 78',
    role: 'nurse',
    contractType: 'independent',
    inamiNumber: user?.inamiNumber || '12345678901',
    tvaNumber: user?.tvaNumber || 'BE0123456789',
    ratePerHour: user?.ratePerHour || 35,
    address: user?.address || {
      street: '15 Rue de la Santé',
      city: 'Paris',
      postalCode: '75013',
      country: 'France'
    },
    startDate: user?.startDate || '2023-01-15',
    specializations: user?.specializations || ['Gériatrie', 'Soins palliatifs'],
    documents: user?.documents || []
  };

  const handleEditProfile = () => {
    setIsEditModalOpen(true);
  };

  const handleUpdateProfile = async (data: any) => {
    try {
      await updateUser(data);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user?.id) return;

    try {
      setIsUploading(true);
      
      // Upload to Supabase Storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}-${Math.random()}.${fileExt}`;
      const filePath = `avatars/${fileName}`;

      const { error: uploadError, data } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      // Update user profile
      await updateUser({
        id: user.id,
        avatar: publicUrl
      });
    } catch (error) {
      console.error('Error uploading avatar:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Mon profil</h1>
          <button
            onClick={handleEditProfile}
            className="btn bg-staff-600 text-white hover:bg-staff-700 flex items-center space-x-2"
          >
            <Edit2 className="h-5 w-5" />
            <span>Modifier le profil</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Information */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center space-x-4 mb-6">
              <div className="relative">
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <div 
                  className="h-24 w-24 rounded-full bg-staff-100 flex items-center justify-center relative cursor-pointer group"
                  onClick={handleAvatarClick}
                >
                  {user?.avatar ? (
                    <img
                      src={user.avatar}
                      alt={`${user.firstName} ${user.lastName}`}
                      className="h-24 w-24 rounded-full object-cover"
                    />
                  ) : (
                    <User className="h-12 w-12 text-staff-600" />
                  )}
                  <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <Camera className="h-6 w-6 text-white" />
                  </div>
                  {isUploading && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <h2 className="text-xl font-semibold">
                  {staffProfile.firstName} {staffProfile.lastName}
                </h2>
                <div className="flex items-center space-x-2 mt-1">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-staff-100 text-staff-800">
                    Infirmier indépendant
                  </span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Mail className="h-5 w-5 text-gray-400" />
                  <span>{staffProfile.email}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Phone className="h-5 w-5 text-gray-400" />
                  <span>{staffProfile.phone}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <MapPin className="h-5 w-5 text-gray-400" />
                  <span>
                    {staffProfile.address.street}, {staffProfile.address.postalCode} {staffProfile.address.city}
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <Calendar className="h-5 w-5 text-gray-400" />
                  <span>Membre depuis le {new Date(staffProfile.startDate).toLocaleDateString('fr-FR')}</span>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Award className="h-5 w-5 text-gray-400" />
                  <span>N° INAMI: {staffProfile.inamiNumber}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Building2 className="h-5 w-5 text-gray-400" />
                  <span>N° TVA: {staffProfile.tvaNumber}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CreditCard className="h-5 w-5 text-gray-400" />
                  <span>Taux horaire: {staffProfile.ratePerHour}€/h</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Spécialisations</h3>
            <div className="flex flex-wrap gap-2">
              {staffProfile.specializations.map((spec, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-staff-100 text-staff-800"
                >
                  {spec}
                </span>
              ))}
            </div>
          </div>
        </div>

        {/* Documents */}
        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Documents</h3>
            <div className="space-y-4">
              {staffProfile.documents.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-10 w-10 rounded-lg bg-staff-100 flex items-center justify-center">
                      <FileText className="h-5 w-5 text-staff-600" />
                    </div>
                    <div>
                      <p className="font-medium">{doc.name}</p>
                      <div className="flex items-center space-x-2 mt-1">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                          doc.verified
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {doc.verified ? 'Vérifié' : 'En attente'}
                        </span>
                        {doc.expiryDate && (
                          <span className="text-xs text-gray-500">
                            Expire le {new Date(doc.expiryDate).toLocaleDateString('fr-FR')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-staff-600 hover:text-staff-700 text-sm font-medium"
                  >
                    Voir
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ProfileEditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        profile={staffProfile}
        onSubmit={handleUpdateProfile}
      />
    </div>
  );
};

export default StaffProfile;