import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import ClientList from '../components/clients/ClientList';
import ClientFilters from '../components/clients/ClientFilters';
import ClientModal from '../components/clients/ClientModal';
import type { Client, ClientFilters as Filters } from '../types/client';

const mockClients: Client[] = [
  {
    id: '1',
    type: 'organization',
    name: 'Centre Médical Saint-Jean',
    contactPerson: 'Dr. Sophie Laurent',
    email: 'contact@cm-saintjean.fr',
    phone: '+33 1 23 45 67 89',
    address: {
      street: '15 Rue de la Santé',
      city: 'Paris',
      postalCode: '75013',
      country: 'France'
    },
    status: 'active',
    registrationDate: '2023-01-15',
    notes: 'Centre médical spécialisé en gériatrie'
  },
  {
    id: '2',
    type: 'individual',
    name: 'Martin Robert',
    email: 'martin.robert@email.com',
    phone: '+33 6 12 34 56 78',
    address: {
      street: '8 Avenue des Lilas',
      city: 'Lyon',
      postalCode: '69003',
      country: 'France'
    },
    status: 'active',
    registrationDate: '2023-03-20'
  }
];

const Clients = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [clients, setClients] = useState<Client[]>(mockClients);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateClient = () => {
    setSelectedClient(undefined);
    setIsModalOpen(true);
  };

  const handleEditClient = (client: Client) => {
    setSelectedClient(client);
    setIsModalOpen(true);
  };

  const handleDeleteClient = (id: string) => {
    setClients(clients.filter(client => client.id !== id));
  };

  const handleSubmitClient = (clientData: Partial<Client>) => {
    if (selectedClient) {
      // Modification
      setClients(clients.map(client =>
        client.id === selectedClient.id
          ? { ...client, ...clientData }
          : client
      ));
    } else {
      // Création
      const newClient: Client = {
        id: Math.random().toString(36).substr(2, 9),
        ...clientData,
      } as Client;
      setClients([...clients, newClient]);
    }
  };

  const filteredClients = clients.filter((client) => {
    if (filters.type && client.type !== filters.type) return false;
    if (filters.status && client.status !== filters.status) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        client.name.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query) ||
        (client.contactPerson?.toLowerCase().includes(query) ?? false)
      );
    }
    return true;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Gestion des clients</h1>
        <button
          onClick={handleCreateClient}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter un client</span>
        </button>
      </div>

      <ClientFilters filters={filters} onFilterChange={setFilters} />
      <ClientList
        clients={filteredClients}
        onEdit={handleEditClient}
        onDelete={handleDeleteClient}
      />

      <ClientModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        client={selectedClient}
        onSubmit={handleSubmitClient}
      />
    </div>
  );
};

export default Clients;