import React, { useState, useRef } from 'react';
import { X, Upload, FileText, Trash2 } from 'lucide-react';

interface Document {
  id: string;
  type: 'id' | 'diploma' | 'inami' | 'insurance' | 'other';
  name: string;
  file?: File;
  url?: string;
  uploadDate?: string;
  expiryDate?: string;
}

interface ProfileEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  profile: any;
  onSubmit: (data: any) => void;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({
  isOpen,
  onClose,
  profile,
  onSubmit,
}) => {
  const [formData, setFormData] = useState(profile);
  const [newDocuments, setNewDocuments] = useState<Document[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentDocumentType, setCurrentDocumentType] = useState<Document['type']>('other');

  const handleChange = (field: string, value: any) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newDocument: Document = {
        id: Math.random().toString(36).substr(2, 9),
        type: currentDocumentType,
        name: file.name,
        file: file,
        uploadDate: new Date().toISOString(),
      };
      setNewDocuments([...newDocuments, newDocument]);
    }
  };

  const handleUploadClick = (type: Document['type']) => {
    setCurrentDocumentType(type);
    fileInputRef.current?.click();
  };

  const handleRemoveDocument = (documentId: string) => {
    setNewDocuments(newDocuments.filter(doc => doc.id !== documentId));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      newDocuments,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-4xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              Modifier mon profil
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Informations personnelles */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-4">Informations personnelles</h3>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Téléphone</label>
                  <input
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => handleChange('phone', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                  />
                </div>
              </div>
            </div>

            {/* Adresse */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-4">Adresse</h3>
              <div className="grid grid-cols-1 gap-4">
                <input
                  type="text"
                  placeholder="Rue"
                  value={formData.address.street}
                  onChange={(e) => handleChange('address.street', e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                />
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Ville"
                    value={formData.address.city}
                    onChange={(e) => handleChange('address.city', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                  />
                  <input
                    type="text"
                    placeholder="Code postal"
                    value={formData.address.postalCode}
                    onChange={(e) => handleChange('address.postalCode', e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                  />
                </div>
              </div>
            </div>

            {/* Documents */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-4">Documents</h3>
              
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileSelect}
                accept=".pdf,.jpg,.jpeg,.png"
              />

              <div className="grid grid-cols-2 gap-4 mb-4">
                <button
                  type="button"
                  onClick={() => handleUploadClick('id')}
                  className="flex items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-staff-500"
                >
                  <div className="text-center">
                    <Upload className="mx-auto h-6 w-6 text-gray-400" />
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      Carte d'identité
                    </span>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => handleUploadClick('diploma')}
                  className="flex items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-staff-500"
                >
                  <div className="text-center">
                    <Upload className="mx-auto h-6 w-6 text-gray-400" />
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      Diplôme
                    </span>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => handleUploadClick('inami')}
                  className="flex items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-staff-500"
                >
                  <div className="text-center">
                    <Upload className="mx-auto h-6 w-6 text-gray-400" />
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      Attestation INAMI
                    </span>
                  </div>
                </button>

                <button
                  type="button"
                  onClick={() => handleUploadClick('insurance')}
                  className="flex items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-staff-500"
                >
                  <div className="text-center">
                    <Upload className="mx-auto h-6 w-6 text-gray-400" />
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      Assurance professionnelle
                    </span>
                  </div>
                </button>
              </div>

              {/* Liste des nouveaux documents */}
              {newDocuments.length > 0 && (
                <div className="mt-4 space-y-2">
                  <h4 className="text-sm font-medium text-gray-700">Documents à téléverser</h4>
                  {newDocuments.map((doc) => (
                    <div
                      key={doc.id}
                      className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                    >
                      <div className="flex items-center space-x-3">
                        <FileText className="h-5 w-5 text-gray-400" />
                        <div>
                          <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                          <p className="text-xs text-gray-500">
                            {new Date(doc.uploadDate!).toLocaleDateString('fr-FR')}
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveDocument(doc.id)}
                        className="p-1 text-gray-400 hover:text-red-500"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn bg-staff-600 text-white hover:bg-staff-700"
              >
                Enregistrer les modifications
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;