import React from 'react';
import { Search, Calendar } from 'lucide-react';
import type { BillingFilters } from '../../types/billing';

interface BillingFiltersProps {
  filters: BillingFilters;
  onFilterChange: (filters: BillingFilters) => void;
}

const BillingFilters: React.FC<BillingFiltersProps> = ({
  filters,
  onFilterChange,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher une facture..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.searchQuery || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, searchQuery: e.target.value })
                }
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.status || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  status: e.target.value as BillingFilters['status'],
                })
              }
            >
              <option value="">Tous les statuts</option>
              <option value="draft">Brouillon</option>
              <option value="sent">Envoyée</option>
              <option value="paid">Payée</option>
              <option value="overdue">En retard</option>
              <option value="cancelled">Annulée</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.startDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, startDate: e.target.value })
                }
              />
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.endDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, endDate: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <input
              type="number"
              placeholder="Montant min"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.minAmount || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  minAmount: e.target.value ? Number(e.target.value) : undefined,
                })
              }
            />
            <input
              type="number"
              placeholder="Montant max"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.maxAmount || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  maxAmount: e.target.value ? Number(e.target.value) : undefined,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingFilters;