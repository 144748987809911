import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface SignaturePadProps {
  onSave: (signature: string) => void;
  onClear: () => void;
}

const SignaturePad: React.FC<SignaturePadProps> = ({ onSave, onClear }) => {
  const signatureRef = useRef<SignatureCanvas>(null);

  const handleSave = () => {
    if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      onSave(signatureData);
    }
  };

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      onClear();
    }
  };

  return (
    <div className="space-y-4">
      <div className="border-2 border-gray-300 rounded-lg">
        <SignatureCanvas
          ref={signatureRef}
          canvasProps={{
            className: 'w-full h-64',
          }}
          backgroundColor="white"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={handleClear}
          className="btn btn-secondary"
        >
          Effacer
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="btn btn-primary"
        >
          Valider la signature
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;