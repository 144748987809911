import React, { useState } from 'react';
import { FileText, Filter, Download, Eye, Upload, Plus } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import DocumentViewer from '../../components/documents/DocumentViewer';
import DocumentUploadModal from '../../components/staff/documents/DocumentUploadModal';
import SignatureModal from '../../components/documents/SignatureModal';
import type { Document } from '../../types/document';

const StaffDocuments = () => {
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [showViewer, setShowViewer] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  // Données mockées pour l'exemple
  const documents = [
    {
      id: '1',
      type: 'contract',
      title: 'Contrat de prestation',
      description: 'Contrat de prestation de services',
      status: 'pending_signature',
      createdAt: '2024-03-01T10:00:00',
      content: 'Contenu du contrat...',
      signers: [
        {
          id: '1',
          name: 'Marie Dubois',
          email: 'marie.dubois@example.com',
          role: 'staff',
          status: 'pending'
        }
      ]
    },
    {
      id: '2',
      type: 'invoice',
      title: 'Facture - Février 2024',
      status: 'draft',
      createdAt: '2024-03-15T09:00:00',
      content: 'Contenu de la facture...',
      signers: [
        {
          id: '1',
          name: 'Marie Dubois',
          email: 'marie.dubois@example.com',
          role: 'staff',
          status: 'pending'
        }
      ]
    }
  ];

  const handleDownload = (document: Document) => {
    // Logique de téléchargement à implémenter
    console.log('Download document:', document.id);
  };

  const handleView = (document: Document) => {
    setSelectedDocument(document);
    setShowViewer(true);
  };

  const handleUpload = () => {
    setShowUploadModal(true);
  };

  const handleSubmitUpload = (data: any) => {
    console.log('Upload document:', data);
    setShowUploadModal(false);
  };

  const handleSign = (document: Document) => {
    setSelectedDocument(document);
    setShowSignatureModal(true);
  };

  const handleSubmitSignature = (signatureData: string) => {
    console.log('Sign document:', selectedDocument?.id, signatureData);
    setShowSignatureModal(false);
    setSelectedDocument(null);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Mes documents</h1>
        <button
          onClick={handleUpload}
          className="btn bg-staff-600 text-white hover:bg-staff-700 flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Nouveau document</span>
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((document) => (
          <div
            key={document.id}
            className="bg-white rounded-lg shadow-sm p-6"
          >
            <div className="flex items-center space-x-4 mb-4">
              <div className="h-12 w-12 rounded-lg bg-staff-100 flex items-center justify-center">
                <FileText className="h-6 w-6 text-staff-600" />
              </div>
              <div>
                <h3 className="font-medium text-gray-900">{document.title}</h3>
                <p className="text-sm text-gray-500">
                  {format(new Date(document.createdAt), 'dd MMMM yyyy', { locale: fr })}
                </p>
              </div>
            </div>

            <div className="space-y-2 mb-4">
              {document.description && (
                <p className="text-sm text-gray-600">{document.description}</p>
              )}
              <div className="flex items-center space-x-2">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  document.status === 'signed'
                    ? 'bg-green-100 text-green-800'
                    : document.status === 'pending_signature'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {document.status === 'signed' ? 'Signé' : 
                   document.status === 'pending_signature' ? 'En attente de signature' : 
                   'Brouillon'}
                </span>
                {document.status === 'signed' && document.signers[0].signedAt && (
                  <span className="text-xs text-gray-500">
                    Signé le {format(new Date(document.signers[0].signedAt), 'dd/MM/yyyy', { locale: fr })}
                  </span>
                )}
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={() => handleView(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Voir"
              >
                <Eye className="h-5 w-5" />
              </button>
              {document.status === 'pending_signature' && (
                <button
                  onClick={() => handleSign(document)}
                  className="btn bg-staff-600 text-white hover:bg-staff-700"
                >
                  Signer
                </button>
              )}
              <button
                onClick={() => handleDownload(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Télécharger"
              >
                <Download className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedDocument && showViewer && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-4xl w-full">
              <DocumentViewer
                document={selectedDocument}
                onClose={() => setShowViewer(false)}
                onDownload={() => handleDownload(selectedDocument)}
                onSign={() => handleSign(selectedDocument)}
              />
            </div>
          </div>
        </div>
      )}

      <DocumentUploadModal
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onSubmit={handleSubmitUpload}
      />

      {selectedDocument && (
        <SignatureModal
          isOpen={showSignatureModal}
          onClose={() => setShowSignatureModal(false)}
          document={selectedDocument}
          onSign={handleSubmitSignature}
        />
      )}
    </div>
  );
};

export default StaffDocuments;