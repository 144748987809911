import React from 'react';
import { Calendar, Clock, MapPin, User, CheckCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Mission } from '../../../types/mission';

interface HistoryListProps {
  missions: Mission[];
}

const HistoryList: React.FC<HistoryListProps> = ({ missions }) => {
  return (
    <div className="space-y-6">
      {missions.map((mission) => (
        <div
          key={mission.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">{mission.title}</h3>
              <div className="flex items-center space-x-2 mt-1">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  <CheckCircle className="h-3 w-3 mr-1" />
                  Terminée
                </span>
              </div>
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <span>
                Du {format(new Date(mission.startDate), 'PPP', { locale: fr })} au{' '}
                {format(new Date(mission.endDate), 'PPP', { locale: fr })}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>
                {format(new Date(mission.startDate), 'HH:mm')} - {format(new Date(mission.endDate), 'HH:mm')}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <MapPin className="h-5 w-5" />
              <span>
                {mission.address.street}, {mission.address.postalCode} {mission.address.city}
              </span>
            </div>

            {mission.description && (
              <p className="text-gray-600">{mission.description}</p>
            )}
          </div>

          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <User className="h-5 w-5 text-gray-400" />
                <span className="text-gray-600">Personnel soignant assigné</span>
              </div>
              <button className="text-client-600 hover:text-client-700 text-sm font-medium">
                Voir les détails
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryList;