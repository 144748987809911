import React from 'react';
import { Calendar, Clock, MapPin, AlertTriangle, User, Check, X, Building2, Share2 } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Mission } from '../../types/mission';

interface MissionListProps {
  missions: Mission[];
  userRole: 'admin' | 'staff' | 'client';
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  onView: (mission: Mission) => void;
  onBroadcast?: (mission: Mission) => void;
}

const MissionList: React.FC<MissionListProps> = ({
  missions,
  userRole,
  onApprove,
  onReject,
  onView,
  onBroadcast,
}) => {
  const getStatusColor = (status: Mission['status']) => {
    switch (status) {
      case 'pending_approval':
        return 'bg-yellow-100 text-yellow-800';
      case 'approved':
        return 'bg-blue-100 text-blue-800';
      case 'broadcast':
        return 'bg-purple-100 text-purple-800';
      case 'assigned':
        return 'bg-green-100 text-green-800';
      case 'in_progress':
        return 'bg-indigo-100 text-indigo-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'expired':
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: Mission['status']) => {
    switch (status) {
      case 'pending_approval':
        return 'En attente de validation';
      case 'approved':
        return 'Validée';
      case 'broadcast':
        return 'Diffusée';
      case 'assigned':
        return 'Assignée';
      case 'in_progress':
        return 'En cours';
      case 'completed':
        return 'Terminée';
      case 'cancelled':
        return 'Annulée';
      case 'expired':
        return 'Expirée';
    }
  };

  return (
    <div className="space-y-6">
      {missions.map((mission) => (
        <div
          key={mission.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">{mission.title}</h3>
              <div className="flex items-center space-x-2 mt-1">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(mission.status)}`}>
                  {getStatusLabel(mission.status)}
                </span>
                {mission.priority === 'high' && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Urgent
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Informations du client */}
          <div className="mb-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <Building2 className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="font-medium text-gray-900">{mission.clientName}</p>
                  <p className="text-sm text-gray-500">Client</p>
                </div>
              </div>
              <div className="text-sm text-gray-500">
                Demande créée le {format(new Date(mission.createdAt), 'PPP', { locale: fr })}
              </div>
            </div>
            {mission.clientContact && (
              <div className="mt-2 text-sm text-gray-600">
                Contact: {mission.clientContact}
              </div>
            )}
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <span>
                {format(new Date(mission.startDate), 'PPP', { locale: fr })}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>
                {format(new Date(mission.startDate), 'HH:mm')} - {format(new Date(mission.endDate), 'HH:mm')}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <MapPin className="h-5 w-5" />
              <span>
                {mission.address.street}, {mission.address.postalCode} {mission.address.city}
              </span>
            </div>

            {mission.description && (
              <div className="flex items-start space-x-2 text-gray-600">
                <AlertTriangle className="h-5 w-5 mt-0.5" />
                <span>{mission.description}</span>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            {userRole === 'admin' && mission.status === 'pending_approval' && (
              <>
                <button
                  onClick={() => onReject?.(mission.id)}
                  className="btn text-red-600 hover:text-red-700 flex items-center space-x-2"
                >
                  <X className="h-5 w-5" />
                  <span>Refuser</span>
                </button>
                <button
                  onClick={() => onApprove?.(mission.id)}
                  className="btn bg-primary-600 text-white hover:bg-primary-700 flex items-center space-x-2"
                >
                  <Check className="h-5 w-5" />
                  <span>Valider</span>
                </button>
              </>
            )}

            {userRole === 'admin' && mission.status === 'approved' && onBroadcast && (
              <button
                onClick={() => onBroadcast(mission)}
                className="btn bg-primary-600 text-white hover:bg-primary-700 flex items-center space-x-2"
              >
                <Share2 className="h-5 w-5" />
                <span>Diffuser aux personnels</span>
              </button>
            )}

            <button
              onClick={() => onView(mission)}
              className="btn btn-secondary"
            >
              Voir les détails
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MissionList;