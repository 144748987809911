import React, { useState } from 'react';
import { Save, Bell, Lock, Globe, Smartphone } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const StaffSettings = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('notifications');
  const [settings, setSettings] = useState({
    notifications: {
      email: true,
      sms: true,
      push: false,
      newMissions: true,
      missionReminders: true,
      documents: true,
      payments: true
    },
    privacy: {
      showProfile: true,
      showAvailability: true,
      showRating: true
    },
    security: {
      twoFactorAuth: false,
      sessionTimeout: 30
    },
    preferences: {
      language: 'fr',
      timezone: 'Europe/Paris',
      mobileApp: true
    }
  });

  const handleSave = () => {
    // Logique de sauvegarde des paramètres
    console.log('Saving settings:', settings);
  };

  const updateSettings = (section: string, key: string, value: any) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: value
      }
    }));
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Paramètres</h1>
        <button
          onClick={handleSave}
          className="btn bg-staff-600 text-white hover:bg-staff-700 flex items-center space-x-2"
        >
          <Save className="h-5 w-5" />
          <span>Enregistrer</span>
        </button>
      </div>

      <div className="flex space-x-6">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <nav className="space-y-1">
              {[
                { id: 'notifications', label: 'Notifications', icon: Bell },
                { id: 'privacy', label: 'Confidentialité', icon: Globe },
                { id: 'security', label: 'Sécurité', icon: Lock },
                { id: 'preferences', label: 'Préférences', icon: Smartphone }
              ].map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full px-4 py-2 text-left rounded-lg transition-colors flex items-center space-x-3 ${
                    activeTab === tab.id
                      ? 'bg-staff-50 text-staff-700'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <tab.icon className="h-5 w-5" />
                  <span>{tab.label}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Content */}
        <div className="flex-1">
          <div className="bg-white rounded-lg shadow-sm p-6">
            {activeTab === 'notifications' && (
              <div className="space-y-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Paramètres de notifications</h2>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Notifications par email</label>
                      <p className="text-sm text-gray-500">Recevoir les notifications par email</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.notifications.email}
                        onChange={(e) => updateSettings('notifications', 'email', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Notifications SMS</label>
                      <p className="text-sm text-gray-500">Recevoir les notifications par SMS</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.notifications.sms}
                        onChange={(e) => updateSettings('notifications', 'sms', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Notifications push</label>
                      <p className="text-sm text-gray-500">Recevoir les notifications sur le navigateur</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.notifications.push}
                        onChange={(e) => updateSettings('notifications', 'push', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div className="border-t border-gray-200 pt-4">
                    <h3 className="text-sm font-medium text-gray-700 mb-4">Types de notifications</h3>
                    
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <label className="text-sm text-gray-700">Nouvelles missions</label>
                        <input
                          type="checkbox"
                          checked={settings.notifications.newMissions}
                          onChange={(e) => updateSettings('notifications', 'newMissions', e.target.checked)}
                          className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <label className="text-sm text-gray-700">Rappels de missions</label>
                        <input
                          type="checkbox"
                          checked={settings.notifications.missionReminders}
                          onChange={(e) => updateSettings('notifications', 'missionReminders', e.target.checked)}
                          className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <label className="text-sm text-gray-700">Documents</label>
                        <input
                          type="checkbox"
                          checked={settings.notifications.documents}
                          onChange={(e) => updateSettings('notifications', 'documents', e.target.checked)}
                          className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        <label className="text-sm text-gray-700">Paiements</label>
                        <input
                          type="checkbox"
                          checked={settings.notifications.payments}
                          onChange={(e) => updateSettings('notifications', 'payments', e.target.checked)}
                          className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'privacy' && (
              <div className="space-y-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Paramètres de confidentialité</h2>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Afficher mon profil</label>
                      <p className="text-sm text-gray-500">Rendre mon profil visible aux autres utilisateurs</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.privacy.showProfile}
                        onChange={(e) => updateSettings('privacy', 'showProfile', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Afficher mes disponibilités</label>
                      <p className="text-sm text-gray-500">Rendre mes disponibilités visibles</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.privacy.showAvailability}
                        onChange={(e) => updateSettings('privacy', 'showAvailability', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Afficher ma note</label>
                      <p className="text-sm text-gray-500">Rendre ma note visible aux autres utilisateurs</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.privacy.showRating}
                        onChange={(e) => updateSettings('privacy', 'showRating', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'security' && (
              <div className="space-y-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Paramètres de sécurité</h2>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Double authentification</label>
                      <p className="text-sm text-gray-500">Activer la double authentification</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.security.twoFactorAuth}
                        onChange={(e) => updateSettings('security', 'twoFactorAuth', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Délai d'expiration de session (minutes)
                    </label>
                    <input
                      type="number"
                      min="5"
                      max="120"
                      value={settings.security.sessionTimeout}
                      onChange={(e) => updateSettings('security', 'sessionTimeout', parseInt(e.target.value))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                    />
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'preferences' && (
              <div className="space-y-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Préférences</h2>
                
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Langue</label>
                    <select
                      value={settings.preferences.language}
                      onChange={(e) => updateSettings('preferences', 'language', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                    >
                      <option value="fr">Français</option>
                      <option value="en">English</option>
                      <option value="nl">Nederlands</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fuseau horaire</label>
                    <select
                      value={settings.preferences.timezone}
                      onChange={(e) => updateSettings('preferences', 'timezone', e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                    >
                      <option value="Europe/Paris">Europe/Paris</option>
                      <option value="Europe/Brussels">Europe/Brussels</option>
                      <option value="Europe/London">Europe/London</option>
                    </select>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label className="text-sm font-medium text-gray-700">Application mobile</label>
                      <p className="text-sm text-gray-500">Activer les fonctionnalités mobiles</p>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={settings.preferences.mobileApp}
                        onChange={(e) => updateSettings('preferences', 'mobileApp', e.target.checked)}
                        className="h-4 w-4 text-staff-600 focus:ring-staff-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffSettings;