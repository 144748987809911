import { supabase } from '../lib/supabase/client';
import type { Document } from '../types/document';

export const documentsService = {
  // Send document
  sendDocument: async (file: File, metadata: Partial<Document>) => {
    try {
      // 1. Upload file to Supabase Storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}_${Math.random().toString(36).substring(7)}.${fileExt}`;
      const filePath = `documents/${metadata.type}/${fileName}`;

      const { error: uploadError, data } = await supabase.storage
        .from('documents')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // 2. Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('documents')
        .getPublicUrl(filePath);

      // 3. Create document record in database
      const { data: document, error: dbError } = await supabase
        .from('documents')
        .insert([{
          ...metadata,
          url: publicUrl,
          created_at: new Date().toISOString(),
          status: 'pending',
        }])
        .select()
        .single();

      if (dbError) throw dbError;

      return document;
    } catch (error) {
      console.error('Error sending document:', error);
      throw new Error('Erreur lors de l\'envoi du document');
    }
  },

  // Get received documents
  getReceivedDocuments: async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('recipient_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error getting received documents:', error);
      throw new Error('Erreur lors de la récupération des documents');
    }
  },

  // Get sent documents
  getSentDocuments: async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('sender_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error getting sent documents:', error);
      throw new Error('Erreur lors de la récupération des documents');
    }
  },

  // Update document status
  updateDocumentStatus: async (documentId: string, status: 'approved' | 'rejected') => {
    try {
      const { error } = await supabase
        .from('documents')
        .update({ 
          status,
          updated_at: new Date().toISOString()
        })
        .eq('id', documentId);

      if (error) throw error;
      return { success: true };
    } catch (error) {
      console.error('Error updating document status:', error);
      throw new Error('Erreur lors de la mise à jour du statut');
    }
  },

  // Forward document
  forwardDocument: async (documentId: string, recipientId: string) => {
    try {
      // Get original document
      const { data: originalDoc, error: getError } = await supabase
        .from('documents')
        .select('*')
        .eq('id', documentId)
        .single();

      if (getError) throw getError;

      // Create new document record
      const { error: createError } = await supabase
        .from('documents')
        .insert([{
          ...originalDoc,
          id: undefined, // Let Supabase generate new ID
          original_document_id: documentId,
          recipient_id: recipientId,
          status: 'pending',
          created_at: new Date().toISOString()
        }]);

      if (createError) throw createError;
      return { success: true };
    } catch (error) {
      console.error('Error forwarding document:', error);
      throw new Error('Erreur lors du transfert du document');
    }
  },

  // Get available recipients
  getAvailableRecipients: async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('status', 'active');

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error getting recipients:', error);
      throw new Error('Erreur lors de la récupération des destinataires');
    }
  },

  // Sign document
  signDocument: async (documentId: string, signatureData: string, userId: string) => {
    try {
      // 1. Upload signature image
      const signatureBlob = await (await fetch(signatureData)).blob();
      const filePath = `signatures/${documentId}/${userId}`;

      const { error: uploadError, data } = await supabase.storage
        .from('signatures')
        .upload(filePath, signatureBlob);

      if (uploadError) throw uploadError;

      // 2. Get signature URL
      const { data: { publicUrl: signatureUrl } } = supabase.storage
        .from('signatures')
        .getPublicUrl(filePath);

      // 3. Update document with signature
      const { data: document, error: getError } = await supabase
        .from('documents')
        .select('signers')
        .eq('id', documentId)
        .single();

      if (getError) throw getError;

      const updatedSigners = document.signers.map((signer: any) => {
        if (signer.id === userId) {
          return {
            ...signer,
            status: 'signed',
            signed_at: new Date().toISOString(),
            signature_url: signatureUrl
          };
        }
        return signer;
      });

      const allSigned = updatedSigners.every((signer: any) => signer.status === 'signed');

      const { error: updateError } = await supabase
        .from('documents')
        .update({
          signers: updatedSigners,
          status: allSigned ? 'signed' : 'pending_signature',
          signed_at: allSigned ? new Date().toISOString() : null,
          updated_at: new Date().toISOString()
        })
        .eq('id', documentId);

      if (updateError) throw updateError;
      return { success: true };
    } catch (error) {
      console.error('Error signing document:', error);
      throw new Error('Erreur lors de la signature du document');
    }
  },

  // Download document
  downloadDocument: async (documentId: string) => {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('url')
        .eq('id', documentId)
        .single();

      if (error) throw error;
      return data.url;
    } catch (error) {
      console.error('Error downloading document:', error);
      throw new Error('Erreur lors du téléchargement du document');
    }
  }
};