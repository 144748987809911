import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, Building2, ArrowLeft } from 'lucide-react';
import StaffRegistrationForm from '../../components/auth/StaffRegistrationForm';
import ClientRegistrationForm from '../../components/auth/ClientRegistrationForm';

type UserType = 'staff' | 'client' | null;

const Register = () => {
  const [selectedType, setSelectedType] = useState<UserType>(null);
  const navigate = useNavigate();

  const handleBack = () => {
    if (selectedType) {
      setSelectedType(null);
    } else {
      navigate('/login');
    }
  };

  const handleSuccess = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <button
          onClick={handleBack}
          className="mb-6 flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour
        </button>

        <h1 className="text-3xl font-bold text-center text-gray-900 mb-6">
          Hope-Care Intérim
        </h1>
        <h2 className="text-center text-2xl font-extrabold text-gray-900">
          {selectedType ? 'Créer un compte' : 'Choisissez votre profil'}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {!selectedType ? (
          <div className="bg-white py-8 px-4 shadow-lg rounded-lg sm:px-10">
            <div className="space-y-4">
              <button
                onClick={() => setSelectedType('staff')}
                className="w-full flex items-center justify-between p-4 border-2 border-staff-200 rounded-lg hover:border-staff-500 hover:bg-staff-50 transition-colors"
              >
                <div className="flex items-center">
                  <div className="h-12 w-12 rounded-full bg-staff-100 flex items-center justify-center">
                    <User className="h-6 w-6 text-staff-600" />
                  </div>
                  <div className="ml-4 text-left">
                    <p className="text-lg font-medium text-gray-900">Personnel soignant</p>
                    <p className="text-sm text-gray-500">Infirmier(e) indépendant(e)</p>
                  </div>
                </div>
              </button>

              <button
                onClick={() => setSelectedType('client')}
                className="w-full flex items-center justify-between p-4 border-2 border-client-200 rounded-lg hover:border-client-500 hover:bg-client-50 transition-colors"
              >
                <div className="flex items-center">
                  <div className="h-12 w-12 rounded-full bg-client-100 flex items-center justify-center">
                    <Building2 className="h-6 w-6 text-client-600" />
                  </div>
                  <div className="ml-4 text-left">
                    <p className="text-lg font-medium text-gray-900">Client</p>
                    <p className="text-sm text-gray-500">Organisation ou particulier</p>
                  </div>
                </div>
              </button>
            </div>

            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                Déjà inscrit ?{' '}
                <Link
                  to="/login"
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  Connectez-vous
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-white py-8 px-4 shadow-lg rounded-lg sm:px-10">
            {selectedType === 'staff' ? (
              <StaffRegistrationForm onSuccess={handleSuccess} />
            ) : (
              <ClientRegistrationForm onSuccess={handleSuccess} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;