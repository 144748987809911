import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Filter } from 'lucide-react';
import MissionList from '../../components/staff/missions/MissionList';
import MissionFilters from '../../components/staff/missions/MissionFilters';
import type { Mission } from '../../types/mission';

const StaffMissions = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);

  // Données mockées pour l'exemple
  const missions: Mission[] = [
    {
      id: '1',
      title: 'Soins à domicile',
      clientId: '1',
      staffId: '1',
      startDate: '2024-03-20T09:00:00',
      endDate: '2024-03-20T10:00:00',
      status: 'pending',
      type: 'medical_care',
      priority: 'high',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013',
        country: 'France'
      },
      description: 'Soins post-opératoires',
      ratePerHour: 35
    },
    {
      id: '2',
      title: 'Visite de contrôle',
      clientId: '2',
      staffId: '1',
      startDate: '2024-03-21T14:00:00',
      endDate: '2024-03-21T15:00:00',
      status: 'pending',
      type: 'medical_care',
      priority: 'medium',
      address: {
        street: '8 Avenue des Lilas',
        city: 'Lyon',
        postalCode: '69003',
        country: 'France'
      },
      description: 'Suivi traitement',
      ratePerHour: 35
    }
  ];

  const handleTimeEntry = async (missionId: string, type: 'start' | 'end', location: any) => {
    console.log('Time entry:', { missionId, type, location });
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Mes missions</h1>
          <p className="text-sm text-gray-600 mt-1">
            {format(new Date(), 'EEEE d MMMM yyyy', { locale: fr })}
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      {showFilters && <MissionFilters />}

      <div className="space-y-6">
        <div>
          <h2 className="text-lg font-medium mb-4">Missions du jour</h2>
          <MissionList
            missions={missions.filter(m => 
              new Date(m.startDate).toDateString() === new Date().toDateString()
            )}
            onTimeEntry={handleTimeEntry}
          />
        </div>

        <div>
          <h2 className="text-lg font-medium mb-4">Missions à venir</h2>
          <MissionList
            missions={missions.filter(m => 
              new Date(m.startDate) > new Date()
            )}
            onTimeEntry={handleTimeEntry}
          />
        </div>
      </div>
    </div>
  );
};

export default StaffMissions;