import React from 'react';
import { X } from 'lucide-react';
import StaffForm from './StaffForm';
import type { StaffMember } from '../../types/staff';

interface StaffModalProps {
  isOpen: boolean;
  onClose: () => void;
  staff?: StaffMember;
  onSubmit: (data: Partial<StaffMember>) => void;
}

const StaffModal: React.FC<StaffModalProps> = ({
  isOpen,
  onClose,
  staff,
  onSubmit,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-2xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              {staff ? 'Modifier le collaborateur' : 'Ajouter un collaborateur'}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6">
            <StaffForm
              staff={staff}
              onSubmit={(data) => {
                onSubmit(data);
                onClose();
              }}
              onCancel={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffModal;