import React from 'react';
import DashboardStats from '../components/DashboardStats';
import { Calendar, Clock } from 'lucide-react';

const RecentActivity = () => (
  <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6 mt-6">
    <h2 className="text-lg font-semibold mb-4">Activité récente</h2>
    <div className="space-y-4">
      {[
        {
          time: "Il y a 2h",
          title: "Nouvelle mission assignée",
          description: "Mission de soins assignée à Marie Dubois"
        },
        {
          time: "Il y a 4h",
          title: "Document signé",
          description: "Convention signée par Dr. Martin"
        },
        {
          time: "Il y a 6h",
          title: "Nouveau client",
          description: "Inscription de Centre médical Saint-Jean"
        }
      ].map((activity, index) => (
        <div key={index} className="flex items-start space-x-3 sm:space-x-4">
          <div className="flex-shrink-0">
            <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-indigo-100 flex items-center justify-center">
              <Clock className="h-4 w-4 sm:h-5 sm:w-5 text-indigo-600" />
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-xs sm:text-sm text-gray-500">{activity.time}</p>
            <p className="text-sm sm:text-base font-medium">{activity.title}</p>
            <p className="text-xs sm:text-sm text-gray-600">{activity.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const UpcomingMissions = () => (
  <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6 mt-6">
    <h2 className="text-lg font-semibold mb-4">Missions à venir</h2>
    <div className="space-y-4">
      {[
        {
          date: "Aujourd'hui",
          missions: [
            {
              time: "14:00",
              patient: "M. Bernard",
              nurse: "Sophie Martin",
              type: "Soins infirmiers"
            },
            {
              time: "16:30",
              patient: "Mme Petit",
              nurse: "Jean Dupont",
              type: "Aide à domicile"
            }
          ]
        },
        {
          date: "Demain",
          missions: [
            {
              time: "09:00",
              patient: "M. Lambert",
              nurse: "Marie Dubois",
              type: "Soins infirmiers"
            }
          ]
        }
      ].map((day, index) => (
        <div key={index}>
          <h3 className="font-medium text-gray-900 mb-2">{day.date}</h3>
          <div className="space-y-3">
            {day.missions.map((mission, mIndex) => (
              <div key={mIndex} className="flex items-center space-x-3 sm:space-x-4 bg-gray-50 p-3 sm:p-4 rounded-lg">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-green-100 flex items-center justify-center">
                    <Calendar className="h-4 w-4 sm:h-5 sm:w-5 text-green-600" />
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="text-sm sm:text-base font-medium truncate">
                    {mission.time} - {mission.type}
                  </p>
                  <p className="text-xs sm:text-sm text-gray-600 truncate">
                    Patient: {mission.patient}
                  </p>
                  <p className="text-xs sm:text-sm text-gray-600 truncate">
                    Soignant: {mission.nurse}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Dashboard = () => {
  return (
    <div className="p-4 sm:p-6">
      <h1 className="text-xl sm:text-2xl font-bold mb-6">Tableau de bord</h1>
      <DashboardStats />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <RecentActivity />
        <UpcomingMissions />
      </div>
    </div>
  );
};

export default Dashboard;