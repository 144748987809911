import React, { useState } from 'react';
import NotificationList from '../components/notifications/NotificationList';
import NotificationFilters from '../components/notifications/NotificationFilters';
import type { Notification, NotificationFilters as Filters } from '../types/notification';

const mockNotifications: Notification[] = [
  {
    id: '1',
    type: 'warning',
    category: 'mission',
    title: 'Mission urgente à affecter',
    message: 'Une nouvelle mission prioritaire nécessite une affectation immédiate',
    timestamp: '2024-03-20T09:00:00',
    read: false,
    actionUrl: '/missions/assign',
    metadata: {
      missionId: '123'
    }
  },
  {
    id: '2',
    type: 'info',
    category: 'staff',
    title: 'Nouveau collaborateur',
    message: 'Marie Dubois a rejoint l\'équipe en tant qu\'infirmière',
    timestamp: '2024-03-19T14:30:00',
    read: true,
    actionUrl: '/staff/profile/456',
    metadata: {
      staffId: '456'
    }
  },
  {
    id: '3',
    type: 'error',
    category: 'billing',
    title: 'Facture en retard',
    message: 'La facture INV-2024-001 est en retard de paiement',
    timestamp: '2024-03-18T16:45:00',
    read: false,
    actionUrl: '/billing/invoice/789',
    metadata: {
      invoiceId: '789'
    }
  }
];

const Notifications = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [notifications] = useState<Notification[]>(mockNotifications);

  const handleMarkAsRead = (id: string) => {
    console.log('Mark as read:', id);
  };

  const handleMarkAllAsRead = () => {
    console.log('Mark all as read');
  };

  const handleDelete = (id: string) => {
    console.log('Delete notification:', id);
  };

  const handleClearAll = () => {
    console.log('Clear all notifications');
  };

  const filteredNotifications = notifications.filter((notification) => {
    if (filters.type && notification.type !== filters.type) return false;
    if (filters.category && notification.category !== filters.category) return false;
    if (filters.read !== undefined && notification.read !== filters.read) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        notification.title.toLowerCase().includes(query) ||
        notification.message.toLowerCase().includes(query)
      );
    }
    if (filters.startDate && new Date(notification.timestamp) < new Date(filters.startDate)) return false;
    if (filters.endDate && new Date(notification.timestamp) > new Date(filters.endDate)) return false;
    return true;
  });

  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Notifications</h1>
          <p className="text-sm text-gray-600 mt-1">
            {unreadCount} notification{unreadCount !== 1 ? 's' : ''} non lue{unreadCount !== 1 ? 's' : ''}
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={handleMarkAllAsRead}
            className="btn btn-secondary"
          >
            Tout marquer comme lu
          </button>
          <button
            onClick={handleClearAll}
            className="btn text-red-600 hover:text-red-700"
          >
            Effacer tout
          </button>
        </div>
      </div>

      <NotificationFilters filters={filters} onFilterChange={setFilters} />
      <NotificationList
        notifications={filteredNotifications}
        onMarkAsRead={handleMarkAsRead}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default Notifications;