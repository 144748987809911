import React, { useState } from 'react';
import { UserPlus } from 'lucide-react';
import AdminList from './AdminList';
import AdminFilters from './AdminFilters';
import type { AdminUser } from '../../types/user';
import type { UserFilters } from '../../types/user';

interface AdminManagementProps {
  admins: AdminUser[];
  onAddAdmin: () => void;
  onEditAdmin: (admin: AdminUser) => void;
  onDeleteAdmin: (id: string) => void;
}

const AdminManagement: React.FC<AdminManagementProps> = ({
  admins,
  onAddAdmin,
  onEditAdmin,
  onDeleteAdmin,
}) => {
  const [filters, setFilters] = useState<UserFilters>({});

  const filteredAdmins = admins.filter((admin) => {
    if (filters.status && admin.status !== filters.status) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        admin.firstName.toLowerCase().includes(query) ||
        admin.lastName.toLowerCase().includes(query) ||
        admin.email.toLowerCase().includes(query)
      );
    }
    return true;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Gestion des administrateurs</h2>
        <button
          onClick={onAddAdmin}
          className="btn btn-primary flex items-center space-x-2"
        >
          <UserPlus className="h-5 w-5" />
          <span>Ajouter un administrateur</span>
        </button>
      </div>

      <AdminFilters filters={filters} onFilterChange={setFilters} />
      <AdminList
        admins={filteredAdmins}
        onEdit={onEditAdmin}
        onDelete={onDeleteAdmin}
      />
    </div>
  );
};

export default AdminManagement;