import React from 'react';
import { Search, Calendar } from 'lucide-react';
import type { MissionFilters } from '../../types/mission';

interface MissionFiltersProps {
  filters: MissionFilters;
  onFilterChange: (filters: MissionFilters) => void;
}

const MissionFilters: React.FC<MissionFiltersProps> = ({
  filters = {}, // Valeur par défaut pour éviter l'erreur undefined
  onFilterChange,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher une mission..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.searchQuery || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, searchQuery: e.target.value })
                }
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.status || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  status: e.target.value as MissionFilters['status'],
                })
              }
            >
              <option value="">Tous les statuts</option>
              <option value="pending">En attente</option>
              <option value="in_progress">En cours</option>
              <option value="completed">Terminée</option>
              <option value="cancelled">Annulée</option>
            </select>

            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.type || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  type: e.target.value as MissionFilters['type'],
                })
              }
            >
              <option value="">Tous les types</option>
              <option value="medical_care">Soins médicaux</option>
              <option value="home_assistance">Aide à domicile</option>
            </select>

            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.priority || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  priority: e.target.value as MissionFilters['priority'],
                })
              }
            >
              <option value="">Toutes les priorités</option>
              <option value="high">Haute</option>
              <option value="medium">Moyenne</option>
              <option value="low">Basse</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.startDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, startDate: e.target.value })
                }
              />
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.endDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, endDate: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionFilters;