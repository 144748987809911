import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Filter } from 'lucide-react';
import ModernCalendar from '../components/planning/ModernCalendar';
import PlanningFilters from '../components/planning/PlanningFilters';
import { useAvailability } from '../contexts/AvailabilityContext';
import { useMission } from '../contexts/MissionContext';
import type { Mission } from '../types/mission';
import type { StaffMember } from '../types/staff';

const Planning = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const { missions } = useMission();
  const { availabilities } = useAvailability();

  // Données mockées pour l'exemple
  const [staffMembers] = useState<StaffMember[]>([
    {
      id: 'staff-1',
      firstName: 'Marie',
      lastName: 'Dubois',
      email: 'marie.dubois@example.com',
      phone: '+33 6 12 34 56 78',
      role: 'nurse',
      status: 'active',
      contractType: 'independent',
      inamiNumber: '12345678901',
      tvaNumber: 'BE0123456789',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013',
        country: 'France'
      },
      bankDetails: {
        iban: 'FR76 1234 5678 9012 3456 7890 123',
        bic: 'BNPAFRPP',
        bankName: 'BNP Paribas'
      },
      ratePerHour: 35,
      startDate: '2023-01-15',
      specializations: ['Gériatrie', 'Soins palliatifs']
    },
    {
      id: 'staff-2',
      firstName: 'Jean',
      lastName: 'Martin',
      email: 'jean.martin@example.com',
      phone: '+33 6 98 76 54 32',
      role: 'nurse',
      status: 'active',
      contractType: 'independent',
      inamiNumber: '98765432109',
      tvaNumber: 'BE9876543210',
      address: {
        street: '8 Avenue des Lilas',
        city: 'Paris',
        postalCode: '75014',
        country: 'France'
      },
      bankDetails: {
        iban: 'FR76 9876 5432 1098 7654 3210',
        bic: 'BNPAFRPP',
        bankName: 'BNP Paribas'
      },
      ratePerHour: 35,
      startDate: '2023-02-01',
      specializations: ['Soins à domicile', 'Gériatrie']
    }
  ]);

  const handleEventClick = (info: any) => {
    const mission = info.event.extendedProps.mission;
    if (mission) {
      setSelectedMission(mission);
    }
  };

  const handleDateSelect = (info: any) => {
    console.log('Date selected:', info.startStr, info.endStr);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Planning</h1>
          <p className="text-sm text-gray-600 mt-1">
            {format(new Date(), 'MMMM yyyy', { locale: fr })}
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      {showFilters && <PlanningFilters />}

      <ModernCalendar
        missions={missions}
        staffAvailabilities={availabilities}
        onEventClick={handleEventClick}
        onDateSelect={handleDateSelect}
      />
    </div>
  );
};

export default Planning;