import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { MissionProvider } from './contexts/MissionContext';
import { AvailabilityProvider } from './contexts/AvailabilityContext';
import { NotificationProvider } from './contexts/NotificationContext';
import Routes from './Routes';
import { initializeDefaultAccounts } from './config/initialData';

const App: React.FC = () => {
  useEffect(() => {
    initializeDefaultAccounts();
  }, []);

  return (
    <AuthProvider>
      <NotificationProvider>
        <MissionProvider>
          <AvailabilityProvider>
            <Router>
              <Routes />
            </Router>
          </AvailabilityProvider>
        </MissionProvider>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;