import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  User, 
  Mail, 
  Phone, 
  MapPin, 
  Calendar, 
  Award, 
  Building2, 
  CreditCard,
  FileText,
  Download,
  Eye,
  Clock,
  CheckCircle,
  AlertTriangle
} from 'lucide-react';
import DocumentViewer from '../components/documents/DocumentViewer';
import type { StaffMember } from '../types/staff';

const StaffDetails = () => {
  const { id } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  // Données mockées pour l'exemple
  const staffMember: StaffMember = {
    id: '1',
    firstName: 'Marie',
    lastName: 'Dubois',
    email: 'marie.dubois@example.com',
    phone: '+33 6 12 34 56 78',
    role: 'nurse',
    contractType: 'independent',
    status: 'active',
    inamiNumber: '12345678901',
    tvaNumber: 'BE0123456789',
    siret: '123 456 789 00012',
    address: {
      street: '15 Rue de la Santé',
      city: 'Paris',
      postalCode: '75013',
      country: 'France'
    },
    bankDetails: {
      iban: 'FR76 1234 5678 9012 3456 7890 123',
      bic: 'BNPAFRPP',
      bankName: 'BNP Paribas'
    },
    ratePerHour: 35,
    startDate: '2023-01-15',
    specializations: ['Gériatrie', 'Soins palliatifs'],
    documents: [
      {
        id: '1',
        type: 'id',
        name: 'Carte d\'identité',
        url: '#',
        uploadDate: '2023-01-10',
        verified: true,
        expiryDate: '2028-01-10'
      },
      {
        id: '2',
        type: 'diploma',
        name: 'Diplôme infirmier',
        url: '#',
        uploadDate: '2023-01-10',
        verified: true
      },
      {
        id: '3',
        type: 'inami',
        name: 'Attestation INAMI',
        url: '#',
        uploadDate: '2023-01-10',
        verified: true,
        expiryDate: '2024-12-31'
      },
      {
        id: '4',
        type: 'insurance',
        name: 'Assurance professionnelle',
        url: '#',
        uploadDate: '2023-01-10',
        verified: true,
        expiryDate: '2024-12-31'
      }
    ],
    availability: {
      monday: { morning: true, afternoon: true, night: false },
      tuesday: { morning: true, afternoon: true, night: false },
      wednesday: { morning: true, afternoon: false, night: false },
      thursday: { morning: true, afternoon: true, night: false },
      friday: { morning: true, afternoon: true, night: false },
      saturday: { morning: false, afternoon: false, night: false },
      sunday: { morning: false, afternoon: false, night: false }
    }
  };

  const handleViewDocument = (document: any) => {
    setSelectedDocument(document);
    setShowDocumentViewer(true);
  };

  const handleDownloadDocument = (document: any) => {
    console.log('Download document:', document);
  };

  const handleVerifyDocument = (documentId: string) => {
    console.log('Verify document:', documentId);
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Profil du collaborateur</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Informations principales */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center space-x-4 mb-6">
              <div className="h-24 w-24 rounded-full bg-primary-100 flex items-center justify-center">
                {staffMember.avatar ? (
                  <img
                    src={staffMember.avatar}
                    alt={`${staffMember.firstName} ${staffMember.lastName}`}
                    className="h-24 w-24 rounded-full object-cover"
                  />
                ) : (
                  <User className="h-12 w-12 text-primary-600" />
                )}
              </div>
              <div>
                <h2 className="text-xl font-semibold">
                  {staffMember.firstName} {staffMember.lastName}
                </h2>
                <div className="flex items-center space-x-2 mt-1">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800">
                    Infirmier indépendant
                  </span>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    staffMember.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {staffMember.status === 'active' ? 'Actif' : 'Inactif'}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Mail className="h-5 w-5 text-gray-400" />
                  <span>{staffMember.email}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Phone className="h-5 w-5 text-gray-400" />
                  <span>{staffMember.phone}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <MapPin className="h-5 w-5 text-gray-400" />
                  <span>
                    {staffMember.address.street}, {staffMember.address.postalCode} {staffMember.address.city}
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <Calendar className="h-5 w-5 text-gray-400" />
                  <span>Membre depuis le {new Date(staffMember.startDate).toLocaleDateString('fr-FR')}</span>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Award className="h-5 w-5 text-gray-400" />
                  <span>N° INAMI: {staffMember.inamiNumber}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Building2 className="h-5 w-5 text-gray-400" />
                  <span>N° SIRET: {staffMember.siret}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Building2 className="h-5 w-5 text-gray-400" />
                  <span>N° TVA: {staffMember.tvaNumber}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <CreditCard className="h-5 w-5 text-gray-400" />
                  <span>Taux horaire: {staffMember.ratePerHour}€/h</span>
                </div>
              </div>
            </div>
          </div>

          {/* Coordonnées bancaires */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Coordonnées bancaires</h3>
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Banque</label>
                  <p className="mt-1">{staffMember.bankDetails.bankName}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">IBAN</label>
                  <p className="mt-1">{staffMember.bankDetails.iban}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">BIC</label>
                  <p className="mt-1">{staffMember.bankDetails.bic}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Disponibilités */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Disponibilités</h3>
            <div className="grid grid-cols-7 gap-4">
              {Object.entries(staffMember.availability).map(([day, periods]) => (
                <div key={day} className="text-center">
                  <h4 className="font-medium mb-2">{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                  <div className="space-y-2">
                    {Object.entries(periods).map(([period, available]) => (
                      <div
                        key={period}
                        className={`px-2 py-1 rounded text-xs ${
                          available
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}
                      >
                        {period === 'morning' ? 'Matin' :
                         period === 'afternoon' ? 'Après-midi' : 'Nuit'}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Documents */}
        <div className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Documents</h3>
            <div className="space-y-4">
              {staffMember.documents.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-10 w-10 rounded-lg bg-primary-100 flex items-center justify-center">
                      <FileText className="h-5 w-5 text-primary-600" />
                    </div>
                    <div>
                      <p className="font-medium">{doc.name}</p>
                      <div className="flex items-center space-x-2 mt-1">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                          doc.verified
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {doc.verified ? 'Vérifié' : 'À vérifier'}
                        </span>
                        {doc.expiryDate && (
                          <span className="text-xs text-gray-500">
                            Expire le {new Date(doc.expiryDate).toLocaleDateString('fr-FR')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleViewDocument(doc)}
                      className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                      title="Voir"
                    >
                      <Eye className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDownloadDocument(doc)}
                      className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                      title="Télécharger"
                    >
                      <Download className="h-4 w-4" />
                    </button>
                    {!doc.verified && (
                      <button
                        onClick={() => handleVerifyDocument(doc.id)}
                        className="p-2 text-green-600 hover:bg-green-100 rounded-lg"
                        title="Vérifier"
                      >
                        <CheckCircle className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Alertes */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-lg font-medium mb-4">Alertes</h3>
            <div className="space-y-4">
              {staffMember.documents
                .filter(doc => doc.expiryDate && new Date(doc.expiryDate) < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))
                .map(doc => (
                  <div key={doc.id} className="flex items-start space-x-3 p-4 bg-red-50 text-red-800 rounded-lg">
                    <AlertTriangle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                    <div>
                      <p className="font-medium">{doc.name}</p>
                      <p className="text-sm">
                        Expire le {new Date(doc.expiryDate!).toLocaleDateString('fr-FR')}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {selectedDocument && showDocumentViewer && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-4xl w-full">
              <DocumentViewer
                document={selectedDocument}
                onClose={() => setShowDocumentViewer(false)}
                onDownload={() => handleDownloadDocument(selectedDocument)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffDetails;