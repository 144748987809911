import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useMission } from '../../contexts/MissionContext';
import { useAuth } from '../../contexts/AuthContext';
import MissionRequestList from '../../components/client/missions/MissionRequestList';
import MissionRequestModal from '../../components/client/missions/MissionRequestModal';
import MissionRequestFilters from '../../components/client/missions/MissionRequestFilters';
import type { MissionRequest } from '../../types/missionRequest';

const ClientMissions: React.FC = () => {
  const { user } = useAuth();
  const { missionRequests, addMissionRequest, updateMissionRequest, deleteMissionRequest } = useMission();
  const [showFilters, setShowFilters] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<MissionRequest | null>(null);

  const handleCreateRequest = () => {
    setSelectedRequest(null);
    setIsModalOpen(true);
  };

  const handleEditRequest = (request: MissionRequest) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleCancelRequest = (id: string) => {
    updateMissionRequest(id, { status: 'cancelled' });
  };

  const handleSubmitRequest = (data: Partial<MissionRequest>) => {
    if (user?.id) {
      if (selectedRequest) {
        // Modification
        updateMissionRequest(selectedRequest.id, data);
      } else {
        // Création
        const newRequest: MissionRequest = {
          id: Math.random().toString(36).substr(2, 9),
          clientId: user.id,
          clientName: user.organization || `${user.firstName} ${user.lastName}`, // Ajout du nom du client
          status: 'pending',
          createdAt: new Date().toISOString(),
          ...data as Omit<MissionRequest, 'id' | 'clientId' | 'clientName' | 'status' | 'createdAt'>
        };
        addMissionRequest(newRequest);
      }
      setIsModalOpen(false);
    }
  };

  // Filtrer les demandes pour n'afficher que celles du client connecté
  const filteredRequests = missionRequests.filter(request => request.clientId === user?.id);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Demandes de mission</h1>
          <p className="text-sm text-gray-600 mt-1">
            Créez et gérez vos demandes de soins
          </p>
        </div>
        <button
          onClick={handleCreateRequest}
          className="btn bg-client-600 text-white hover:bg-client-700 flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Nouvelle demande</span>
        </button>
      </div>

      <MissionRequestFilters
        show={showFilters}
        onToggle={() => setShowFilters(!showFilters)}
      />

      <MissionRequestList
        requests={filteredRequests}
        onEdit={handleEditRequest}
        onCancel={handleCancelRequest}
      />

      <MissionRequestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        request={selectedRequest}
        onSubmit={handleSubmitRequest}
      />
    </div>
  );
};

export default ClientMissions;