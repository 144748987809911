import React, { useState } from 'react';
import { Filter } from 'lucide-react';
import InvoiceList from '../../components/client/billing/InvoiceList';
import BillingStats from '../../components/client/billing/BillingStats';
import BillingFilters from '../../components/client/billing/BillingFilters';
import type { Invoice } from '../../types/billing';

const ClientBilling = () => {
  const [showFilters, setShowFilters] = useState(false);

  // Données mockées pour l'exemple
  const invoices: Invoice[] = [
    {
      id: '1',
      number: 'INV-2024-001',
      clientId: '1',
      clientName: 'Centre Médical Saint-Jean',
      status: 'paid',
      issueDate: '2024-03-01',
      dueDate: '2024-03-31',
      items: [
        {
          id: '1',
          serviceId: '1',
          description: 'Soins infirmiers - Mars 2024',
          quantity: 20,
          rate: 35,
          amount: 700,
          date: '2024-03-01'
        }
      ],
      subtotal: 700,
      tax: 140,
      total: 840,
      paymentMethod: 'bank_transfer',
      paymentDate: '2024-03-15'
    },
    {
      id: '2',
      number: 'INV-2024-002',
      clientId: '1',
      clientName: 'Centre Médical Saint-Jean',
      status: 'pending',
      issueDate: '2024-03-15',
      dueDate: '2024-04-14',
      items: [
        {
          id: '2',
          serviceId: '2',
          description: 'Soins infirmiers - Avril 2024',
          quantity: 15,
          rate: 35,
          amount: 525,
          date: '2024-03-15'
        }
      ],
      subtotal: 525,
      tax: 105,
      total: 630
    }
  ];

  const handleDownloadInvoice = (invoiceId: string) => {
    console.log('Download invoice:', invoiceId);
  };

  const handlePayInvoice = (invoiceId: string) => {
    console.log('Pay invoice:', invoiceId);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Facturation</h1>
          <p className="text-sm text-gray-600 mt-1">
            Gérez vos factures et paiements
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      <BillingStats invoices={invoices} />
      
      {showFilters && <BillingFilters />}
      
      <InvoiceList
        invoices={invoices}
        onDownload={handleDownloadInvoice}
        onPay={handlePayInvoice}
      />
    </div>
  );
};

export default ClientBilling;