import React, { useState } from 'react';
import { FileText, Filter, Download, Eye, Plus } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import DocumentViewer from '../../components/documents/DocumentViewer';
import DocumentUploadModal from '../../components/client/documents/DocumentUploadModal';
import type { Document } from '../../types/document';

const ClientDocuments = () => {
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [showViewer, setShowViewer] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // Données mockées pour l'exemple
  const documents = [
    {
      id: '1',
      type: 'contract',
      title: 'Convention de soins',
      description: 'Convention de prestation de services',
      status: 'pending_signature',
      createdAt: '2024-03-01T10:00:00',
      content: 'Contenu de la convention...',
      sender: 'Administration',
      direction: 'received'
    },
    {
      id: '2',
      type: 'invoice',
      title: 'Facture - Mars 2024',
      status: 'signed',
      createdAt: '2024-03-15T09:00:00',
      content: 'Contenu de la facture...',
      sender: 'Client',
      direction: 'sent'
    }
  ];

  const handleDownload = (document: Document) => {
    console.log('Download document:', document.id);
  };

  const handleView = (document: Document) => {
    setSelectedDocument(document);
    setShowViewer(true);
  };

  const handleUpload = () => {
    setShowUploadModal(true);
  };

  const handleSubmitUpload = (data: any) => {
    console.log('Upload document:', data);
    setShowUploadModal(false);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Documents</h1>
          <p className="text-sm text-gray-600 mt-1">
            Gérez vos documents et signatures
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-secondary flex items-center space-x-2"
          >
            <Filter className="h-5 w-5" />
            <span>Filtres</span>
          </button>
          <button
            onClick={handleUpload}
            className="btn bg-client-600 text-white hover:bg-client-700 flex items-center space-x-2"
          >
            <Plus className="h-5 w-5" />
            <span>Envoyer un document</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((document) => (
          <div
            key={document.id}
            className="bg-white rounded-lg shadow-sm p-6"
          >
            <div className="flex items-center space-x-4 mb-4">
              <div className="h-12 w-12 rounded-lg bg-client-100 flex items-center justify-center">
                <FileText className="h-6 w-6 text-client-600" />
              </div>
              <div>
                <h3 className="font-medium text-gray-900">{document.title}</h3>
                <div className="flex items-center space-x-2 mt-1">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    document.direction === 'received' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                  }`}>
                    {document.direction === 'received' ? 'Reçu' : 'Envoyé'}
                  </span>
                  <span className="text-sm text-gray-500">
                    {format(new Date(document.createdAt), 'dd MMMM yyyy', { locale: fr })}
                  </span>
                </div>
              </div>
            </div>

            <div className="space-y-2 mb-4">
              {document.description && (
                <p className="text-sm text-gray-600">{document.description}</p>
              )}
              <div className="flex items-center space-x-2">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  document.status === 'signed'
                    ? 'bg-green-100 text-green-800'
                    : document.status === 'pending_signature'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {document.status === 'signed' ? 'Signé' : 
                   document.status === 'pending_signature' ? 'En attente de signature' : 
                   'Brouillon'}
                </span>
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={() => handleView(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Voir"
              >
                <Eye className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleDownload(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Télécharger"
              >
                <Download className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      <DocumentUploadModal
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onSubmit={handleSubmitUpload}
      />

      {selectedDocument && showViewer && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-4xl w-full">
              <DocumentViewer
                document={selectedDocument}
                onClose={() => setShowViewer(false)}
                onDownload={() => handleDownload(selectedDocument)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDocuments;