import React from 'react';
import { Briefcase, Clock, CheckCircle, CreditCard } from 'lucide-react';

interface ClientStatsProps {
  stats: {
    activeMissions: number;
    pendingRequests: number;
    completedMissions: number;
    totalSpent: number;
  };
}

const ClientStats: React.FC<ClientStatsProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Missions actives</p>
            <p className="text-2xl font-semibold mt-2">{stats.activeMissions}</p>
          </div>
          <div className="p-3 rounded-full bg-client-100">
            <Briefcase className="h-6 w-6 text-client-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Demandes en attente</p>
            <p className="text-2xl font-semibold mt-2">{stats.pendingRequests}</p>
          </div>
          <div className="p-3 rounded-full bg-client-100">
            <Clock className="h-6 w-6 text-client-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Missions terminées</p>
            <p className="text-2xl font-semibold mt-2">{stats.completedMissions}</p>
          </div>
          <div className="p-3 rounded-full bg-client-100">
            <CheckCircle className="h-6 w-6 text-client-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Total dépensé</p>
            <p className="text-2xl font-semibold mt-2">
              {stats.totalSpent.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
            </p>
          </div>
          <div className="p-3 rounded-full bg-client-100">
            <CreditCard className="h-6 w-6 text-client-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientStats;