import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const RevenueChart = () => {
  const data = {
    labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin'],
    datasets: [
      {
        label: 'Revenus',
        data: [2800, 3200, 2900, 3500, 3250, 3800],
        fill: true,
        backgroundColor: 'rgba(246, 32, 127, 0.1)',
        borderColor: 'rgba(246, 32, 127, 1)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.parsed.y.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: number) => {
            return value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
          },
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default RevenueChart;