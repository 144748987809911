import React, { useState } from 'react';
import type { StaffMember } from '../../types/staff';

interface StaffFormProps {
  staff?: StaffMember;
  onSubmit: (data: Partial<StaffMember>) => void;
  onCancel: () => void;
}

const StaffForm: React.FC<StaffFormProps> = ({
  staff,
  onSubmit,
  onCancel,
}) => {
  const [formData, setFormData] = useState<Partial<StaffMember>>(
    staff || {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: 'nurse',
      contractType: 'independent',
      status: 'active',
      address: {
        street: '',
        city: '',
        postalCode: '',
        country: 'France',
      },
      bankDetails: {
        iban: '',
        bic: '',
        bankName: '',
      },
      ratePerHour: 35,
      availability: {
        monday: { morning: false, afternoon: false, night: false },
        tuesday: { morning: false, afternoon: false, night: false },
        wednesday: { morning: false, afternoon: false, night: false },
        thursday: { morning: false, afternoon: false, night: false },
        friday: { morning: false, afternoon: false, night: false },
        saturday: { morning: false, afternoon: false, night: false },
        sunday: { morning: false, afternoon: false, night: false }
      }
    }
  );

  const handleChange = (field: string, value: any) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent as keyof typeof prev],
          [child]: value,
        },
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleAvailabilityChange = (day: string, period: string, value: boolean) => {
    setFormData(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: {
          ...prev.availability?.[day],
          [period]: value
        }
      }
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Prénom
          </label>
          <input
            type="text"
            value={formData.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nom
          </label>
          <input
            type="text"
            value={formData.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Téléphone
          </label>
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            N° INAMI
          </label>
          <input
            type="text"
            value={formData.inamiNumber}
            onChange={(e) => handleChange('inamiNumber', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            N° TVA
          </label>
          <input
            type="text"
            value={formData.tvaNumber}
            onChange={(e) => handleChange('tvaNumber', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Taux horaire (€/h)
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          value={formData.ratePerHour}
          onChange={(e) => handleChange('ratePerHour', parseFloat(e.target.value))}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
          required
        />
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-4">Adresse</h3>
        <div className="grid grid-cols-1 gap-4">
          <input
            type="text"
            placeholder="Rue"
            value={formData.address?.street}
            onChange={(e) => handleChange('address.street', e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
            required
          />
          <div className="grid grid-cols-2 gap-4">
            <input
              type="text"
              placeholder="Ville"
              value={formData.address?.city}
              onChange={(e) => handleChange('address.city', e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
              required
            />
            <input
              type="text"
              placeholder="Code postal"
              value={formData.address?.postalCode}
              onChange={(e) => handleChange('address.postalCode', e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
              required
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-4">Coordonnées bancaires</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Banque
            </label>
            <input
              type="text"
              value={formData.bankDetails?.bankName}
              onChange={(e) => handleChange('bankDetails.bankName', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              IBAN
            </label>
            <input
              type="text"
              value={formData.bankDetails?.iban}
              onChange={(e) => handleChange('bankDetails.iban', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              BIC
            </label>
            <input
              type="text"
              value={formData.bankDetails?.bic}
              onChange={(e) => handleChange('bankDetails.bic', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
              required
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-4">Disponibilités</h3>
        <div className="grid grid-cols-7 gap-4">
          {Object.entries(formData.availability || {}).map(([day, periods]) => (
            <div key={day} className="text-center">
              <h4 className="font-medium mb-2">{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
              <div className="space-y-2">
                {Object.entries(periods).map(([period, value]) => (
                  <label key={period} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={value}
                      onChange={(e) => handleAvailabilityChange(day, period, e.target.checked)}
                      className="rounded border-gray-300 text-staff-600 focus:ring-staff-500"
                    />
                    <span className="text-sm">
                      {period === 'morning' ? 'Matin' :
                       period === 'afternoon' ? 'Après-midi' : 'Nuit'}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-secondary"
        >
          Annuler
        </button>
        <button
          type="submit"
          className="btn bg-staff-600 text-white hover:bg-staff-700"
        >
          {staff ? 'Modifier' : 'Ajouter'}
        </button>
      </div>
    </form>
  );
};

export default StaffForm;