import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Filter } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useAvailability } from '../../contexts/AvailabilityContext';
import ModernCalendar from '../../components/staff/planning/ModernCalendar';
import AvailabilityManager from '../../components/staff/planning/AvailabilityManager';
import PlanningFilters from '../../components/staff/planning/PlanningFilters';
import type { Mission } from '../../types/mission';

const StaffPlanning = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const { user } = useAuth();
  const { getStaffAvailability, updateAvailability } = useAvailability();
  const [missions] = useState<Mission[]>([
    {
      id: '1',
      title: 'Soins à domicile',
      clientId: '1',
      clientName: 'Centre Médical Saint-Jean',
      staffId: 'staff-1',
      staffName: 'Marie Dubois',
      startDate: '2024-03-20T09:00:00',
      endDate: '2024-03-20T10:00:00',
      status: 'pending',
      type: 'medical_care',
      priority: 'high',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013',
        country: 'France'
      },
      description: 'Soins post-opératoires',
      ratePerHour: 35
    }
  ]);

  // Récupérer les disponibilités du personnel connecté
  const staffAvailabilities = user?.id ? getStaffAvailability(user.id) : {};

  const handleUpdateAvailability = (
    date: string,
    period: 'morning' | 'afternoon' | 'night',
    available: boolean
  ) => {
    if (user?.id) {
      updateAvailability(user.id, date, period, available);
    }
  };

  const handleEventClick = (info: any) => {
    const mission = info.event.extendedProps.mission;
    if (mission) {
      setSelectedMission(mission);
    }
  };

  const handleDateSelect = (info: any) => {
    console.log('Date selected:', info.startStr, info.endStr);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Mon planning</h1>
          <p className="text-sm text-gray-600 mt-1">
            {format(new Date(), 'MMMM yyyy', { locale: fr })}
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      <AvailabilityManager
        availabilities={staffAvailabilities}
        onUpdate={handleUpdateAvailability}
      />

      {showFilters && <PlanningFilters />}

      <ModernCalendar
        missions={missions}
        availabilities={staffAvailabilities}
        onEventClick={handleEventClick}
        onDateSelect={handleDateSelect}
      />
    </div>
  );
};

export default StaffPlanning;