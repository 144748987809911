import React from 'react';
import { X } from 'lucide-react';
import MissionForm from './MissionForm';
import type { Mission } from '../../types/mission';

interface MissionModalProps {
  isOpen: boolean;
  onClose: () => void;
  mission?: Mission;
  onSubmit: (mission: Partial<Mission>) => void;
}

const MissionModal: React.FC<MissionModalProps> = ({
  isOpen,
  onClose,
  mission,
  onSubmit,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-2xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              {mission ? 'Modifier la mission' : 'Nouvelle mission'}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6">
            <MissionForm
              mission={mission}
              onSubmit={(data) => {
                onSubmit(data);
                onClose();
              }}
              onCancel={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionModal;