import React, { useState, useEffect } from 'react';
import { MapPin, Clock, AlertTriangle } from 'lucide-react';
import type { Mission, Location } from '../../types/mission';

interface PresenceConfirmationProps {
  mission: Mission;
  onConfirm: (location: Location) => Promise<void>;
}

const PresenceConfirmation: React.FC<PresenceConfirmationProps> = ({
  mission,
  onConfirm,
}) => {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            timestamp: new Date().toISOString(),
          });
          setError(null);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setError('Veuillez autoriser l\'accès à la géolocalisation');
              break;
            case error.POSITION_UNAVAILABLE:
              setError('Position indisponible');
              break;
            case error.TIMEOUT:
              setError('Délai d\'attente dépassé');
              break;
            default:
              setError('Une erreur est survenue');
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setError('La géolocalisation n\'est pas supportée par votre appareil');
    }
  }, []);

  const handleConfirm = async () => {
    if (!location) return;

    setIsLoading(true);
    try {
      await onConfirm(location);
    } catch (error) {
      setError('Erreur lors de la confirmation de présence');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-sm">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium">Confirmation de présence</h3>
          <Clock className="h-5 w-5 text-gray-400" />
        </div>

        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <MapPin className="h-4 w-4" />
          <span>{mission.address.street}, {mission.address.city}</span>
        </div>

        {error ? (
          <div className="flex items-center space-x-2 text-red-600">
            <AlertTriangle className="h-5 w-5" />
            <p>{error}</p>
          </div>
        ) : location ? (
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              Position actuelle : {location.latitude.toFixed(6)}, {location.longitude.toFixed(6)}
            </p>
            {location.accuracy && (
              <p className="text-sm text-gray-500">
                Précision : ±{Math.round(location.accuracy)} mètres
              </p>
            )}
          </div>
        ) : (
          <p className="text-sm text-gray-600">
            Récupération de votre position...
          </p>
        )}

        <button
          onClick={handleConfirm}
          disabled={!location || isLoading}
          className="w-full btn bg-staff-600 text-white hover:bg-staff-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? 'Confirmation...' : 'Confirmer ma présence'}
        </button>
      </div>
    </div>
  );
};

export default PresenceConfirmation;