import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { MissionRequest, WeekDay } from '../../../types/missionRequest';

interface MissionRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  request?: MissionRequest | null;
  onSubmit: (data: Partial<MissionRequest>) => void;
}

const MissionRequestModal: React.FC<MissionRequestModalProps> = ({
  isOpen,
  onClose,
  request,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<Partial<MissionRequest>>(
    request || {
      title: '',
      type: 'medical_care',
      priority: 'medium',
      startDate: '',
      endDate: '',
      schedule: {
        days: [],
        timeSlots: [{ start: '09:00', end: '10:00' }]
      },
      address: {
        street: '',
        city: '',
        postalCode: '',
        country: 'France'
      },
      requirements: {
        specializations: [],
        notes: ''
      }
    }
  );

  const weekDays: { value: WeekDay; label: string }[] = [
    { value: 'monday', label: 'Lundi' },
    { value: 'tuesday', label: 'Mardi' },
    { value: 'wednesday', label: 'Mercredi' },
    { value: 'thursday', label: 'Jeudi' },
    { value: 'friday', label: 'Vendredi' },
    { value: 'saturday', label: 'Samedi' },
    { value: 'sunday', label: 'Dimanche' }
  ];

  const handleDayToggle = (day: WeekDay) => {
    const days = formData.schedule?.days || [];
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule!,
        days: days.includes(day)
          ? days.filter(d => d !== day)
          : [...days, day]
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-2xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              {request ? 'Modifier la demande' : 'Nouvelle demande de mission'}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Titre de la mission
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Type de mission
                </label>
                <select
                  value={formData.type}
                  onChange={(e) => setFormData({ ...formData, type: e.target.value as MissionRequest['type'] })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                >
                  <option value="medical_care">Soins médicaux</option>
                  <option value="home_assistance">Aide à domicile</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Priorité
                </label>
                <select
                  value={formData.priority}
                  onChange={(e) => setFormData({ ...formData, priority: e.target.value as MissionRequest['priority'] })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                >
                  <option value="low">Basse priorité</option>
                  <option value="medium">Normal</option>
                  <option value="high">Prioritaire</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date de début
                </label>
                <input
                  type="date"
                  value={formData.startDate}
                  onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date de fin
                </label>
                <input
                  type="date"
                  value={formData.endDate}
                  onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Jours d'intervention
              </label>
              <div className="flex flex-wrap gap-2">
                {weekDays.map((day) => (
                  <button
                    key={day.value}
                    type="button"
                    onClick={() => handleDayToggle(day.value)}
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      formData.schedule?.days.includes(day.value)
                        ? 'bg-client-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {day.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Heure de début
                </label>
                <input
                  type="time"
                  value={formData.schedule?.timeSlots[0].start}
                  onChange={(e) => setFormData({
                    ...formData,
                    schedule: {
                      ...formData.schedule!,
                      timeSlots: [
                        { ...formData.schedule!.timeSlots[0], start: e.target.value }
                      ]
                    }
                  })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Heure de fin
                </label>
                <input
                  type="time"
                  value={formData.schedule?.timeSlots[0].end}
                  onChange={(e) => setFormData({
                    ...formData,
                    schedule: {
                      ...formData.schedule!,
                      timeSlots: [
                        { ...formData.schedule!.timeSlots[0], end: e.target.value }
                      ]
                    }
                  })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                />
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">Adresse</h3>
              <div className="grid grid-cols-1 gap-4">
                <input
                  type="text"
                  placeholder="Rue"
                  value={formData.address?.street}
                  onChange={(e) => setFormData({
                    ...formData,
                    address: { ...formData.address!, street: e.target.value }
                  })}
                  className="block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                  required
                />
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Ville"
                    value={formData.address?.city}
                    onChange={(e) => setFormData({
                      ...formData,
                      address: { ...formData.address!, city: e.target.value }
                    })}
                    className="block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Code postal"
                    value={formData.address?.postalCode}
                    onChange={(e) => setFormData({
                      ...formData,
                      address: { ...formData.address!, postalCode: e.target.value }
                    })}
                    className="block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                    required
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Notes et exigences particulières
              </label>
              <textarea
                value={formData.requirements?.notes}
                onChange={(e) => setFormData({
                  ...formData,
                  requirements: { ...formData.requirements!, notes: e.target.value }
                })}
                rows={4}
                className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
                placeholder="Précisez ici toute information importante concernant la mission..."
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn bg-client-600 text-white hover:bg-client-700"
              >
                {request ? 'Modifier' : 'Créer la demande'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MissionRequestModal;