import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import AdminLayout from './layouts/AdminLayout';
import StaffLayout from './layouts/StaffLayout';
import ClientLayout from './layouts/ClientLayout';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';

// Routes Admin
import Dashboard from './pages/Dashboard';
import Staff from './pages/Staff';
import StaffDetails from './pages/StaffDetails';
import Clients from './pages/Clients';
import Missions from './pages/Missions';
import Planning from './pages/Planning';
import Documents from './pages/Documents';
import Billing from './pages/Billing';
import Messages from './pages/Messages';
import ReceivedDocuments from './pages/ReceivedDocuments';
import Notifications from './pages/Notifications';
import Settings from './pages/Settings';

// Routes Personnel Soignant
import StaffDashboard from './pages/staff/StaffDashboard';
import StaffMissions from './pages/staff/StaffMissions';
import StaffPlanning from './pages/staff/StaffPlanning';
import StaffDocuments from './pages/staff/StaffDocuments';
import StaffPayments from './pages/staff/StaffPayments';
import StaffTimeEntries from './pages/staff/TimeEntries';
import StaffProfile from './pages/staff/StaffProfile';
import StaffMessages from './pages/staff/Messages';
import StaffSettings from './pages/staff/StaffSettings';

// Routes Client
import ClientDashboard from './pages/client/ClientDashboard';
import ClientMissions from './pages/client/ClientMissions';
import ClientHistory from './pages/client/ClientHistory';
import ClientDocuments from './pages/client/ClientDocuments';
import ClientBilling from './pages/client/ClientBilling';
import ClientMessages from './pages/client/ClientMessages';
import ClientProfile from './pages/client/ClientProfile';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* Routes administrateur */}
      <Route
        path="/"
        element={
          <PrivateRoute roles={['admin']}>
            <AdminLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="staff" element={<Staff />} />
        <Route path="staff/:id" element={<StaffDetails />} />
        <Route path="clients" element={<Clients />} />
        <Route path="missions" element={<Missions />} />
        <Route path="planning" element={<Planning />} />
        <Route path="documents" element={<Documents />} />
        <Route path="billing" element={<Billing />} />
        <Route path="messages" element={<Messages />} />
        <Route path="received-documents" element={<ReceivedDocuments />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      {/* Routes personnel soignant */}
      <Route
        path="/staff"
        element={
          <PrivateRoute roles={['staff']}>
            <StaffLayout />
          </PrivateRoute>
        }
      >
        <Route path="dashboard" element={<StaffDashboard />} />
        <Route path="missions" element={<StaffMissions />} />
        <Route path="planning" element={<StaffPlanning />} />
        <Route path="documents" element={<StaffDocuments />} />
        <Route path="payments" element={<StaffPayments />} />
        <Route path="time-entries" element={<StaffTimeEntries />} />
        <Route path="messages" element={<StaffMessages />} />
        <Route path="profile" element={<StaffProfile />} />
        <Route path="settings" element={<StaffSettings />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>

      {/* Routes client */}
      <Route
        path="/client"
        element={
          <PrivateRoute roles={['client']}>
            <ClientLayout />
          </PrivateRoute>
        }
      >
        <Route path="dashboard" element={<ClientDashboard />} />
        <Route path="missions" element={<ClientMissions />} />
        <Route path="history" element={<ClientHistory />} />
        <Route path="documents" element={<ClientDocuments />} />
        <Route path="billing" element={<ClientBilling />} />
        <Route path="messages" element={<ClientMessages />} />
        <Route path="profile" element={<ClientProfile />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>

      {/* Redirection par défaut */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AppRoutes;