import React from 'react';
import { Search, Calendar } from 'lucide-react';
import type { NotificationFilters } from '../../types/notification';

interface NotificationFiltersProps {
  filters: NotificationFilters;
  onFilterChange: (filters: NotificationFilters) => void;
}

const NotificationFilters: React.FC<NotificationFiltersProps> = ({
  filters,
  onFilterChange,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher une notification..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.searchQuery || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, searchQuery: e.target.value })
                }
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.type || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  type: e.target.value as NotificationFilters['type'],
                })
              }
            >
              <option value="">Tous les types</option>
              <option value="info">Information</option>
              <option value="success">Succès</option>
              <option value="warning">Avertissement</option>
              <option value="error">Erreur</option>
            </select>

            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.category || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  category: e.target.value as NotificationFilters['category'],
                })
              }
            >
              <option value="">Toutes les catégories</option>
              <option value="mission">Missions</option>
              <option value="staff">Collaborateurs</option>
              <option value="client">Clients</option>
              <option value="document">Documents</option>
              <option value="billing">Facturation</option>
              <option value="system">Système</option>
            </select>

            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.read === undefined ? '' : String(filters.read)}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  read: e.target.value === '' ? undefined : e.target.value === 'true',
                })
              }
            >
              <option value="">Tous les statuts</option>
              <option value="false">Non lues</option>
              <option value="true">Lues</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.startDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, startDate: e.target.value })
                }
              />
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.endDate || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, endDate: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationFilters;