import React from 'react';
import { Search } from 'lucide-react';
import type { ClientFilters } from '../../types/client';

interface ClientFiltersProps {
  filters: ClientFilters;
  onFilterChange: (filters: ClientFilters) => void;
}

const ClientFilters: React.FC<ClientFiltersProps> = ({ filters, onFilterChange }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un client..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.searchQuery || ''}
              onChange={(e) =>
                onFilterChange({ ...filters, searchQuery: e.target.value })
              }
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
            value={filters.type || ''}
            onChange={(e) =>
              onFilterChange({
                ...filters,
                type: e.target.value as 'organization' | 'individual' | undefined,
              })
            }
          >
            <option value="">Tous les types</option>
            <option value="organization">Organisations</option>
            <option value="individual">Particuliers</option>
          </select>

          <select
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
            value={filters.status || ''}
            onChange={(e) =>
              onFilterChange({
                ...filters,
                status: e.target.value as 'active' | 'inactive' | undefined,
              })
            }
          >
            <option value="">Tous les statuts</option>
            <option value="active">Actif</option>
            <option value="inactive">Inactif</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default ClientFilters;