import React, { useState } from 'react';
import { Calendar, Filter } from 'lucide-react';
import TimeEntriesList from '../../components/staff/timeentries/TimeEntriesList';
import TimeEntryStats from '../../components/staff/timeentries/TimeEntryStats';
import TimeEntryFilters from '../../components/staff/timeentries/TimeEntryFilters';
import type { TimeEntry } from '../../types/mission';

const TimeEntries = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Données mockées pour l'exemple
  const timeEntries = [
    {
      id: '1',
      missionId: '1',
      type: 'start',
      timestamp: '2024-03-20T09:00:00',
      location: {
        latitude: 48.8566,
        longitude: 2.3522,
        accuracy: 10,
        timestamp: '2024-03-20T09:00:00'
      },
      status: 'valid',
      notes: 'Début de mission'
    },
    {
      id: '2',
      missionId: '1',
      type: 'end',
      timestamp: '2024-03-20T10:00:00',
      location: {
        latitude: 48.8566,
        longitude: 2.3522,
        accuracy: 10,
        timestamp: '2024-03-20T10:00:00'
      },
      status: 'valid',
      notes: 'Fin de mission'
    }
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Mes pointages</h1>
          <p className="text-sm text-gray-600 mt-1">
            Historique des pointages et temps de travail
          </p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Filter className="h-5 w-5" />
          <span>Filtres</span>
        </button>
      </div>

      <TimeEntryStats timeEntries={timeEntries} />
      
      {showFilters && <TimeEntryFilters />}
      
      <TimeEntriesList
        timeEntries={timeEntries}
      />
    </div>
  );
};

export default TimeEntries;