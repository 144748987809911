import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import SignatureCanvas from 'react-signature-canvas';
import { X, Download, PenTool } from 'lucide-react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface PDFViewerProps {
  file: string | File;
  onClose: () => void;
  onDownload?: () => void;
  onSign?: (signatureData: string) => void;
  canSign?: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  file,
  onClose,
  onDownload,
  onSign,
  canSign = false
}) => {
  const [showSignature, setShowSignature] = useState(false);
  const [signaturePosition, setSignaturePosition] = useState<{ x: number; y: number } | null>(null);
  const signatureRef = React.useRef<SignatureCanvas>(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleDocumentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (canSign && !signaturePosition) {
      const rect = e.currentTarget.getBoundingClientRect();
      setSignaturePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
      setShowSignature(true);
    }
  };

  const handleSaveSignature = () => {
    if (!signatureRef.current || !onSign) return;
    const signatureData = signatureRef.current.toDataURL();
    onSign(signatureData);
    setShowSignature(false);
    setSignaturePosition(null);
  };

  const handleClearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  return (
    <div className="h-full flex flex-col bg-white rounded-lg">
      <div className="flex items-center justify-between p-4 border-b border-gray-200">
        <div className="flex items-center space-x-4">
          {canSign && (
            <button
              onClick={() => setShowSignature(true)}
              className="flex items-center space-x-2 px-3 py-1 rounded-md bg-blue-50 text-blue-600 hover:bg-blue-100"
            >
              <PenTool className="h-4 w-4" />
              <span>Signer</span>
            </button>
          )}
          {onDownload && (
            <button
              onClick={onDownload}
              className="flex items-center space-x-2 px-3 py-1 rounded-md bg-gray-50 text-gray-600 hover:bg-gray-100"
            >
              <Download className="h-4 w-4" />
              <span>Télécharger</span>
            </button>
          )}
        </div>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="flex-1 overflow-hidden" onClick={handleDocumentClick}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={typeof file === 'string' ? file : URL.createObjectURL(file)}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>

        {showSignature && signaturePosition && (
          <div
            className="absolute bg-white border border-gray-300 rounded-lg p-4"
            style={{
              left: signaturePosition.x,
              top: signaturePosition.y,
              zIndex: 50
            }}
          >
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{
                className: 'border border-gray-300 rounded',
                width: 300,
                height: 150
              }}
            />
            <div className="flex justify-between mt-4">
              <button
                onClick={handleClearSignature}
                className="px-3 py-1 text-sm text-gray-600 hover:bg-gray-100 rounded"
              >
                Effacer
              </button>
              <button
                onClick={handleSaveSignature}
                className="px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Valider
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;