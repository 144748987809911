import React from 'react';
import { Clock, MapPin, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const MissionRequestList = () => {
  const requests = [
    {
      id: '1',
      title: 'Soins infirmiers',
      status: 'pending',
      requestDate: '2024-03-20T10:00:00',
      startDate: '2024-03-25',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013'
      },
      urgency: 'high'
    },
    {
      id: '2',
      title: 'Aide à domicile',
      status: 'processing',
      requestDate: '2024-03-19T14:00:00',
      startDate: '2024-03-24',
      address: {
        street: '8 Avenue des Lilas',
        city: 'Paris',
        postalCode: '75014'
      },
      urgency: 'medium'
    }
  ];

  return (
    <div className="space-y-4">
      {requests.map((request) => (
        <div
          key={request.id}
          className="p-4 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-medium">{request.title}</h3>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              request.urgency === 'high'
                ? 'bg-red-100 text-red-800'
                : 'bg-yellow-100 text-yellow-800'
            }`}>
              {request.urgency === 'high' ? 'Urgent' : 'Normal'}
            </span>
          </div>

          <div className="space-y-2 text-sm text-gray-600">
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-2" />
              <span>
                Demande effectuée le {format(new Date(request.requestDate), 'Pp', { locale: fr })}
              </span>
            </div>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2" />
              <span>
                Début souhaité le {format(new Date(request.startDate), 'PPP', { locale: fr })}
              </span>
            </div>
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-2" />
              <span>
                {request.address.street}, {request.address.postalCode} {request.address.city}
              </span>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <button className="text-client-600 hover:text-client-700 text-sm font-medium">
              Voir les détails
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MissionRequestList;