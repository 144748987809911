import React from 'react';
import { User } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Thread } from '../../../types/message';

interface MessageListProps {
  threads: Thread[];
  selectedThreadId?: string;
  onSelectThread: (thread: Thread) => void;
}

const MessageList: React.FC<MessageListProps> = ({
  threads,
  selectedThreadId,
  onSelectThread,
}) => {
  return (
    <div className="overflow-y-auto h-full">
      {threads.map((thread) => (
        <button
          key={thread.id}
          className={`w-full text-left p-4 border-b border-gray-200 hover:bg-gray-50 focus:outline-none ${
            thread.id === selectedThreadId ? 'bg-gray-50' : ''
          }`}
          onClick={() => onSelectThread(thread)}
        >
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              {thread.participants[0].avatar ? (
                <img
                  src={thread.participants[0].avatar}
                  alt={thread.participants[0].name}
                  className="h-10 w-10 rounded-full"
                />
              ) : (
                <div className="h-10 w-10 rounded-full bg-staff-100 flex items-center justify-center">
                  <User className="h-5 w-5 text-staff-600" />
                </div>
              )}
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {thread.participants[0].name}
                </p>
                <p className="text-xs text-gray-500">
                  {format(new Date(thread.lastMessage.timestamp), 'HH:mm', { locale: fr })}
                </p>
              </div>
              <div className="flex items-center justify-between mt-1">
                <p className="text-sm text-gray-500 truncate">
                  {thread.lastMessage.content}
                </p>
                {thread.unreadCount > 0 && (
                  <span className="inline-flex items-center justify-center h-5 w-5 rounded-full bg-staff-600 text-xs font-medium text-white">
                    {thread.unreadCount}
                  </span>
                )}
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default MessageList;