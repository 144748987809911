import React from 'react';
import { Clock, MapPin, Calendar, AlertTriangle, CreditCard } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Mission } from '../../../types/mission';

interface MissionListProps {
  missions: Mission[];
  onTimeEntry: (missionId: string, type: 'start' | 'end', location: any) => Promise<void>;
}

const MissionList: React.FC<MissionListProps> = ({ missions, onTimeEntry }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {missions.map((mission) => (
        <div
          key={mission.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="font-medium text-lg">{mission.title}</h3>
              <div className="flex items-center space-x-2 text-sm text-gray-500 mt-1">
                <Calendar className="h-4 w-4" />
                <span>
                  {format(new Date(mission.startDate), 'PPp', { locale: fr })}
                </span>
              </div>
            </div>
            <div className={`px-3 py-1 rounded-full text-sm font-medium ${
              mission.priority === 'high'
                ? 'bg-red-100 text-red-800'
                : mission.priority === 'medium'
                ? 'bg-yellow-100 text-yellow-800'
                : 'bg-green-100 text-green-800'
            }`}>
              {mission.priority === 'high' ? 'Prioritaire' : 
               mission.priority === 'medium' ? 'Normal' : 'Basse'}
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <MapPin className="h-5 w-5" />
              <span>{mission.address.street}, {mission.address.city}</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>
                {format(new Date(mission.startDate), 'HH:mm')} - {format(new Date(mission.endDate), 'HH:mm')}
              </span>
            </div>
            <div className="flex items-center space-x-2 text-gray-600">
              <CreditCard className="h-5 w-5" />
              <span>{mission.ratePerHour}€/heure</span>
            </div>
            {mission.description && (
              <div className="flex items-start space-x-2 text-gray-600">
                <AlertTriangle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                <p>{mission.description}</p>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            {mission.status === 'pending' && (
              <button
                onClick={() => onTimeEntry(mission.id, 'start', null)}
                className="btn bg-staff-600 text-white hover:bg-staff-700"
              >
                Débuter la mission
              </button>
            )}
            {mission.status === 'in_progress' && (
              <button
                onClick={() => onTimeEntry(mission.id, 'end', null)}
                className="btn bg-staff-600 text-white hover:bg-staff-700"
              >
                Terminer la mission
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MissionList;