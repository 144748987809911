import React from 'react';
import { Building2, User, MapPin, Phone, Mail, MoreVertical } from 'lucide-react';
import type { Client } from '../../types/client';

interface ClientListProps {
  clients: Client[];
  onEdit: (client: Client) => void;
  onDelete: (id: string) => void;
}

const ClientList: React.FC<ClientListProps> = ({ clients, onEdit, onDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
      {clients.map((client) => (
        <div key={client.id} className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-start">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center">
                {client.type === 'organization' ? (
                  <Building2 className="h-6 w-6 text-indigo-600" />
                ) : (
                  <User className="h-6 w-6 text-indigo-600" />
                )}
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">{client.name}</h3>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 mt-1">
                  {client.type === 'organization' ? 'Organisation' : 'Particulier'}
                </span>
              </div>
            </div>
            <div className="relative">
              <button className="p-2 hover:bg-gray-100 rounded-full">
                <MoreVertical className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>

          {client.contactPerson && (
            <p className="mt-2 text-sm text-gray-600">
              Contact: {client.contactPerson}
            </p>
          )}

          <div className="mt-4 space-y-2">
            <div className="flex items-center text-sm text-gray-600">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{client.address.city}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Phone className="h-4 w-4 mr-2" />
              <span>{client.phone}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Mail className="h-4 w-4 mr-2" />
              <span>{client.email}</span>
            </div>
          </div>

          <div className="mt-4 pt-4 border-t border-gray-200 flex justify-between">
            <button
              onClick={() => onEdit(client)}
              className="btn btn-secondary text-sm"
            >
              Modifier
            </button>
            <button
              onClick={() => onDelete(client.id)}
              className="btn text-sm text-red-600 hover:text-red-700"
            >
              Supprimer
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientList;