import React from 'react';
import { Search, Filter, Calendar } from 'lucide-react';

interface MissionRequestFiltersProps {
  show: boolean;
  onToggle: () => void;
}

const MissionRequestFilters: React.FC<MissionRequestFiltersProps> = ({
  show,
  onToggle,
}) => {
  if (!show) {
    return (
      <button
        onClick={onToggle}
        className="mb-6 flex items-center space-x-2 text-gray-600 hover:text-gray-900"
      >
        <Filter className="h-5 w-5" />
        <span>Afficher les filtres</span>
      </button>
    );
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-medium">Filtres</h3>
        <button
          onClick={onToggle}
          className="text-gray-600 hover:text-gray-900"
        >
          Masquer les filtres
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500">
          <option value="">Tous les types</option>
          <option value="medical_care">Soins médicaux</option>
          <option value="home_assistance">Aide à domicile</option>
        </select>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500">
          <option value="">Tous les statuts</option>
          <option value="pending">En attente</option>
          <option value="approved">Approuvée</option>
          <option value="rejected">Refusée</option>
          <option value="cancelled">Annulée</option>
        </select>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500">
          <option value="">Toutes les priorités</option>
          <option value="high">Prioritaire</option>
          <option value="medium">Normal</option>
          <option value="low">Basse priorité</option>
        </select>
      </div>

      <div className="mt-4 flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <Calendar className="h-5 w-5 text-gray-400" />
          <input
            type="date"
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
            placeholder="Date de début"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Calendar className="h-5 w-5 text-gray-400" />
          <input
            type="date"
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
            placeholder="Date de fin"
          />
        </div>
      </div>
    </div>
  );
};

export default MissionRequestFilters;