import React from 'react';
import { Users, Calendar, FileText, AlertTriangle } from 'lucide-react';

const StatsCard = ({ icon: Icon, label, value, change, changeType }: {
  icon: React.ElementType;
  label: string;
  value: string;
  change: string;
  changeType: 'increase' | 'decrease';
}) => (
  <div className="bg-white rounded-xl shadow-sm p-6">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm font-medium text-gray-600">{label}</p>
        <p className="text-2xl font-semibold mt-2">{value}</p>
      </div>
      <div className={`p-3 rounded-full ${
        label === 'Collaborateurs' ? 'bg-blue-100' :
        label === 'Missions' ? 'bg-green-100' :
        label === 'Documents' ? 'bg-purple-100' :
        'bg-orange-100'
      }`}>
        <Icon className={`h-6 w-6 ${
          label === 'Collaborateurs' ? 'text-blue-600' :
          label === 'Missions' ? 'text-green-600' :
          label === 'Documents' ? 'text-purple-600' :
          'text-orange-600'
        }`} />
      </div>
    </div>
    <div className="mt-4 flex items-center">
      <span className={`text-sm ${
        changeType === 'increase' ? 'text-green-600' : 'text-red-600'
      }`}>
        {change}
      </span>
      <span className="text-sm text-gray-500 ml-2">vs mois dernier</span>
    </div>
  </div>
);

const DashboardStats = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <StatsCard
        icon={Users}
        label="Collaborateurs"
        value="42"
        change="+12%"
        changeType="increase"
      />
      <StatsCard
        icon={Calendar}
        label="Missions"
        value="156"
        change="+8%"
        changeType="increase"
      />
      <StatsCard
        icon={FileText}
        label="Documents"
        value="89"
        change="+5%"
        changeType="increase"
      />
      <StatsCard
        icon={AlertTriangle}
        label="Alertes"
        value="3"
        change="-2"
        changeType="decrease"
      />
    </div>
  );
};

export default DashboardStats;