import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { Calendar, Download } from 'lucide-react';
import type { Mission } from '../../../types/mission';

interface ModernCalendarProps {
  missions: Mission[];
  onEventClick: (info: any) => void;
  onDateSelect: (info: any) => void;
  availabilities?: {
    [key: string]: {
      morning: boolean;
      afternoon: boolean;
      night: boolean;
    };
  };
}

const ModernCalendar: React.FC<ModernCalendarProps> = ({
  missions,
  onEventClick,
  onDateSelect,
  availabilities = {},
}) => {
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [calendarUrl, setCalendarUrl] = useState('');

  // Convertir les missions en événements avec des couleurs différentes selon le statut
  const missionEvents = missions.map(mission => ({
    id: mission.id,
    title: mission.title,
    start: mission.startDate,
    end: mission.endDate,
    backgroundColor: mission.status === 'assigned' ? '#10B981' : // Vert pour les missions assignées
                    mission.status === 'in_progress' ? '#3B82F6' : // Bleu pour les missions en cours
                    '#F6207F', // Rose pour les autres statuts
    borderColor: mission.status === 'assigned' ? '#059669' :
                mission.status === 'in_progress' ? '#2563EB' :
                '#F6207F',
    textColor: '#FFFFFF',
    extendedProps: {
      type: 'mission',
      mission,
    },
  }));

  // Convertir les disponibilités en événements
  const availabilityEvents = Object.entries(availabilities).flatMap(([date, periods]) => {
    const events = [];

    if (periods.morning) {
      events.push({
        title: 'Disponible - Matin',
        start: `${date}T08:00:00`,
        end: `${date}T12:00:00`,
        backgroundColor: 'rgba(246, 32, 127, 0.2)',
        borderColor: '#F6207F',
        textColor: '#F6207F',
        extendedProps: {
          type: 'availability',
          period: 'morning',
        },
      });
    }

    if (periods.afternoon) {
      events.push({
        title: 'Disponible - Après-midi',
        start: `${date}T14:00:00`,
        end: `${date}T18:00:00`,
        backgroundColor: 'rgba(246, 32, 127, 0.2)',
        borderColor: '#F6207F',
        textColor: '#F6207F',
        extendedProps: {
          type: 'availability',
          period: 'afternoon',
        },
      });
    }

    if (periods.night) {
      events.push({
        title: 'Disponible - Nuit',
        start: `${date}T20:00:00`,
        end: `${date}T23:59:59`,
        backgroundColor: 'rgba(246, 32, 127, 0.2)',
        borderColor: '#F6207F',
        textColor: '#F6207F',
        extendedProps: {
          type: 'availability',
          period: 'night',
        },
      });
    }

    return events;
  });

  // Combiner tous les événements
  const events = [...missionEvents, ...availabilityEvents];

  const handleExportCalendar = () => {
    // Générer une URL pour l'export du calendrier
    const calendarUrl = window.location.origin + '/api/calendar/export';
    setCalendarUrl(calendarUrl);
    setShowSyncModal(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        {/* Légende */}
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-[#F6207F]" />
            <span className="text-sm">Disponibilités</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-[#10B981]" />
            <span className="text-sm">Missions assignées</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-[#3B82F6]" />
            <span className="text-sm">Missions en cours</span>
          </div>
        </div>

        <button
          onClick={handleExportCalendar}
          className="btn btn-secondary flex items-center space-x-2"
        >
          <Calendar className="h-5 w-5" />
          <span>Exporter</span>
        </button>
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        locale={frLocale}
        events={events}
        eventClick={onEventClick}
        select={onDateSelect}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        allDaySlot={false}
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        height="auto"
        eventContent={({ event }) => (
          <div className="p-1">
            <div className="font-medium">{event.title}</div>
            {event.extendedProps.type === 'mission' && (
              <div className="text-xs opacity-75">
                {new Date(event.start!).toLocaleTimeString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' - '}
                {new Date(event.end!).toLocaleTimeString('fr-FR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}
          </div>
        )}
      />

      {showSyncModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-md w-full p-6">
              <h3 className="text-lg font-medium mb-4">Exporter le calendrier</h3>
              <div className="space-y-4">
                <button
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = calendarUrl;
                    link.download = 'calendar.ics';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  className="w-full flex items-center justify-between p-4 bg-white border rounded-lg hover:bg-gray-50"
                >
                  <span>Télécharger le fichier ICS</span>
                  <Download className="h-5 w-5 text-gray-600" />
                </button>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => setShowSyncModal(false)}
                  className="w-full btn btn-secondary"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModernCalendar;