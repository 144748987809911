import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import InvoiceList from '../components/billing/InvoiceList';
import BillingFilters from '../components/billing/BillingFilters';
import BillingStats from '../components/billing/BillingStats';
import InvoiceModal from '../components/billing/InvoiceModal';
import type { Invoice, BillingFilters as Filters } from '../types/billing';

const mockInvoices: Invoice[] = [
  {
    id: '1',
    number: 'INV-2024-001',
    clientId: '1',
    clientName: 'Centre Médical Saint-Jean',
    status: 'paid',
    issueDate: '2024-03-01',
    dueDate: '2024-03-31',
    items: [
      {
        id: '1',
        serviceId: '1',
        description: 'Soins infirmiers - Mars 2024',
        quantity: 20,
        rate: 35,
        amount: 700,
        date: '2024-03-01'
      }
    ],
    subtotal: 700,
    tax: 140,
    total: 840,
    paymentMethod: 'bank_transfer',
    paymentDate: '2024-03-15'
  },
  {
    id: '2',
    number: 'INV-2024-002',
    clientId: '2',
    clientName: 'Martin Robert',
    status: 'sent',
    issueDate: '2024-03-15',
    dueDate: '2024-04-14',
    items: [
      {
        id: '2',
        serviceId: '2',
        description: 'Aide à domicile - Mars 2024',
        quantity: 10,
        rate: 25,
        amount: 250,
        date: '2024-03-15'
      }
    ],
    subtotal: 250,
    tax: 50,
    total: 300
  }
];

const Billing = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [invoices, setInvoices] = useState<Invoice[]>(mockInvoices);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateInvoice = () => {
    setSelectedInvoice(undefined);
    setIsModalOpen(true);
  };

  const handleEditInvoice = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setIsModalOpen(true);
  };

  const handleDeleteInvoice = (id: string) => {
    setInvoices(invoices.filter((invoice) => invoice.id !== id));
  };

  const handleSubmitInvoice = (invoiceData: Partial<Invoice>) => {
    if (selectedInvoice) {
      // Modification
      setInvoices(
        invoices.map((invoice) =>
          invoice.id === selectedInvoice.id
            ? { ...invoice, ...invoiceData }
            : invoice
        )
      );
    } else {
      // Création
      const newInvoice: Invoice = {
        id: Math.random().toString(36).substr(2, 9),
        ...invoiceData,
      } as Invoice;
      setInvoices([...invoices, newInvoice]);
    }
    setIsModalOpen(false);
  };

  const handleDownloadInvoice = (invoice: Invoice) => {
    // Logique de téléchargement à implémenter
    console.log('Download invoice:', invoice);
  };

  const handleSendInvoice = (invoice: Invoice) => {
    setInvoices(
      invoices.map((inv) =>
        inv.id === invoice.id
          ? { ...inv, status: 'sent' }
          : inv
      )
    );
  };

  const filteredInvoices = invoices.filter((invoice) => {
    if (filters.status && invoice.status !== filters.status) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        invoice.number.toLowerCase().includes(query) ||
        invoice.clientName.toLowerCase().includes(query)
      );
    }
    if (filters.startDate && new Date(invoice.issueDate) < new Date(filters.startDate)) return false;
    if (filters.endDate && new Date(invoice.issueDate) > new Date(filters.endDate)) return false;
    if (filters.minAmount && invoice.total < filters.minAmount) return false;
    if (filters.maxAmount && invoice.total > filters.maxAmount) return false;
    return true;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Facturation</h1>
        <button 
          onClick={handleCreateInvoice}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Nouvelle facture</span>
        </button>
      </div>

      <BillingStats invoices={invoices} />
      <BillingFilters filters={filters} onFilterChange={setFilters} />
      <InvoiceList
        invoices={filteredInvoices}
        onEdit={handleEditInvoice}
        onDelete={handleDeleteInvoice}
        onDownload={handleDownloadInvoice}
        onSend={handleSendInvoice}
      />

      <InvoiceModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        invoice={selectedInvoice}
        onSubmit={handleSubmitInvoice}
      />
    </div>
  );
};

export default Billing;