import React, { createContext, useContext, useState } from 'react';
import type { StaffAvailability } from '../types/availability';

interface AvailabilityContextType {
  availabilities: {
    [staffId: string]: {
      name: string;
      role: string;
      availabilities: {
        [date: string]: {
          morning: boolean;
          afternoon: boolean;
          night: boolean;
        };
      };
    };
  };
  updateAvailability: (
    staffId: string,
    date: string,
    period: 'morning' | 'afternoon' | 'night',
    available: boolean
  ) => void;
  getStaffAvailability: (staffId: string) => {
    [date: string]: {
      morning: boolean;
      afternoon: boolean;
      night: boolean;
    };
  };
}

const AvailabilityContext = createContext<AvailabilityContextType | undefined>(undefined);

export const AvailabilityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [availabilities, setAvailabilities] = useState<{
    [staffId: string]: {
      name: string;
      role: string;
      availabilities: {
        [date: string]: {
          morning: boolean;
          afternoon: boolean;
          night: boolean;
        };
      };
    };
  }>({
    'staff-1': {
      name: 'Marie Dubois',
      role: 'nurse',
      availabilities: {
        '2024-03-20': {
          morning: true,
          afternoon: false,
          night: false
        },
        '2024-03-21': {
          morning: true,
          afternoon: true,
          night: false
        }
      }
    },
    'staff-2': {
      name: 'Jean Martin',
      role: 'nurse',
      availabilities: {
        '2024-03-20': {
          morning: false,
          afternoon: true,
          night: false
        },
        '2024-03-21': {
          morning: false,
          afternoon: true,
          night: true
        }
      }
    }
  });

  const updateAvailability = (
    staffId: string,
    date: string,
    period: 'morning' | 'afternoon' | 'night',
    available: boolean
  ) => {
    setAvailabilities(prev => {
      const staffData = prev[staffId] || {
        name: '',
        role: '',
        availabilities: {}
      };

      return {
        ...prev,
        [staffId]: {
          ...staffData,
          availabilities: {
            ...staffData.availabilities,
            [date]: {
              ...staffData.availabilities[date],
              [period]: available,
            },
          },
        },
      };
    });
  };

  const getStaffAvailability = (staffId: string) => {
    return availabilities[staffId]?.availabilities || {};
  };

  return (
    <AvailabilityContext.Provider
      value={{
        availabilities,
        updateAvailability,
        getStaffAvailability,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};

export const useAvailability = () => {
  const context = useContext(AvailabilityContext);
  if (context === undefined) {
    throw new Error('useAvailability must be used within an AvailabilityProvider');
  }
  return context;
};