import React from 'react';
import { CreditCard, TrendingUp, Clock, Calendar } from 'lucide-react';

interface Payment {
  id: string;
  period: string;
  amount: number;
  status: 'paid' | 'pending';
  paidAt?: string;
  details: {
    totalHours: number;
    regularHours: number;
    overtimeHours: number;
    regularRate: number;
    overtimeRate: number;
    missions: number;
  };
}

interface PaymentStatsProps {
  payments: Payment[];
}

const PaymentStats: React.FC<PaymentStatsProps> = ({ payments }) => {
  const currentMonth = payments[0] || { amount: 0, details: { totalHours: 0, missions: 0 } };
  const previousMonth = payments[1] || { amount: 0, details: { totalHours: 0, missions: 0 } };

  const calculateGrowth = (current: number, previous: number) => {
    if (previous === 0) return 100;
    return ((current - previous) / previous) * 100;
  };

  const revenueGrowth = calculateGrowth(currentMonth.amount, previousMonth.amount);
  const hoursGrowth = calculateGrowth(
    currentMonth.details.totalHours,
    previousMonth.details.totalHours
  );
  const missionsGrowth = calculateGrowth(
    currentMonth.details.missions,
    previousMonth.details.missions
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Revenus du mois</p>
            <p className="text-2xl font-semibold mt-2">
              {currentMonth.amount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
            </p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <CreditCard className="h-6 w-6 text-staff-600" />
          </div>
        </div>
        <div className="mt-2">
          <span className={`text-sm ${revenueGrowth >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {revenueGrowth >= 0 ? '+' : ''}{revenueGrowth.toFixed(1)}%
          </span>
          <span className="text-sm text-gray-500"> vs mois dernier</span>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Heures travaillées</p>
            <p className="text-2xl font-semibold mt-2">{currentMonth.details.totalHours}h</p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <Clock className="h-6 w-6 text-staff-600" />
          </div>
        </div>
        <div className="mt-2">
          <span className={`text-sm ${hoursGrowth >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {hoursGrowth >= 0 ? '+' : ''}{hoursGrowth.toFixed(1)}%
          </span>
          <span className="text-sm text-gray-500"> vs mois dernier</span>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Missions réalisées</p>
            <p className="text-2xl font-semibold mt-2">{currentMonth.details.missions}</p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <Calendar className="h-6 w-6 text-staff-600" />
          </div>
        </div>
        <div className="mt-2">
          <span className={`text-sm ${missionsGrowth >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {missionsGrowth >= 0 ? '+' : ''}{missionsGrowth.toFixed(1)}%
          </span>
          <span className="text-sm text-gray-500"> vs mois dernier</span>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Taux horaire moyen</p>
            <p className="text-2xl font-semibold mt-2">
              {(currentMonth.amount / currentMonth.details.totalHours).toFixed(2)}€/h
            </p>
          </div>
          <div className="p-3 rounded-full bg-staff-100">
            <TrendingUp className="h-6 w-6 text-staff-600" />
          </div>
        </div>
        <div className="mt-2">
          <span className="text-sm text-gray-500">Basé sur {currentMonth.details.totalHours} heures</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentStats;