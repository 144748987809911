import React, { useState } from 'react';
import { Save } from 'lucide-react';
import GeneralSettings from '../components/settings/GeneralSettings';
import NotificationSettings from '../components/settings/NotificationSettings';
import BillingSettings from '../components/settings/BillingSettings';
import SecuritySettings from '../components/settings/SecuritySettings';
import AdminManagement from '../components/settings/AdminManagement';
import AdminModal from '../components/settings/AdminModal';
import type { SystemSettings, SettingsSection } from '../types/settings';
import type { AdminUser } from '../types/user';

const mockSettings: SystemSettings = {
  general: {
    companyName: 'Hope Care Interim',
    email: 'contact@hopecareinterim.fr',
    phone: '+33 1 23 45 67 89',
    address: {
      street: '123 Avenue de la Santé',
      city: 'Paris',
      postalCode: '75001',
      country: 'France'
    }
  },
  notifications: {
    emailNotifications: true,
    smsNotifications: true,
    pushNotifications: false,
    dailyDigest: true,
    reminderDelay: 24
  },
  billing: {
    currency: 'EUR',
    taxRate: 20,
    paymentTerms: 30,
    bankDetails: {
      bankName: 'Banque Nationale',
      iban: 'FR76 1234 5678 9012 3456 7890 123',
      bic: 'BNPAFRPP'
    }
  },
  security: {
    passwordPolicy: {
      minLength: 8,
      requireNumbers: true,
      requireSpecialChars: true,
      requireUppercase: true,
      expiryDays: 90
    },
    sessionTimeout: 30,
    twoFactorAuth: true
  }
};

const mockAdmins: AdminUser[] = [
  {
    id: '1',
    email: 'admin@hopecareinterim.fr',
    firstName: 'Jean',
    lastName: 'Dupont',
    role: 'admin',
    status: 'active',
    createdAt: '2024-01-01T00:00:00Z',
    lastLogin: '2024-03-20T10:00:00Z',
    department: 'Direction',
    phoneNumber: '+33 6 12 34 56 78',
    permissions: ['all'],
    emergencyContact: {
      name: 'Marie Dupont',
      relationship: 'Épouse',
      phone: '+33 6 98 76 54 32'
    }
  }
];

const Settings = () => {
  const [activeSection, setActiveSection] = useState<SettingsSection>('general');
  const [settings, setSettings] = useState<SystemSettings>(mockSettings);
  const [admins, setAdmins] = useState<AdminUser[]>(mockAdmins);
  const [isSaving, setIsSaving] = useState(false);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<AdminUser | undefined>();

  const handleSave = async () => {
    setIsSaving(true);
    // Simuler une sauvegarde
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsSaving(false);
  };

  const updateSettings = (section: SettingsSection, newData: Partial<SystemSettings>) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        ...newData[section]
      }
    }));
  };

  const handleAddAdmin = () => {
    setSelectedAdmin(undefined);
    setIsAdminModalOpen(true);
  };

  const handleEditAdmin = (admin: AdminUser) => {
    setSelectedAdmin(admin);
    setIsAdminModalOpen(true);
  };

  const handleDeleteAdmin = (id: string) => {
    setAdmins(admins.filter(admin => admin.id !== id));
  };

  const handleSubmitAdmin = (adminData: Partial<AdminUser>) => {
    if (selectedAdmin) {
      // Modification
      setAdmins(admins.map(admin =>
        admin.id === selectedAdmin.id
          ? { ...admin, ...adminData }
          : admin
      ));
    } else {
      // Création
      const newAdmin: AdminUser = {
        id: Math.random().toString(36).substr(2, 9),
        role: 'admin',
        status: 'active',
        createdAt: new Date().toISOString(),
        permissions: ['all'],
        ...adminData,
      } as AdminUser;
      setAdmins([...admins, newAdmin]);
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Paramètres du système</h1>
        <button
          onClick={handleSave}
          disabled={isSaving}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Save className="h-5 w-5" />
          <span>{isSaving ? 'Enregistrement...' : 'Enregistrer'}</span>
        </button>
      </div>

      <div className="flex space-x-6">
        <div className="w-64 flex-shrink-0">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <nav className="space-y-1">
              {(['general', 'notifications', 'billing', 'security', 'admins'] as const).map((section) => (
                <button
                  key={section}
                  onClick={() => setActiveSection(section)}
                  className={`w-full px-4 py-2 text-left rounded-lg transition-colors ${
                    activeSection === section
                      ? 'bg-primary-50 text-primary-700'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  {section === 'general' && 'Général'}
                  {section === 'notifications' && 'Notifications'}
                  {section === 'billing' && 'Facturation'}
                  {section === 'security' && 'Sécurité'}
                  {section === 'admins' && 'Administrateurs'}
                </button>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex-1">
          <div className="bg-white rounded-lg shadow-sm p-6">
            {activeSection === 'general' && (
              <GeneralSettings
                settings={settings.general}
                onChange={(data) => updateSettings('general', { general: data })}
              />
            )}
            {activeSection === 'notifications' && (
              <NotificationSettings
                settings={settings.notifications}
                onChange={(data) => updateSettings('notifications', { notifications: data })}
              />
            )}
            {activeSection === 'billing' && (
              <BillingSettings
                settings={settings.billing}
                onChange={(data) => updateSettings('billing', { billing: data })}
              />
            )}
            {activeSection === 'security' && (
              <SecuritySettings
                settings={settings.security}
                onChange={(data) => updateSettings('security', { security: data })}
              />
            )}
            {activeSection === 'admins' && (
              <AdminManagement
                admins={admins}
                onAddAdmin={handleAddAdmin}
                onEditAdmin={handleEditAdmin}
                onDeleteAdmin={handleDeleteAdmin}
              />
            )}
          </div>
        </div>
      </div>

      <AdminModal
        isOpen={isAdminModalOpen}
        onClose={() => setIsAdminModalOpen(false)}
        admin={selectedAdmin}
        onSubmit={handleSubmitAdmin}
      />
    </div>
  );
};

export default Settings;