import React from 'react';
import { Search } from 'lucide-react';

const PlanningFilters = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une mission..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-staff-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-staff-500">
          <option value="">Tous les types</option>
          <option value="medical_care">Soins médicaux</option>
          <option value="home_assistance">Aide à domicile</option>
        </select>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-staff-500">
          <option value="">Toutes les priorités</option>
          <option value="high">Haute</option>
          <option value="medium">Moyenne</option>
          <option value="low">Basse</option>
        </select>
      </div>
    </div>
  );
};

export default PlanningFilters;