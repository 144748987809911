import React, { useState } from 'react';
import { Calendar, Clock, MapPin, CheckCircle, AlertTriangle, User, FileText, CreditCard, TrendingUp } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuth } from '../../contexts/AuthContext';
import MissionCard from '../../components/staff/MissionCard';
import RevenueChart from '../../components/staff/RevenueChart';
import DocumentsList from '../../components/staff/DocumentsList';
import ProfileCompletionCard from '../../components/staff/ProfileCompletionCard';
import type { TimeEntry } from '../../types/mission';

const StaffDashboard = () => {
  const { user } = useAuth();
  const [isConfirming, setIsConfirming] = useState<string | null>(null);

  const stats = {
    totalRevenue: 3250.00,
    pendingPayments: 850.00,
    completedMissions: 18,
    upcomingMissions: 5,
    monthlyHours: 120,
    rating: 4.8
  };

  const staffProfile = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: '+33 6 12 34 56 78',
    inamiNumber: '12345678901',
    tvaNumber: 'BE0123456789',
    address: {
      street: '15 Rue de la Santé',
      city: 'Paris',
      postalCode: '75013'
    },
    bankDetails: {
      iban: 'FR76 1234 5678 9012 3456 7890 123',
      bic: 'BNPAFRPP',
      bankName: 'BNP Paribas'
    },
    documents: [
      { id: '1', type: 'id', verified: true },
      { id: '2', type: 'diploma', verified: false },
      { id: '3', type: 'insurance', verified: true },
      { id: '4', type: 'inami', verified: false }
    ]
  };

  const handleTimeEntry = async (entry: Partial<TimeEntry>) => {
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Time entry:', entry);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-4 sm:p-6">
      <div className="mb-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <div className="flex items-center space-x-4 mb-4 sm:mb-0">
            <div className="h-12 w-12 sm:h-16 sm:w-16 rounded-full bg-staff-100 flex items-center justify-center">
              {user?.avatar ? (
                <img
                  src={user.avatar}
                  alt={`${user.firstName} ${user.lastName}`}
                  className="h-12 w-12 sm:h-16 sm:w-16 rounded-full object-cover"
                />
              ) : (
                <User className="h-6 w-6 sm:h-8 sm:w-8 text-staff-600" />
              )}
            </div>
            <div>
              <h1 className="text-xl sm:text-2xl font-bold">Bonjour, {user?.firstName} 👋</h1>
              <p className="text-sm sm:text-base text-gray-600">Voici le résumé de votre activité</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-6">
        <StatsCard
          icon={CreditCard}
          title="Revenus du mois"
          value={stats.totalRevenue}
          change={12}
          type="currency"
        />
        <StatsCard
          icon={Clock}
          title="Heures travaillées"
          value={stats.monthlyHours}
          suffix="h"
          target={150}
        />
        <StatsCard
          icon={CheckCircle}
          title="Missions terminées"
          value={stats.completedMissions}
          period="Ce mois-ci"
        />
        <StatsCard
          icon={TrendingUp}
          title="Note moyenne"
          value={stats.rating}
          suffix="/5"
          reviews={45}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
        <div className="lg:col-span-2">
          <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
            <h2 className="text-lg font-semibold mb-4">Revenus</h2>
            <div className="h-64 sm:h-80">
              <RevenueChart />
            </div>
          </div>
        </div>

        <div>
          <ProfileCompletionCard profile={staffProfile} />
        </div>
      </div>

      <div className="mt-4 sm:mt-6">
        <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
          <h2 className="text-lg font-semibold mb-4">Documents récents</h2>
          <DocumentsList />
        </div>
      </div>

      <div className="mt-4 sm:mt-6">
        <h2 className="text-lg font-semibold mb-4">Missions du jour</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
          <MissionCard
            mission={{
              id: '1',
              title: 'Soins à domicile',
              startDate: '2024-03-20T09:00:00',
              endDate: '2024-03-20T10:00:00',
              status: 'pending',
              type: 'medical_care',
              priority: 'high',
              address: {
                street: '15 Rue de la Santé',
                city: 'Paris',
                postalCode: '75013',
                country: 'France'
              },
              description: 'Prise de tension et injection insuline'
            }}
            onTimeEntry={handleTimeEntry}
          />
        </div>
      </div>
    </div>
  );
};

interface StatsCardProps {
  icon: React.ElementType;
  title: string;
  value: number;
  change?: number;
  type?: 'currency' | 'number';
  suffix?: string;
  target?: number;
  period?: string;
  reviews?: number;
}

const StatsCard: React.FC<StatsCardProps> = ({
  icon: Icon,
  title,
  value,
  change,
  type = 'number',
  suffix = '',
  target,
  period,
  reviews
}) => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className="text-lg sm:text-2xl font-semibold mt-1 sm:mt-2">
            {type === 'currency' 
              ? value.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
              : value + suffix}
          </p>
        </div>
        <div className="p-2 sm:p-3 rounded-full bg-staff-100">
          <Icon className="h-5 w-5 sm:h-6 sm:w-6 text-staff-600" />
        </div>
      </div>
      <div className="mt-2">
        {change !== undefined && (
          <>
            <span className="text-sm text-green-600">+{change}% </span>
            <span className="text-sm text-gray-500">vs mois dernier</span>
          </>
        )}
        {target && (
          <span className="text-sm text-gray-500">Objectif: {target}h</span>
        )}
        {period && (
          <span className="text-sm text-gray-500">{period}</span>
        )}
        {reviews && (
          <span className="text-sm text-gray-500">Basé sur {reviews} avis</span>
        )}
      </div>
    </div>
  );
};

export default StaffDashboard;