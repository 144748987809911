import React from 'react';
import { AlertTriangle, FileText } from 'lucide-react';
import ClientStats from '../../components/client/dashboard/ClientStats';
import MissionRequestList from '../../components/client/dashboard/MissionRequestList';
import ActiveMissionsList from '../../components/client/dashboard/ActiveMissionsList';
import RecentDocuments from '../../components/client/dashboard/RecentDocuments';

const ClientDashboard = () => {
  const stats = {
    activeMissions: 3,
    pendingRequests: 2,
    completedMissions: 15,
    totalSpent: 2500.00
  };

  return (
    <div className="p-4 sm:p-6">
      <div className="mb-6">
        <h1 className="text-xl sm:text-2xl font-bold">Tableau de bord</h1>
      </div>

      <ClientStats stats={stats} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 mt-6">
        <div className="space-y-4 sm:space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
            <h2 className="text-lg font-medium mb-4">Demandes en cours</h2>
            <MissionRequestList />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
            <h2 className="text-lg font-medium mb-4">Missions actives</h2>
            <ActiveMissionsList />
          </div>
        </div>

        <div className="space-y-4 sm:space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
            <h2 className="text-lg font-medium mb-4">Documents récents</h2>
            <RecentDocuments />
          </div>

          <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
            <h2 className="text-lg font-medium mb-4">Alertes</h2>
            <div className="space-y-4">
              <div className="flex items-start space-x-3 p-3 sm:p-4 bg-yellow-50 text-yellow-800 rounded-lg">
                <AlertTriangle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="font-medium">Mission à renouveler</p>
                  <p className="text-sm">La mission de soins quotidiens arrive à échéance dans 5 jours</p>
                </div>
              </div>
              <div className="flex items-start space-x-3 p-3 sm:p-4 bg-blue-50 text-blue-800 rounded-lg">
                <FileText className="h-5 w-5 flex-shrink-0 mt-0.5" />
                <div>
                  <p className="font-medium">Document à signer</p>
                  <p className="text-sm">Une nouvelle convention est en attente de signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;