import React from 'react';
import { CreditCard, Clock, CheckCircle, AlertTriangle } from 'lucide-react';
import type { Invoice } from '../../../types/billing';

interface BillingStatsProps {
  invoices: Invoice[];
}

const BillingStats: React.FC<BillingStatsProps> = ({ invoices }) => {
  const totalPaid = invoices
    .filter(invoice => invoice.status === 'paid')
    .reduce((sum, invoice) => sum + invoice.total, 0);

  const totalPending = invoices
    .filter(invoice => invoice.status === 'pending')
    .reduce((sum, invoice) => sum + invoice.total, 0);

  const overdueInvoices = invoices.filter(invoice => 
    invoice.status === 'pending' && new Date(invoice.dueDate) < new Date()
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Total payé</p>
            <p className="text-2xl font-semibold mt-2">
              {totalPaid.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
            </p>
          </div>
          <div className="p-3 rounded-full bg-green-100">
            <CheckCircle className="h-6 w-6 text-green-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">En attente</p>
            <p className="text-2xl font-semibold mt-2">
              {totalPending.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
            </p>
          </div>
          <div className="p-3 rounded-full bg-yellow-100">
            <Clock className="h-6 w-6 text-yellow-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Factures en retard</p>
            <p className="text-2xl font-semibold mt-2">{overdueInvoices.length}</p>
          </div>
          <div className="p-3 rounded-full bg-red-100">
            <AlertTriangle className="h-6 w-6 text-red-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Moyen de paiement</p>
            <p className="text-lg font-medium mt-2">Virement bancaire</p>
          </div>
          <div className="p-3 rounded-full bg-client-100">
            <CreditCard className="h-6 w-6 text-client-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingStats;