import React, { useState, useEffect } from 'react';
import { FileText, Filter, Download, Eye, Check, X, Search, Users } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import DocumentViewer from '../components/documents/DocumentViewer';
import { documentsService } from '../services/documents';
import { useAuth } from '../contexts/AuthContext';
import type { Document } from '../types/document';

const ReceivedDocuments = () => {
  const { user } = useAuth();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [showViewer, setShowViewer] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      if (!user?.id) return;
      const docs = await documentsService.getReceivedDocuments(user.id);
      setDocuments(docs);
    } catch (error) {
      console.error('Error loading documents:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (document: Document) => {
    try {
      const url = await documentsService.downloadDocument(document.id);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const handleView = (document: Document) => {
    setSelectedDocument(document);
    setShowViewer(true);
  };

  const handleApprove = async (documentId: string) => {
    try {
      await documentsService.updateDocumentStatus(documentId, 'approved');
      loadDocuments();
    } catch (error) {
      console.error('Error approving document:', error);
    }
  };

  const handleReject = async (documentId: string) => {
    try {
      await documentsService.updateDocumentStatus(documentId, 'rejected');
      loadDocuments();
    } catch (error) {
      console.error('Error rejecting document:', error);
    }
  };

  const handleForward = (document: Document) => {
    setSelectedDocument(document);
    setShowUserList(true);
  };

  const filteredDocuments = documents.filter(doc => 
    doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    doc.sender.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Documents reçus</h1>
          <p className="text-sm text-gray-600 mt-1">
            Gérez les documents envoyés par les clients et le personnel soignant
          </p>
        </div>
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredDocuments.map((document) => (
            <div
              key={document.id}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="h-12 w-12 rounded-lg bg-primary-100 flex items-center justify-center">
                  <FileText className="h-6 w-6 text-primary-600" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{document.title}</h3>
                  <p className="text-sm text-gray-500">
                    {document.sender}
                  </p>
                  <p className="text-sm text-gray-500">
                    {format(new Date(document.createdAt), 'dd MMMM yyyy', { locale: fr })}
                  </p>
                </div>
              </div>

              <div className="space-y-2 mb-4">
                <div className="flex items-center space-x-2">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    document.status === 'approved'
                      ? 'bg-green-100 text-green-800'
                      : document.status === 'rejected'
                      ? 'bg-red-100 text-red-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {document.status === 'approved' ? 'Approuvé' : 
                     document.status === 'rejected' ? 'Rejeté' : 
                     'En attente'}
                  </span>
                  <span className="text-xs text-gray-500">
                    {document.type === 'invoice' ? 'Facture' : 'Convention'}
                  </span>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleView(document)}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                    title="Voir"
                  >
                    <Eye className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDownload(document)}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                    title="Télécharger"
                  >
                    <Download className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleForward(document)}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                    title="Transférer"
                  >
                    <Users className="h-5 w-5" />
                  </button>
                </div>

                {document.status === 'pending' && (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleApprove(document.id)}
                      className="p-2 text-green-600 hover:bg-green-100 rounded-lg"
                      title="Approuver"
                    >
                      <Check className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleReject(document.id)}
                      className="p-2 text-red-600 hover:bg-red-100 rounded-lg"
                      title="Rejeter"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedDocument && showViewer && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-4xl w-full h-[80vh]">
              <DocumentViewer
                document={selectedDocument}
                onClose={() => setShowViewer(false)}
                onDownload={() => handleDownload(selectedDocument)}
              />
            </div>
          </div>
        </div>
      )}

      {showUserList && selectedDocument && (
        <UserListModal
          document={selectedDocument}
          onClose={() => setShowUserList(false)}
          onForward={async (userId: string) => {
            try {
              await documentsService.forwardDocument(selectedDocument.id, userId);
              setShowUserList(false);
            } catch (error) {
              console.error('Error forwarding document:', error);
            }
          }}
        />
      )}
    </div>
  );
};

interface UserListModalProps {
  document: Document;
  onClose: () => void;
  onForward: (userId: string) => Promise<void>;
}

const UserListModal: React.FC<UserListModalProps> = ({ document, onClose, onForward }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      // Charger les utilisateurs depuis Firestore
      const usersData = await documentsService.getAvailableRecipients();
      setUsers(usersData);
    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="relative bg-white rounded-lg max-w-lg w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Transférer le document</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-4">
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Rechercher un utilisateur..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
              </div>
            ) : (
              <div className="max-h-96 overflow-y-auto">
                {filteredUsers.map((user) => (
                  <button
                    key={user.id}
                    onClick={() => onForward(user.id)}
                    className="w-full flex items-center p-3 hover:bg-gray-50 rounded-lg"
                  >
                    <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                      {user.avatar ? (
                        <img
                          src={user.avatar}
                          alt={`${user.firstName} ${user.lastName}`}
                          className="h-10 w-10 rounded-full object-cover"
                        />
                      ) : (
                        <User className="h-5 w-5 text-gray-600" />
                      )}
                    </div>
                    <div className="ml-3 text-left">
                      <p className="font-medium">{user.firstName} {user.lastName}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivedDocuments;