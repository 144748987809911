import React, { useState } from 'react';
import { X, Clock, MapPin } from 'lucide-react';
import type { Mission, TimeEntry, Location } from '../../types/mission';
import PresenceConfirmation from './PresenceConfirmation';

interface TimeEntryModalProps {
  isOpen: boolean;
  onClose: () => void;
  mission: Mission;
  type: 'start' | 'end';
  onSubmit: (entry: Partial<TimeEntry>) => Promise<void>;
}

const TimeEntryModal: React.FC<TimeEntryModalProps> = ({
  isOpen,
  onClose,
  mission,
  type,
  onSubmit,
}) => {
  const [notes, setNotes] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleConfirm = async (location: Location) => {
    try {
      await onSubmit({
        missionId: mission.id,
        type,
        timestamp: new Date().toISOString(),
        location,
        notes,
        status: 'pending',
      });
      onClose();
    } catch (err) {
      setError('Une erreur est survenue lors de l\'enregistrement');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-md w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              {type === 'start' ? 'Début de mission' : 'Fin de mission'}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            <div className="space-y-2">
              <div className="flex items-center space-x-2 text-gray-600">
                <Clock className="h-5 w-5" />
                <span>
                  {new Date().toLocaleTimeString('fr-FR', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </div>
              <div className="flex items-center space-x-2 text-gray-600">
                <MapPin className="h-5 w-5" />
                <span>{mission.address.street}, {mission.address.city}</span>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Notes (optionnel)
              </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={3}
                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-staff-500 focus:ring-staff-500"
                placeholder="Ajoutez des notes si nécessaire..."
              />
            </div>

            {error && (
              <p className="text-sm text-red-600">{error}</p>
            )}

            <PresenceConfirmation
              mission={mission}
              onConfirm={handleConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeEntryModal;