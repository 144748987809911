import React from 'react';
import { Search, Calendar } from 'lucide-react';
import type { DocumentFilters } from '../../types/document';

interface DocumentFiltersProps {
  filters: DocumentFilters;
  onFilterChange: (filters: DocumentFilters) => void;
}

const DocumentFilters: React.FC<DocumentFiltersProps> = ({
  filters,
  onFilterChange,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher un document..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.searchQuery || ''}
                onChange={(e) =>
                  onFilterChange({ ...filters, searchQuery: e.target.value })
                }
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.type || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  type: e.target.value as DocumentFilters['type'],
                })
              }
            >
              <option value="">Tous les types</option>
              <option value="contract">Contrats</option>
              <option value="convention">Conventions</option>
              <option value="invoice">Factures</option>
              <option value="report">Rapports</option>
            </select>

            <select
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
              value={filters.status || ''}
              onChange={(e) =>
                onFilterChange({
                  ...filters,
                  status: e.target.value as DocumentFilters['status'],
                })
              }
            >
              <option value="">Tous les statuts</option>
              <option value="draft">Brouillon</option>
              <option value="pending_signature">En attente de signature</option>
              <option value="signed">Signé</option>
              <option value="expired">Expiré</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.dateRange?.start || ''}
                onChange={(e) =>
                  onFilterChange({
                    ...filters,
                    dateRange: {
                      ...filters.dateRange,
                      start: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-gray-400" />
              <input
                type="date"
                className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-indigo-500"
                value={filters.dateRange?.end || ''}
                onChange={(e) =>
                  onFilterChange({
                    ...filters,
                    dateRange: {
                      ...filters.dateRange,
                      end: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentFilters;