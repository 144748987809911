import React, { useState } from 'react';
import { X } from 'lucide-react';
import SignaturePad from './SignaturePad';
import type { Document } from '../../types/document';

interface SignatureModalProps {
  isOpen: boolean;
  onClose: () => void;
  document: Document;
  onSign: (signatureData: string) => void;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  isOpen,
  onClose,
  document,
  onSign,
}) => {
  const [error, setError] = useState('');

  const handleSign = (signatureData: string) => {
    if (!signatureData) {
      setError('Veuillez signer le document');
      return;
    }
    onSign(signatureData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-2xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              Signer le document : {document.title}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6">
            <div className="mb-6">
              <p className="text-sm text-gray-600">
                Veuillez signer dans la zone ci-dessous en utilisant votre souris ou votre écran tactile.
              </p>
            </div>

            <SignaturePad
              onSave={handleSign}
              onClear={() => setError('')}
            />

            {error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;