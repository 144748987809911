import React from 'react';
import { User, MapPin, Phone, Mail, Edit2, Trash2, Eye } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { StaffMember } from '../../types/staff';

interface StaffListProps {
  staff: StaffMember[];
  onEdit: (member: StaffMember) => void;
  onDelete: (id: string) => void;
}

const StaffList: React.FC<StaffListProps> = ({ staff, onEdit, onDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
      {staff.map((member) => (
        <div key={member.id} className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-start">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-primary-100 flex items-center justify-center">
                {member.avatar ? (
                  <img
                    src={member.avatar}
                    alt={`${member.firstName} ${member.lastName}`}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                ) : (
                  <User className="h-6 w-6 text-primary-600" />
                )}
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">
                  {member.firstName} {member.lastName}
                </h3>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800 mt-1">
                  Infirmier indépendant
                </span>
              </div>
            </div>
            <div className="flex space-x-2">
              <Link
                to={`/staff/${member.id}`}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Voir le profil"
              >
                <Eye className="h-4 w-4" />
              </Link>
              <button
                onClick={() => onEdit(member)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Modifier"
              >
                <Edit2 className="h-4 w-4" />
              </button>
              <button
                onClick={() => onDelete(member.id)}
                className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                title="Supprimer"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          </div>

          <div className="mt-4 space-y-2">
            <div className="flex items-center text-sm text-gray-600">
              <MapPin className="h-4 w-4 mr-2" />
              <span>{member.address.city}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Phone className="h-4 w-4 mr-2" />
              <span>{member.phone}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              <Mail className="h-4 w-4 mr-2" />
              <span>{member.email}</span>
            </div>
          </div>

          {member.specializations && member.specializations.length > 0 && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Spécialisations
              </h4>
              <div className="flex flex-wrap gap-2">
                {member.specializations.map((spec, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                  >
                    {spec}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                N° INAMI: {member.inamiNumber}
              </p>
              <p className="text-sm text-gray-600">
                N° SIRET: {member.siret}
              </p>
              <p className="text-sm text-gray-600">
                Taux horaire: {member.ratePerHour}€/h
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaffList;