import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import type { Document as DocumentType } from '../../types/document';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  date: {
    fontSize: 12,
    color: '#666',
    marginBottom: 20,
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 20,
  },
  signaturesSection: {
    marginTop: 40,
  },
  signatureTitle: {
    fontSize: 14,
    marginBottom: 10,
  },
  signatureBox: {
    border: '1px solid #000',
    padding: 10,
    marginBottom: 10,
  },
  signerInfo: {
    fontSize: 10,
    color: '#666',
  },
});

interface DocumentPreviewProps {
  document: DocumentType;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>{document.title}</Text>
          <Text style={styles.date}>
            Date de création : {new Date(document.createdAt).toLocaleDateString()}
          </Text>
        </View>

        {document.description && (
          <View style={styles.content}>
            <Text>{document.description}</Text>
          </View>
        )}

        <View style={styles.content}>
          <Text>{document.content}</Text>
        </View>

        <View style={styles.signaturesSection}>
          <Text style={styles.signatureTitle}>Signatures</Text>
          {document.signers.map((signer) => (
            <View key={signer.id} style={styles.signatureBox}>
              <Text style={styles.signerInfo}>
                {signer.name} ({signer.role === 'staff' ? 'Collaborateur' : 'Client'})
              </Text>
              <Text style={styles.signerInfo}>{signer.email}</Text>
              <Text style={styles.signerInfo}>
                Statut : {
                  signer.status === 'signed' ? 'Signé' :
                  signer.status === 'rejected' ? 'Refusé' :
                  'En attente'
                }
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default DocumentPreview;