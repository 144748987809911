import React, { useState } from 'react';
import { X, Download, PenTool } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import PDFViewer from './PDFViewer';
import type { Document } from '../../types/document';

interface DocumentViewerProps {
  document: Document;
  onClose: () => void;
  onDownload: () => void;
  onSign?: (signatureData: string) => void;
  canSign?: boolean;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({
  document,
  onClose,
  onDownload,
  onSign,
  canSign = false
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveSignature = (signatureData: string) => {
    if (onSign) {
      onSign(signatureData);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between p-4 border-b border-gray-200">
        <div>
          <h2 className="text-lg font-semibold text-gray-900">{document.title}</h2>
          <p className="text-sm text-gray-500">
            Créé le {format(new Date(document.createdAt), 'dd MMMM yyyy', { locale: fr })}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          {canSign && !isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
              title="Signer"
            >
              <PenTool className="h-5 w-5" />
            </button>
          )}
          <button
            onClick={onDownload}
            className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            title="Télécharger"
          >
            <Download className="h-5 w-5" />
          </button>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <PDFViewer
          file={document.url}
          onSave={handleSaveSignature}
          canSign={isEditing}
        />
      </div>
    </div>
  );
};

export default DocumentViewer;