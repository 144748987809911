export const handleFirebaseError = (error: any): string => {
  // Vérifier la connexion Internet
  if (!navigator.onLine) {
    return 'Veuillez vérifier votre connexion Internet';
  }

  // Messages d'erreur spécifiques
  const errorMessages: Record<string, string> = {
    'auth/email-already-in-use': 'Cette adresse email est déjà utilisée',
    'auth/invalid-credential': 'Email ou mot de passe incorrect',
    'auth/user-not-found': 'Aucun compte ne correspond à cet email',
    'auth/wrong-password': 'Mot de passe incorrect',
    'auth/invalid-email': 'Format d\'email invalide',
    'auth/weak-password': 'Le mot de passe doit contenir au moins 6 caractères',
    'auth/too-many-requests': 'Trop de tentatives, veuillez réessayer plus tard',
    'auth/network-request-failed': 'Erreur de connexion, vérifiez votre connexion internet',
    'auth/operation-not-allowed': 'Cette opération n\'est pas autorisée',
    'auth/user-disabled': 'Ce compte a été désactivé',
    'auth/requires-recent-login': 'Veuillez vous reconnecter pour continuer',
    'permission-denied': 'Vous n\'avez pas les permissions nécessaires',
    'not-found': 'Ressource non trouvée',
    'already-exists': 'Cette ressource existe déjà'
  };

  const errorCode = error.code || '';
  return errorMessages[errorCode] || error.message || 'Une erreur inattendue est survenue';
};