import React from 'react';
import { AlertTriangle, CheckCircle, FileText, Award, CreditCard, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ProfileRequirement {
  id: string;
  label: string;
  isComplete: boolean;
  icon: React.ElementType;
}

interface ProfileCompletionCardProps {
  profile: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: {
      street?: string;
      city?: string;
      postalCode?: string;
    };
    inamiNumber?: string;
    tvaNumber?: string;
    bankDetails?: {
      iban?: string;
      bic?: string;
      bankName?: string;
    };
    documents?: {
      id: string;
      type: string;
      verified: boolean;
    }[];
  };
}

const ProfileCompletionCard: React.FC<ProfileCompletionCardProps> = ({ profile }) => {
  const requirements: ProfileRequirement[] = [
    {
      id: 'personal',
      label: 'Informations personnelles',
      isComplete: !!(profile.firstName && profile.lastName && profile.email && profile.phone),
      icon: MapPin
    },
    {
      id: 'professional',
      label: 'Informations professionnelles',
      isComplete: !!(profile.inamiNumber && profile.tvaNumber),
      icon: Award
    },
    {
      id: 'bank',
      label: 'Coordonnées bancaires',
      isComplete: !!(profile.bankDetails?.iban && profile.bankDetails?.bic && profile.bankDetails?.bankName),
      icon: CreditCard
    },
    {
      id: 'documents',
      label: 'Documents requis',
      isComplete: profile.documents?.every(doc => doc.verified) ?? false,
      icon: FileText
    }
  ];

  const completedRequirements = requirements.filter(req => req.isComplete).length;
  const completionPercentage = (completedRequirements / requirements.length) * 100;

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium">Complétion du profil</h3>
        <span className={`text-2xl font-bold ${
          completionPercentage === 100 ? 'text-green-600' :
          completionPercentage >= 50 ? 'text-yellow-600' :
          'text-red-600'
        }`}>
          {Math.round(completionPercentage)}%
        </span>
      </div>

      <div className="relative h-2 bg-gray-200 rounded-full mb-6">
        <div
          className={`absolute left-0 top-0 h-full rounded-full transition-all duration-500 ${
            completionPercentage === 100 ? 'bg-green-500' :
            completionPercentage >= 50 ? 'bg-yellow-500' :
            'bg-red-500'
          }`}
          style={{ width: `${completionPercentage}%` }}
        />
      </div>

      <div className="space-y-4">
        {requirements.map((requirement) => (
          <div
            key={requirement.id}
            className={`flex items-center justify-between p-3 rounded-lg ${
              requirement.isComplete ? 'bg-green-50' : 'bg-red-50'
            }`}
          >
            <div className="flex items-center space-x-3">
              <requirement.icon className={`h-5 w-5 ${
                requirement.isComplete ? 'text-green-600' : 'text-red-600'
              }`} />
              <span className="font-medium">{requirement.label}</span>
            </div>
            {requirement.isComplete ? (
              <CheckCircle className="h-5 w-5 text-green-600" />
            ) : (
              <AlertTriangle className="h-5 w-5 text-red-600" />
            )}
          </div>
        ))}
      </div>

      {completionPercentage < 100 && (
        <div className="mt-6">
          <Link
            to="/staff/profile"
            className="btn bg-staff-600 text-white hover:bg-staff-700 w-full flex justify-center"
          >
            Compléter mon profil
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProfileCompletionCard;