import React, { useState } from 'react';
import { Search, Send } from 'lucide-react';
import MessageList from '../components/messages/MessageList';
import MessageThread from '../components/messages/MessageThread';
import { useAuth } from '../contexts/AuthContext';
import type { Message, Thread } from '../types/message';

const Messages = () => {
  const { user } = useAuth();
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Données mockées pour l'exemple
  const mockThreads: Thread[] = [
    {
      id: '1',
      participants: [
        {
          id: '2',
          name: 'Marie Dubois',
          avatar: null,
          role: 'staff'
        }
      ],
      lastMessage: {
        id: '1',
        content: 'Bonjour, j\'ai une question concernant ma prochaine mission',
        senderId: '2',
        timestamp: new Date().toISOString(),
        read: false
      },
      unreadCount: 1
    },
    {
      id: '2',
      participants: [
        {
          id: '3',
          name: 'Centre Médical Saint-Jean',
          avatar: null,
          role: 'client'
        }
      ],
      lastMessage: {
        id: '2',
        content: 'Merci pour les informations',
        senderId: '3',
        timestamp: new Date(Date.now() - 3600000).toISOString(),
        read: true
      },
      unreadCount: 0
    }
  ];

  const mockMessages: Message[] = [
    {
      id: '1',
      threadId: '1',
      content: 'Bonjour, j\'ai une question concernant ma prochaine mission',
      senderId: '2',
      timestamp: new Date().toISOString(),
      read: false
    },
    {
      id: '2',
      threadId: '1',
      content: 'Je vous écoute, en quoi puis-je vous aider ?',
      senderId: '1',
      timestamp: new Date(Date.now() - 1800000).toISOString(),
      read: true
    }
  ];

  const handleSendMessage = (content: string) => {
    if (!selectedThread) return;
    console.log('Send message:', { threadId: selectedThread.id, content });
  };

  const filteredThreads = mockThreads.filter(thread => {
    // Filtrer par la recherche
    if (searchQuery) {
      return thread.participants.some(p =>
        p.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return true;
  });

  return (
    <div className="h-[calc(100vh-4rem)] flex">
      {/* Liste des conversations */}
      <div className="w-1/3 border-r border-gray-200 bg-white">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher une conversation..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
        <MessageList
          threads={filteredThreads}
          selectedThreadId={selectedThread?.id}
          onSelectThread={setSelectedThread}
        />
      </div>

      {/* Conversation active */}
      {selectedThread ? (
        <div className="flex-1 flex flex-col bg-gray-50">
          <MessageThread
            thread={selectedThread}
            messages={mockMessages}
            onSendMessage={handleSendMessage}
          />
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center bg-gray-50">
          <div className="text-center">
            <Send className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">
              {user?.role === 'admin' 
                ? 'Sélectionnez une conversation pour commencer'
                : 'Sélectionnez un administrateur pour commencer une conversation'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Messages;