import React, { useState } from 'react';
import { Calendar, Clock, Plus } from 'lucide-react';
import { format, startOfWeek, addDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { WeekDay } from '../../../types/availability';

interface AvailabilityManagerProps {
  availabilities: {
    [key: string]: {
      morning: boolean;
      afternoon: boolean;
      night: boolean;
    };
  };
  onUpdate: (date: string, period: 'morning' | 'afternoon' | 'night', available: boolean) => void;
}

const AvailabilityManager: React.FC<AvailabilityManagerProps> = ({
  availabilities,
  onUpdate,
}) => {
  const [selectedWeek, setSelectedWeek] = useState(startOfWeek(new Date(), { locale: fr }));
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedPeriods, setSelectedPeriods] = useState<{
    [key: string]: boolean;
  }>({
    morning: false,
    afternoon: false,
    night: false,
  });
  const [selectedDays, setSelectedDays] = useState<{
    [key: string]: boolean;
  }>({});
  const [recurrence, setRecurrence] = useState('single');

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(selectedWeek, i));

  const periods = [
    { id: 'morning', label: 'Matin', hours: '8h - 12h' },
    { id: 'afternoon', label: 'Après-midi', hours: '14h - 18h' },
    { id: 'night', label: 'Nuit', hours: '20h - 8h' },
  ];

  const handlePeriodSelect = (periodId: string) => {
    setSelectedPeriods(prev => ({
      ...prev,
      [periodId]: !prev[periodId]
    }));
  };

  const handleDaySelect = (date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    setSelectedDays(prev => ({
      ...prev,
      [dateStr]: !prev[dateStr]
    }));
  };

  const handleAddAvailabilities = () => {
    // Récupérer les périodes sélectionnées
    const periodsToAdd = Object.entries(selectedPeriods)
      .filter(([_, isSelected]) => isSelected)
      .map(([period]) => period as 'morning' | 'afternoon' | 'night');

    // Récupérer les jours sélectionnés
    const daysToAdd = Object.entries(selectedDays)
      .filter(([_, isSelected]) => isSelected)
      .map(([date]) => date);

    // Pour chaque combinaison jour/période, mettre à jour les disponibilités
    daysToAdd.forEach(date => {
      periodsToAdd.forEach(period => {
        onUpdate(date, period, true);
      });
    });

    // Si récurrence hebdomadaire, ajouter pour les 4 prochaines semaines
    if (recurrence === 'weekly') {
      for (let week = 1; week <= 4; week++) {
        daysToAdd.forEach(date => {
          const currentDate = new Date(date);
          const futureDate = format(
            new Date(currentDate.setDate(currentDate.getDate() + (week * 7))),
            'yyyy-MM-dd'
          );
          periodsToAdd.forEach(period => {
            onUpdate(futureDate, period, true);
          });
        });
      }
    }

    // Réinitialiser et fermer le modal
    setSelectedPeriods({ morning: false, afternoon: false, night: false });
    setSelectedDays({});
    setRecurrence('single');
    setShowAddModal(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-medium">Mes disponibilités</h2>
        <button
          onClick={() => setShowAddModal(true)}
          className="btn bg-staff-600 text-white hover:bg-staff-700 flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter des disponibilités</span>
        </button>
      </div>

      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-1"></div>
        {weekDays.map((day) => (
          <div key={day.toISOString()} className="text-center">
            <div className="font-medium">{format(day, 'EEEE', { locale: fr })}</div>
            <div className="text-sm text-gray-500">{format(day, 'd MMM', { locale: fr })}</div>
          </div>
        ))}

        {periods.map((period) => (
          <React.Fragment key={period.id}>
            <div className="flex items-center">
              <div>
                <div className="font-medium">{period.label}</div>
                <div className="text-sm text-gray-500">{period.hours}</div>
              </div>
            </div>
            {weekDays.map((day) => {
              const dateStr = format(day, 'yyyy-MM-dd');
              const isAvailable = availabilities[dateStr]?.[period.id as keyof typeof availabilities[string]];

              return (
                <button
                  key={`${dateStr}-${period.id}`}
                  onClick={() => onUpdate(dateStr, period.id as any, !isAvailable)}
                  className={`p-4 rounded-lg border-2 transition-colors ${
                    isAvailable
                      ? 'bg-staff-100 border-staff-500 text-staff-700'
                      : 'bg-gray-50 border-gray-200 text-gray-400 hover:bg-gray-100'
                  }`}
                >
                  <Clock className="h-5 w-5 mx-auto" />
                </button>
              );
            })}
          </React.Fragment>
        ))}
      </div>

      {/* Modal d'ajout de disponibilités */}
      {showAddModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="relative bg-white rounded-lg max-w-lg w-full p-6">
              <h3 className="text-lg font-medium mb-4">Ajouter des disponibilités</h3>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Période</label>
                  <div className="mt-2 grid grid-cols-3 gap-4">
                    {periods.map((period) => (
                      <button
                        key={period.id}
                        onClick={() => handlePeriodSelect(period.id)}
                        className={`p-4 rounded-lg border-2 transition-colors ${
                          selectedPeriods[period.id]
                            ? 'border-staff-500 bg-staff-50'
                            : 'border-gray-200 hover:border-staff-500'
                        }`}
                      >
                        <div className="font-medium">{period.label}</div>
                        <div className="text-sm text-gray-500">{period.hours}</div>
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Jours</label>
                  <div className="mt-2 grid grid-cols-7 gap-2">
                    {weekDays.map((day) => {
                      const dateStr = format(day, 'yyyy-MM-dd');
                      return (
                        <button
                          key={day.toISOString()}
                          onClick={() => handleDaySelect(day)}
                          className={`p-2 rounded-lg border-2 transition-colors ${
                            selectedDays[dateStr]
                              ? 'border-staff-500 bg-staff-50'
                              : 'border-gray-200 hover:border-staff-500'
                          }`}
                        >
                          <div className="text-sm font-medium">
                            {format(day, 'EEE', { locale: fr })}
                          </div>
                          <div className="text-xs text-gray-500">
                            {format(day, 'd', { locale: fr })}
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Récurrence</label>
                  <select
                    value={recurrence}
                    onChange={(e) => setRecurrence(e.target.value)}
                    className="mt-1 block w-full rounded-lg border-gray-300"
                  >
                    <option value="single">Cette semaine uniquement</option>
                    <option value="weekly">Toutes les semaines</option>
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setShowAddModal(false)}
                  className="btn btn-secondary"
                >
                  Annuler
                </button>
                <button
                  onClick={handleAddAvailabilities}
                  disabled={
                    !Object.values(selectedPeriods).some(Boolean) ||
                    !Object.values(selectedDays).some(Boolean)
                  }
                  className="btn bg-staff-600 text-white hover:bg-staff-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailabilityManager;