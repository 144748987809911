import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

interface ClientRegistrationFormProps {
  onSuccess: () => void;
}

const ClientRegistrationForm: React.FC<ClientRegistrationFormProps> = ({ onSuccess }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    type: 'organization',
    name: '',
    contactPerson: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: 'France'
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Logique d'inscription à implémenter
      console.log('Form data:', formData);
      onSuccess();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Type de compte</label>
        <select
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
          value={formData.type}
          onChange={(e) => setFormData({ ...formData, type: e.target.value })}
        >
          <option value="organization">Organisation</option>
          <option value="individual">Particulier</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          {formData.type === 'organization' ? 'Nom de l\'organisation' : 'Nom complet'}
        </label>
        <input
          type="text"
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </div>

      {formData.type === 'organization' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Personne de contact</label>
          <input
            type="text"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
            value={formData.contactPerson}
            onChange={(e) => setFormData({ ...formData, contactPerson: e.target.value })}
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Téléphone</label>
        <input
          type="tel"
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-2">Adresse</h3>
        <div className="grid grid-cols-1 gap-4">
          <input
            type="text"
            placeholder="Rue"
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
            value={formData.address.street}
            onChange={(e) => setFormData({
              ...formData,
              address: { ...formData.address, street: e.target.value }
            })}
          />
          <div className="grid grid-cols-2 gap-4">
            <input
              type="text"
              placeholder="Ville"
              required
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
              value={formData.address.city}
              onChange={(e) => setFormData({
                ...formData,
                address: { ...formData.address, city: e.target.value }
              })}
            />
            <input
              type="text"
              placeholder="Code postal"
              required
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
              value={formData.address.postalCode}
              onChange={(e) => setFormData({
                ...formData,
                address: { ...formData.address, postalCode: e.target.value }
              })}
            />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
        <div className="mt-1 relative">
          <input
            type={showPassword ? 'text' : 'password'}
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5 text-gray-400" />
            ) : (
              <Eye className="h-5 w-5 text-gray-400" />
            )}
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Confirmer le mot de passe</label>
        <div className="mt-1 relative">
          <input
            type={showPassword ? 'text' : 'password'}
            required
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-client-500 focus:ring-client-500"
            value={formData.confirmPassword}
            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-client-600 hover:bg-client-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-client-500"
        >
          S'inscrire
        </button>
      </div>
    </form>
  );
};

export default ClientRegistrationForm;