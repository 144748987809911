import { supabase } from '../lib/supabase/client';
import type { AuthUser, LoginCredentials } from '../types/auth';

export const authService = {
  // Login
  login: async (credentials: LoginCredentials): Promise<AuthUser> => {
    try {
      const { data: { user }, error } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password
      });

      if (error) throw error;
      if (!user) throw new Error('No user returned from Supabase');

      // Get the user's profile data
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;
      if (!profile) throw new Error('No profile found');

      // Verify role if specified
      if (credentials.role && profile.role !== credentials.role) {
        throw new Error(`Ce compte n'est pas un compte ${credentials.role}`);
      }

      return {
        id: user.id,
        email: user.email!,
        firstName: profile.first_name,
        lastName: profile.last_name,
        role: profile.role,
        status: profile.status,
        organization: profile.organization,
        createdAt: profile.created_at
      };
    } catch (error: any) {
      console.error('Login error:', error);
      throw new Error(error.message || 'Erreur lors de la connexion');
    }
  },

  // Register
  register: async (userData: Partial<AuthUser> & { password: string }): Promise<AuthUser> => {
    try {
      const { data: { user }, error } = await supabase.auth.signUp({
        email: userData.email!,
        password: userData.password,
        options: {
          data: {
            first_name: userData.firstName,
            last_name: userData.lastName,
            role: userData.role
          }
        }
      });

      if (error) throw error;
      if (!user) throw new Error('No user returned from Supabase');

      // Create profile
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: user.id,
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          role: userData.role,
          status: 'active',
          organization: userData.organization
        }])
        .select()
        .single();

      if (profileError) throw profileError;
      if (!profile) throw new Error('Failed to create profile');

      return {
        id: user.id,
        email: user.email!,
        firstName: profile.first_name,
        lastName: profile.last_name,
        role: profile.role,
        status: profile.status,
        organization: profile.organization,
        createdAt: profile.created_at
      };
    } catch (error: any) {
      console.error('Registration error:', error);
      throw new Error(error.message || 'Erreur lors de l\'inscription');
    }
  },

  // Logout
  logout: async (): Promise<void> => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error: any) {
      console.error('Logout error:', error);
      throw new Error(error.message || 'Erreur lors de la déconnexion');
    }
  },

  // Get current user
  getCurrentUser: async (): Promise<AuthUser | null> => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;
      if (!profile) return null;

      return {
        id: user.id,
        email: user.email!,
        firstName: profile.first_name,
        lastName: profile.last_name,
        role: profile.role,
        status: profile.status,
        organization: profile.organization,
        createdAt: profile.created_at
      };
    } catch (error: any) {
      console.error('Get current user error:', error);
      return null;
    }
  },

  // Update user profile
  updateProfile: async (userId: string, updates: Partial<AuthUser>): Promise<void> => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({
          first_name: updates.firstName,
          last_name: updates.lastName,
          organization: updates.organization,
          status: updates.status,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) throw error;
    } catch (error: any) {
      console.error('Update profile error:', error);
      throw new Error(error.message || 'Erreur lors de la mise à jour du profil');
    }
  }
};