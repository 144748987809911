import React, { useState } from 'react';
import { format } from 'date-fns';
import type { Invoice, InvoiceItem } from '../../types/billing';

interface InvoiceFormProps {
  invoice?: Invoice;
  onSubmit: (data: Partial<Invoice>) => void;
  onCancel: () => void;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  invoice,
  onSubmit,
  onCancel,
}) => {
  const [formData, setFormData] = useState<Partial<Invoice>>(
    invoice || {
      number: `INV-${new Date().getFullYear()}-${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
      clientId: '',
      clientName: '',
      status: 'draft',
      issueDate: format(new Date(), 'yyyy-MM-dd'),
      dueDate: format(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd'),
      items: [],
      subtotal: 0,
      tax: 0,
      total: 0,
      notes: '',
    }
  );

  const [newItem, setNewItem] = useState<Partial<InvoiceItem>>({
    description: '',
    quantity: 1,
    rate: 0,
    date: format(new Date(), 'yyyy-MM-dd'),
  });

  const handleAddItem = () => {
    if (!newItem.description || !newItem.quantity || !newItem.rate) return;

    const amount = newItem.quantity * newItem.rate;
    const item: InvoiceItem = {
      id: Math.random().toString(36).substr(2, 9),
      serviceId: Math.random().toString(36).substr(2, 9),
      ...newItem as Omit<InvoiceItem, 'id' | 'serviceId'>,
      amount,
    };

    const items = [...(formData.items || []), item];
    const subtotal = items.reduce((sum, item) => sum + item.amount, 0);
    const tax = subtotal * 0.2; // 20% TVA
    const total = subtotal + tax;

    setFormData(prev => ({
      ...prev,
      items,
      subtotal,
      tax,
      total,
    }));

    setNewItem({
      description: '',
      quantity: 1,
      rate: 0,
      date: format(new Date(), 'yyyy-MM-dd'),
    });
  };

  const handleRemoveItem = (id: string) => {
    const items = formData.items?.filter(item => item.id !== id) || [];
    const subtotal = items.reduce((sum, item) => sum + item.amount, 0);
    const tax = subtotal * 0.2;
    const total = subtotal + tax;

    setFormData(prev => ({
      ...prev,
      items,
      subtotal,
      tax,
      total,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Numéro de facture
          </label>
          <input
            type="text"
            value={formData.number}
            readOnly
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Client
          </label>
          <input
            type="text"
            value={formData.clientName}
            onChange={(e) => setFormData(prev => ({ ...prev, clientName: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date d'émission
          </label>
          <input
            type="date"
            value={formData.issueDate}
            onChange={(e) => setFormData(prev => ({ ...prev, issueDate: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date d'échéance
          </label>
          <input
            type="date"
            value={formData.dueDate}
            onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            required
          />
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-700 mb-4">Prestations</h3>
        
        <div className="space-y-4">
          {formData.items?.map((item) => (
            <div key={item.id} className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex-1 grid grid-cols-5 gap-4">
                <div className="col-span-2">
                  <p className="font-medium">{item.description}</p>
                  <p className="text-sm text-gray-500">{format(new Date(item.date), 'dd/MM/yyyy')}</p>
                </div>
                <div className="text-right">
                  <p className="font-medium">{item.quantity}</p>
                  <p className="text-sm text-gray-500">Quantité</p>
                </div>
                <div className="text-right">
                  <p className="font-medium">{item.rate.toFixed(2)} €</p>
                  <p className="text-sm text-gray-500">Prix unitaire</p>
                </div>
                <div className="text-right">
                  <p className="font-medium">{item.amount.toFixed(2)} €</p>
                  <p className="text-sm text-gray-500">Total</p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleRemoveItem(item.id)}
                className="text-red-600 hover:text-red-700"
              >
                Supprimer
              </button>
            </div>
          ))}

          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-2">
              <input
                type="text"
                placeholder="Description"
                value={newItem.description}
                onChange={(e) => setNewItem(prev => ({ ...prev, description: e.target.value }))}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <input
                type="number"
                min="1"
                placeholder="Quantité"
                value={newItem.quantity}
                onChange={(e) => setNewItem(prev => ({ ...prev, quantity: parseInt(e.target.value) }))}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <input
                type="number"
                min="0"
                step="0.01"
                placeholder="Prix unitaire"
                value={newItem.rate}
                onChange={(e) => setNewItem(prev => ({ ...prev, rate: parseFloat(e.target.value) }))}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <button
                type="button"
                onClick={handleAddItem}
                className="w-full btn btn-secondary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 pt-4">
        <div className="flex justify-end">
          <div className="w-64 space-y-2">
            <div className="flex justify-between">
              <span className="text-sm text-gray-500">Sous-total</span>
              <span className="font-medium">{formData.subtotal?.toFixed(2)} €</span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-gray-500">TVA (20%)</span>
              <span className="font-medium">{formData.tax?.toFixed(2)} €</span>
            </div>
            <div className="flex justify-between text-lg font-bold">
              <span>Total</span>
              <span>{formData.total?.toFixed(2)} €</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Notes
        </label>
        <textarea
          value={formData.notes}
          onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
        />
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-secondary"
        >
          Annuler
        </button>
        <button
          type="submit"
          className="btn btn-primary"
        >
          {invoice ? 'Modifier' : 'Créer'}
        </button>
      </div>
    </form>
  );
};

export default InvoiceForm;