import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import StaffList from '../components/staff/StaffList';
import StaffFilters from '../components/staff/StaffFilters';
import StaffModal from '../components/staff/StaffModal';
import type { StaffMember, StaffFilters as Filters } from '../types/staff';

const mockStaff: StaffMember[] = [
  {
    id: '1',
    firstName: 'Marie',
    lastName: 'Dubois',
    email: 'marie.dubois@example.com',
    phone: '+33 6 12 34 56 78',
    role: 'nurse',
    inamiNumber: '12345678901',
    tvaNumber: 'BE0123456789',
    address: {
      street: '123 Rue de la Santé',
      city: 'Paris',
      postalCode: '75001',
      country: 'France',
    },
    salary: 45000,
    startDate: '2022-01-15',
    status: 'active',
    specializations: ['Soins intensifs', 'Gériatrie'],
  },
  {
    id: '2',
    firstName: 'Jean',
    lastName: 'Martin',
    email: 'jean.martin@example.com',
    phone: '+33 6 98 76 54 32',
    role: 'caregiver',
    address: {
      street: '456 Avenue du Soin',
      city: 'Lyon',
      postalCode: '69001',
      country: 'France',
    },
    salary: 35000,
    startDate: '2021-06-01',
    status: 'active',
    specializations: ['Aide à domicile', 'Handicap'],
  },
];

const Staff = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [staff, setStaff] = useState<StaffMember[]>(mockStaff);
  const [selectedStaff, setSelectedStaff] = useState<StaffMember | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateStaff = () => {
    setSelectedStaff(undefined);
    setIsModalOpen(true);
  };

  const handleEditStaff = (member: StaffMember) => {
    setSelectedStaff(member);
    setIsModalOpen(true);
  };

  const handleDeleteStaff = (id: string) => {
    setStaff(staff.filter(member => member.id !== id));
  };

  const handleSubmitStaff = (staffData: Partial<StaffMember>) => {
    if (selectedStaff) {
      // Modification
      setStaff(staff.map(member =>
        member.id === selectedStaff.id
          ? { ...member, ...staffData }
          : member
      ));
    } else {
      // Création
      const newStaff: StaffMember = {
        id: Math.random().toString(36).substr(2, 9),
        ...staffData,
      } as StaffMember;
      setStaff([...staff, newStaff]);
    }
  };

  const filteredStaff = staff.filter((member) => {
    if (filters.role && member.role !== filters.role) return false;
    if (filters.status && member.status !== filters.status) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        member.firstName.toLowerCase().includes(query) ||
        member.lastName.toLowerCase().includes(query) ||
        member.email.toLowerCase().includes(query)
      );
    }
    return true;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Gestion des collaborateurs</h1>
        <button
          onClick={handleCreateStaff}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter un collaborateur</span>
        </button>
      </div>

      <StaffFilters filters={filters} onFilterChange={setFilters} />
      <StaffList
        staff={filteredStaff}
        onEdit={handleEditStaff}
        onDelete={handleDeleteStaff}
      />

      <StaffModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        staff={selectedStaff}
        onSubmit={handleSubmitStaff}
      />
    </div>
  );
};

export default Staff;