import React from 'react';
import { FileText, Download } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const DocumentsList = () => {
  const documents = [
    {
      id: '1',
      title: 'Fiche de présence - Mars 2024',
      type: 'presence',
      date: '2024-03-15',
      status: 'pending'
    },
    {
      id: '2',
      title: 'Facture - Février 2024',
      type: 'invoice',
      date: '2024-02-28',
      status: 'signed'
    },
    {
      id: '3',
      title: 'Convention de mission',
      type: 'contract',
      date: '2024-03-01',
      status: 'signed'
    }
  ];

  return (
    <div className="space-y-4">
      {documents.map((doc) => (
        <div
          key={doc.id}
          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center space-x-3">
            <div className="h-10 w-10 rounded-lg bg-staff-100 flex items-center justify-center">
              <FileText className="h-5 w-5 text-staff-600" />
            </div>
            <div>
              <p className="font-medium">{doc.title}</p>
              <p className="text-sm text-gray-500">
                {format(new Date(doc.date), 'dd MMMM yyyy', { locale: fr })}
              </p>
            </div>
          </div>
          <button className="p-2 text-staff-600 hover:bg-staff-50 rounded-lg">
            <Download className="h-5 w-5" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;