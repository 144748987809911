import React from 'react';
import { Calendar, Download, CreditCard, CheckCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Payment {
  id: string;
  period: string;
  amount: number;
  status: 'paid' | 'pending';
  paidAt?: string;
  details: {
    totalHours: number;
    regularHours: number;
    overtimeHours: number;
    regularRate: number;
    overtimeRate: number;
    missions: number;
  };
}

interface PaymentsListProps {
  payments: Payment[];
  onDownload: (id: string) => void;
}

const PaymentsList: React.FC<PaymentsListProps> = ({ payments, onDownload }) => {
  return (
    <div className="space-y-6">
      {payments.map((payment) => (
        <div
          key={payment.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-lg bg-staff-100 flex items-center justify-center">
                <CreditCard className="h-6 w-6 text-staff-600" />
              </div>
              <div>
                <h3 className="font-medium text-lg">{payment.period}</h3>
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <Calendar className="h-4 w-4" />
                  <span>
                    Payé le {format(new Date(payment.paidAt!), 'dd MMMM yyyy', { locale: fr })}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-right">
              <p className="text-2xl font-semibold">
                {payment.amount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
              </p>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                <CheckCircle className="h-3 w-3 mr-1" />
                Payé
              </span>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Heures totales</p>
              <p className="text-lg font-medium">{payment.details.totalHours}h</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Missions réalisées</p>
              <p className="text-lg font-medium">{payment.details.missions}</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500">Taux horaire moyen</p>
              <p className="text-lg font-medium">
                {(payment.amount / payment.details.totalHours).toFixed(2)}€/h
              </p>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-4 flex justify-between items-center">
            <div className="text-sm text-gray-500">
              <p>Heures normales: {payment.details.regularHours}h × {payment.details.regularRate}€</p>
              <p>Heures supplémentaires: {payment.details.overtimeHours}h × {payment.details.overtimeRate}€</p>
            </div>
            <button
              onClick={() => onDownload(payment.id)}
              className="btn btn-secondary flex items-center space-x-2"
            >
              <Download className="h-5 w-5" />
              <span>Télécharger la facture</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaymentsList;