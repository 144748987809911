import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import DocumentList from '../components/documents/DocumentList';
import DocumentFilters from '../components/documents/DocumentFilters';
import DocumentCreator from '../components/documents/DocumentCreator';
import DocumentViewer from '../components/documents/DocumentViewer';
import SignatureModal from '../components/documents/SignatureModal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentPreview from '../components/documents/DocumentPreview';
import type { Document, DocumentFilters as Filters } from '../types/document';

const mockDocuments: Document[] = [
  {
    id: '1',
    type: 'contract',
    title: 'Contrat de prestation de soins',
    description: 'Contrat standard pour les services de soins à domicile',
    status: 'pending_signature',
    createdAt: '2024-03-19T10:00:00',
    expiresAt: '2024-04-19T10:00:00',
    signers: [
      {
        id: '1',
        name: 'Marie Dubois',
        email: 'marie.dubois@example.com',
        role: 'staff',
        status: 'signed',
        signedAt: '2024-03-19T11:00:00'
      },
      {
        id: '2',
        name: 'Centre Médical Saint-Jean',
        email: 'contact@cm-saintjean.fr',
        role: 'client',
        status: 'pending'
      }
    ],
    content: 'Contenu du contrat...',
    metadata: {
      clientId: '1',
      staffId: '1'
    }
  },
  {
    id: '2',
    type: 'invoice',
    title: 'Facture - Mars 2024',
    status: 'signed',
    createdAt: '2024-03-18T14:00:00',
    signedAt: '2024-03-18T15:30:00',
    signers: [
      {
        id: '3',
        name: 'Jean Martin',
        email: 'jean.martin@example.com',
        role: 'client',
        status: 'signed',
        signedAt: '2024-03-18T15:30:00'
      }
    ],
    content: 'Contenu de la facture...',
    metadata: {
      clientId: '2'
    }
  }
];

const Documents = () => {
  const [filters, setFilters] = useState<Filters>({});
  const [documents, setDocuments] = useState<Document[]>(mockDocuments);
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleCreateDocument = (document: Partial<Document>) => {
    const newDocument: Document = {
      id: Math.random().toString(36).substr(2, 9),
      ...document as Document,
    };
    setDocuments([...documents, newDocument]);
    setIsCreatorOpen(false);
  };

  const handlePreview = (document: Document) => {
    setSelectedDocument(document);
    setIsViewerOpen(true);
  };

  const handleSign = (document: Document) => {
    setSelectedDocument(document);
    setIsSignatureModalOpen(true);
  };

  const handleSignatureSubmit = (signatureData: string) => {
    if (!selectedDocument) return;

    const updatedDocument = {
      ...selectedDocument,
      signers: selectedDocument.signers.map(signer => 
        signer.status === 'pending'
          ? { ...signer, status: 'signed', signedAt: new Date().toISOString() }
          : signer
      ),
    };

    setDocuments(documents.map(doc =>
      doc.id === selectedDocument.id ? updatedDocument : doc
    ));

    setIsSignatureModalOpen(false);
    setSelectedDocument(null);
  };

  const handleDownload = (document: Document) => {
    // La logique de téléchargement est gérée par PDFDownloadLink
  };

  const filteredDocuments = documents.filter((document) => {
    if (filters.type && document.type !== filters.type) return false;
    if (filters.status && document.status !== filters.status) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        document.title.toLowerCase().includes(query) ||
        document.description?.toLowerCase().includes(query)
      );
    }
    if (filters.dateRange) {
      const documentDate = new Date(document.createdAt);
      const startDate = new Date(filters.dateRange.start);
      const endDate = new Date(filters.dateRange.end);
      return documentDate >= startDate && documentDate <= endDate;
    }
    return true;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Documents et signatures</h1>
        <button
          onClick={() => setIsCreatorOpen(true)}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Nouveau document</span>
        </button>
      </div>

      <DocumentFilters filters={filters} onFilterChange={setFilters} />
      <DocumentList
        documents={filteredDocuments}
        onPreview={handlePreview}
        onSign={handleSign}
        onDownload={handleDownload}
      />

      <DocumentCreator
        isOpen={isCreatorOpen}
        onClose={() => setIsCreatorOpen(false)}
        onSubmit={handleCreateDocument}
      />

      {selectedDocument && (
        <>
          <SignatureModal
            isOpen={isSignatureModalOpen}
            onClose={() => setIsSignatureModalOpen(false)}
            document={selectedDocument}
            onSign={handleSignatureSubmit}
          />

          {isViewerOpen && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="relative bg-white rounded-lg max-w-4xl w-full">
                  <DocumentViewer
                    document={selectedDocument}
                    onSign={() => setIsSignatureModalOpen(true)}
                    onDownload={() => {
                      // Le téléchargement est géré par PDFDownloadLink
                    }}
                  />
                  <div className="absolute top-4 right-4">
                    <PDFDownloadLink
                      document={<DocumentPreview document={selectedDocument} />}
                      fileName={`${selectedDocument.title}.pdf`}
                      className="btn btn-primary"
                    >
                      {({ loading }) =>
                        loading ? 'Préparation...' : 'Télécharger le PDF'
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Documents;