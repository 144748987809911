import React, { useState } from 'react';
import { Search, Send } from 'lucide-react';
import MessageList from '../../components/client/messages/MessageList';
import MessageThread from '../../components/client/messages/MessageThread';
import type { Message, Thread } from '../../types/message';

const ClientMessages = () => {
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Données mockées pour l'exemple
  const mockThreads: Thread[] = [
    {
      id: '1',
      participants: [
        {
          id: '1',
          name: 'Support Hope Care',
          avatar: null,
          role: 'admin'
        }
      ],
      lastMessage: {
        id: '1',
        content: 'Bonjour, comment pouvons-nous vous aider ?',
        senderId: '1',
        timestamp: new Date().toISOString(),
        read: false
      },
      unreadCount: 1
    }
  ];

  const mockMessages: Message[] = [
    {
      id: '1',
      threadId: '1',
      content: 'Bonjour, comment pouvons-nous vous aider ?',
      senderId: '1',
      timestamp: new Date().toISOString(),
      read: false
    }
  ];

  const handleSendMessage = (content: string) => {
    if (!selectedThread) return;
    console.log('Send message:', { threadId: selectedThread.id, content });
  };

  const filteredThreads = mockThreads.filter(thread =>
    thread.participants.some(p =>
      p.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="h-[calc(100vh-4rem)] flex">
      {/* Liste des conversations */}
      <div className="w-1/3 border-r border-gray-200 bg-white">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher une conversation..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
        <MessageList
          threads={filteredThreads}
          selectedThreadId={selectedThread?.id}
          onSelectThread={setSelectedThread}
        />
      </div>

      {/* Conversation active */}
      {selectedThread ? (
        <div className="flex-1 flex flex-col bg-gray-50">
          <MessageThread
            thread={selectedThread}
            messages={mockMessages}
            onSendMessage={handleSendMessage}
          />
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center bg-gray-50">
          <div className="text-center">
            <Send className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">
              Sélectionnez une conversation pour commencer
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientMessages;