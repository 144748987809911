import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Document, DocumentType } from '../../types/document';

interface DocumentCreatorProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (document: Partial<Document>) => void;
}

const DocumentCreator: React.FC<DocumentCreatorProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<Partial<Document>>({
    type: 'contract',
    title: '',
    description: '',
    content: '',
    signers: [],
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      status: 'draft',
      createdAt: new Date().toISOString(),
    });
    onClose();
  };

  const handleAddSigner = () => {
    setFormData(prev => ({
      ...prev,
      signers: [
        ...(prev.signers || []),
        {
          id: Math.random().toString(36).substr(2, 9),
          name: '',
          email: '',
          role: 'staff',
          status: 'pending',
        }
      ]
    }));
  };

  const handleRemoveSigner = (index: number) => {
    setFormData(prev => ({
      ...prev,
      signers: prev.signers?.filter((_, i) => i !== index)
    }));
  };

  const handleSignerChange = (index: number, field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      signers: prev.signers?.map((signer, i) =>
        i === index ? { ...signer, [field]: value } : signer
      )
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="relative bg-white rounded-lg max-w-3xl w-full">
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">
              Créer un nouveau document
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Type de document
                </label>
                <select
                  value={formData.type}
                  onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value as DocumentType }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                >
                  <option value="contract">Contrat</option>
                  <option value="convention">Convention</option>
                  <option value="invoice">Facture</option>
                  <option value="report">Rapport</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Titre
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={2}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Contenu du document
              </label>
              <textarea
                value={formData.content}
                onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
                rows={10}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-sm font-medium text-gray-700">Signataires</h3>
                <button
                  type="button"
                  onClick={handleAddSigner}
                  className="btn btn-secondary text-sm"
                >
                  Ajouter un signataire
                </button>
              </div>

              <div className="space-y-4">
                {formData.signers?.map((signer, index) => (
                  <div key={signer.id} className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg">
                    <div className="flex-1 grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        placeholder="Nom"
                        value={signer.name}
                        onChange={(e) => handleSignerChange(index, 'name', e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        value={signer.email}
                        onChange={(e) => handleSignerChange(index, 'email', e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                      <select
                        value={signer.role}
                        onChange={(e) => handleSignerChange(index, 'role', e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="staff">Collaborateur</option>
                        <option value="client">Client</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveSigner(index)}
                      className="text-red-600 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Créer le document
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DocumentCreator;