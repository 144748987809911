import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useMission } from '../contexts/MissionContext';
import { useNotification } from '../contexts/NotificationContext';
import MissionList from '../components/missions/MissionList';
import MissionFilters from '../components/missions/MissionFilters';
import MissionModal from '../components/missions/MissionModal';
import { useAuth } from '../contexts/AuthContext';
import type { Mission, MissionFilters as Filters } from '../types/mission';

const Missions: React.FC = () => {
  const { user } = useAuth();
  const { missions, missionRequests, updateMissionRequest, addMission } = useMission();
  const { createNotification } = useNotification();
  const [filters, setFilters] = useState<Filters>({});
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleApproveMission = async (id: string) => {
    const request = missionRequests.find(r => r.id === id);
    if (request) {
      const newMission: Mission = {
        id: Math.random().toString(36).substr(2, 9),
        title: request.title,
        clientId: request.clientId,
        clientName: request.clientName,
        clientContact: request.clientContact,
        type: request.type,
        priority: request.priority,
        status: 'approved',
        startDate: request.startDate,
        endDate: request.endDate,
        address: request.address,
        description: request.requirements?.notes,
        ratePerHour: 35,
        createdAt: request.createdAt,
      };
      
      addMission(newMission);
      updateMissionRequest(id, { 
        status: 'approved', 
        approvedAt: new Date().toISOString() 
      });

      // Notification au client
      createNotification({
        userId: request.clientId,
        type: 'success',
        title: 'Demande approuvée',
        message: 'Votre demande de mission a été approuvée',
        category: 'mission',
      });
    }
  };

  const handleRejectMission = async (id: string) => {
    const request = missionRequests.find(r => r.id === id);
    if (request) {
      updateMissionRequest(id, { 
        status: 'rejected',
        rejectedAt: new Date().toISOString()
      });

      // Notification au client
      createNotification({
        userId: request.clientId,
        type: 'error',
        title: 'Demande refusée',
        message: 'Votre demande de mission a été refusée',
        category: 'mission',
      });
    }
  };

  const handleBroadcastMission = (mission: Mission) => {
    // Envoyer une notification à tous les personnels soignants
    createNotification({
      type: 'info',
      title: 'Nouvelle mission disponible',
      message: `Une nouvelle mission "${mission.title}" est disponible`,
      category: 'mission',
      role: 'staff', // Envoyer à tous les personnels soignants
      metadata: {
        missionId: mission.id,
      },
    });

    // Mettre à jour le statut de la mission
    updateMissionRequest(mission.id, {
      status: 'broadcast',
      broadcastedAt: new Date().toISOString(),
    });
  };

  const handleViewMission = (mission: Mission) => {
    setSelectedMission(mission);
    setIsModalOpen(true);
  };

  const filteredMissions = [...missions, ...missionRequests.map(request => ({
    id: request.id,
    title: request.title,
    type: request.type,
    priority: request.priority,
    status: request.status,
    startDate: request.startDate,
    endDate: request.endDate,
    address: request.address,
    description: request.requirements?.notes,
    clientId: request.clientId,
    clientName: request.clientName,
    clientContact: request.clientContact,
    createdAt: request.createdAt,
    ratePerHour: 35,
  }))].filter(mission => {
    if (filters.type && mission.type !== filters.type) return false;
    if (filters.status && mission.status !== filters.status) return false;
    if (filters.priority && mission.priority !== filters.priority) return false;
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase();
      return (
        mission.title.toLowerCase().includes(query) ||
        mission.description?.toLowerCase().includes(query) ||
        mission.clientName?.toLowerCase().includes(query)
      );
    }
    if (filters.startDate && new Date(mission.startDate) < new Date(filters.startDate)) return false;
    if (filters.endDate && new Date(mission.endDate) > new Date(filters.endDate)) return false;
    return true;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Missions</h1>
          <p className="text-sm text-gray-600 mt-1">
            Gérez les demandes de missions
          </p>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="btn btn-primary flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Nouvelle mission</span>
        </button>
      </div>

      <MissionFilters filters={filters} onFilterChange={setFilters} />

      <MissionList
        missions={filteredMissions}
        userRole={user?.role || 'admin'}
        onApprove={handleApproveMission}
        onReject={handleRejectMission}
        onView={handleViewMission}
        onBroadcast={handleBroadcastMission}
      />

      <MissionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mission={selectedMission}
      />
    </div>
  );
};

export default Missions;