import React from 'react';
import { MapPin, Calendar, User, Clock } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const ActiveMissionsList = () => {
  const missions = [
    {
      id: '1',
      title: 'Soins quotidiens',
      staff: {
        name: 'Marie Dubois',
        role: 'Infirmière'
      },
      startDate: '2024-03-15',
      endDate: '2024-04-15',
      schedule: 'Lundi au vendredi, 9h-10h',
      address: {
        street: '15 Rue de la Santé',
        city: 'Paris',
        postalCode: '75013'
      }
    },
    {
      id: '2',
      title: 'Aide à la toilette',
      staff: {
        name: 'Jean Martin',
        role: 'Aide-soignant'
      },
      startDate: '2024-03-10',
      endDate: '2024-04-10',
      schedule: 'Lundi, mercredi, vendredi, 8h-9h',
      address: {
        street: '8 Avenue des Lilas',
        city: 'Paris',
        postalCode: '75014'
      }
    }
  ];

  return (
    <div className="space-y-4">
      {missions.map((mission) => (
        <div
          key={mission.id}
          className="p-4 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-medium">{mission.title}</h3>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              En cours
            </span>
          </div>

          <div className="space-y-2 text-sm text-gray-600">
            <div className="flex items-center">
              <User className="h-4 w-4 mr-2" />
              <span>
                {mission.staff.name} - {mission.staff.role}
              </span>
            </div>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2" />
              <span>
                Du {format(new Date(mission.startDate), 'P', { locale: fr })} au{' '}
                {format(new Date(mission.endDate), 'P', { locale: fr })}
              </span>
            </div>
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-2" />
              <span>{mission.schedule}</span>
            </div>
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-2" />
              <span>
                {mission.address.street}, {mission.address.postalCode} {mission.address.city}
              </span>
            </div>
          </div>

          <div className="mt-4 flex justify-end space-x-4">
            <button className="text-gray-600 hover:text-gray-700 text-sm font-medium">
              Historique
            </button>
            <button className="text-client-600 hover:text-client-700 text-sm font-medium">
              Voir les détails
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActiveMissionsList;