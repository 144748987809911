import React from 'react';
import { FileText, Calendar, Clock, CheckCircle, XCircle, Download, Eye, PenTool } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Document } from '../../types/document';

interface DocumentListProps {
  documents: Document[];
  onPreview: (document: Document) => void;
  onSign: (document: Document) => void;
  onDownload: (document: Document) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({
  documents,
  onPreview,
  onSign,
  onDownload,
}) => {
  const getStatusColor = (status: Document['status']) => {
    switch (status) {
      case 'draft':
        return 'text-gray-600 bg-gray-100';
      case 'pending_signature':
        return 'text-yellow-600 bg-yellow-100';
      case 'signed':
        return 'text-green-600 bg-green-100';
      case 'expired':
        return 'text-red-600 bg-red-100';
    }
  };

  const getStatusLabel = (status: Document['status']) => {
    switch (status) {
      case 'draft':
        return 'Brouillon';
      case 'pending_signature':
        return 'En attente de signature';
      case 'signed':
        return 'Signé';
      case 'expired':
        return 'Expiré';
    }
  };

  const getTypeLabel = (type: Document['type']) => {
    switch (type) {
      case 'contract':
        return 'Contrat';
      case 'convention':
        return 'Convention';
      case 'invoice':
        return 'Facture';
      case 'report':
        return 'Rapport';
    }
  };

  return (
    <div className="space-y-4">
      {documents.map((document) => (
        <div
          key={document.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-start justify-between">
            <div className="flex items-start space-x-4">
              <div className="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                <FileText className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="font-semibold text-gray-900">{document.title}</h3>
                <div className="flex space-x-2 mt-1">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                    {getTypeLabel(document.type)}
                  </span>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(document.status)}`}>
                    {getStatusLabel(document.status)}
                  </span>
                </div>
                {document.description && (
                  <p className="mt-1 text-sm text-gray-600">{document.description}</p>
                )}
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => onPreview(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Aperçu"
              >
                <Eye className="h-5 w-5" />
              </button>
              {document.status === 'pending_signature' && (
                <button
                  onClick={() => onSign(document)}
                  className="p-2 text-indigo-600 hover:bg-indigo-50 rounded-lg"
                  title="Signer"
                >
                  <PenTool className="h-5 w-5" />
                </button>
              )}
              <button
                onClick={() => onDownload(document)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                title="Télécharger"
              >
                <Download className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="flex items-center text-sm text-gray-600">
                <Calendar className="h-4 w-4 mr-2" />
                <span>
                  Créé le {format(new Date(document.createdAt), 'd MMMM yyyy', { locale: fr })}
                </span>
              </div>
              {document.expiresAt && (
                <div className="flex items-center text-sm text-gray-600">
                  <Clock className="h-4 w-4 mr-2" />
                  <span>
                    Expire le {format(new Date(document.expiresAt), 'd MMMM yyyy', { locale: fr })}
                  </span>
                </div>
              )}
            </div>

            <div className="space-y-2">
              {document.signers.map((signer) => (
                <div
                  key={signer.id}
                  className="flex items-center justify-between text-sm"
                >
                  <span className="text-gray-600">{signer.name}</span>
                  {signer.status === 'signed' ? (
                    <div className="flex items-center text-green-600">
                      <CheckCircle className="h-4 w-4 mr-1" />
                      <span>Signé</span>
                    </div>
                  ) : signer.status === 'rejected' ? (
                    <div className="flex items-center text-red-600">
                      <XCircle className="h-4 w-4 mr-1" />
                      <span>Refusé</span>
                    </div>
                  ) : (
                    <span className="text-yellow-600">En attente</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentList;