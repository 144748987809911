import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Home,
  Briefcase,
  History,
  FileText,
  Bell,
  MessageSquare,
  User,
  CreditCard,
  X
} from 'lucide-react';

interface ClientSidebarProps {
  onClose?: () => void;
}

const ClientSidebar: React.FC<ClientSidebarProps> = ({ onClose }) => {
  const menuItems = [
    { icon: Home, label: 'Tableau de bord', path: '/client/dashboard' },
    { icon: Briefcase, label: 'Demandes', path: '/client/missions' },
    { icon: History, label: 'Historique', path: '/client/history' },
    { icon: FileText, label: 'Documents', path: '/client/documents' },
    { icon: CreditCard, label: 'Facturation', path: '/client/billing' },
    { icon: MessageSquare, label: 'Messages', path: '/client/messages' },
    { icon: Bell, label: 'Notifications', path: '/client/notifications' },
    { icon: User, label: 'Mon profil', path: '/client/profile' }
  ];

  return (
    <div className="h-full flex flex-col bg-client-900 text-white">
      <div className="flex items-center justify-between p-4">
        <h1 className="text-xl font-bold">Hope-Care Intérim</h1>
        {onClose && (
          <button 
            onClick={onClose}
            className="lg:hidden p-2 rounded-md text-client-200 hover:text-white hover:bg-client-800"
          >
            <X className="h-6 w-6" />
          </button>
        )}
      </div>

      <nav className="flex-1 px-2 py-4">
        {menuItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            onClick={onClose}
            className={({ isActive }) =>
              `flex items-center px-4 py-3 text-sm rounded-lg transition-colors ${
                isActive
                  ? 'bg-client-800 text-white'
                  : 'text-client-100 hover:bg-client-800 hover:text-white'
              }`
            }
          >
            <item.icon className="h-5 w-5 mr-3" />
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>

      <div className="p-4 border-t border-client-800">
        <p className="text-xs text-client-300">
          Besoin d'aide ? Contactez-nous
        </p>
        <a href="tel:+33123456789" className="text-sm text-white hover:text-client-200">
          +33 1 23 45 67 89
        </a>
      </div>
    </div>
  );
};

export default ClientSidebar;