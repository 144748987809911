import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, Building2, Lock } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { handleFirebaseError } from '../../utils/firebase-error-handler';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [selectedRole, setSelectedRole] = useState<'admin' | 'staff' | 'client'>('admin');
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await login({ ...credentials, role: selectedRole });
      
      // Redirection selon le rôle
      const dashboardPaths = {
        admin: '/',
        staff: '/staff/dashboard',
        client: '/client/dashboard',
      };
      
      navigate(dashboardPaths[selectedRole]);
    } catch (err: any) {
      setError(handleFirebaseError(err));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-6 sm:py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* Logo ou titre */}
        <div className="flex justify-center mb-6">
          <div className="w-16 h-16 sm:w-20 sm:h-20 bg-white rounded-full flex items-center justify-center shadow-lg">
            <User className="w-8 h-8 sm:w-10 sm:h-10 text-primary-600" />
          </div>
        </div>
        <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-900 mb-2">
          Hope-Care Intérim
        </h1>
        <h2 className="text-center text-xl sm:text-2xl font-bold text-gray-900">
          Connexion
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 sm:px-10 shadow-lg rounded-lg">
          {/* Sélecteur de rôle */}
          <div className="mb-6">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
              {[
                { role: 'admin', label: 'Administrateur', color: 'primary' },
                { role: 'staff', label: 'Personnel soignant', color: 'staff' },
                { role: 'client', label: 'Client', color: 'client' },
              ].map(({ role, label, color }) => (
                <button
                  key={role}
                  onClick={() => setSelectedRole(role as 'admin' | 'staff' | 'client')}
                  className={`w-full py-2 px-3 rounded-lg text-sm sm:text-base transition-colors ${
                    selectedRole === role
                      ? color === 'primary'
                        ? 'bg-primary-600 text-white'
                        : color === 'staff'
                        ? 'bg-staff-600 text-white'
                        : 'bg-client-600 text-white'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                  }`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {selectedRole === 'client' ? (
                    <Building2 className="h-5 w-5 text-gray-400" />
                  ) : (
                    <User className="h-5 w-5 text-gray-400" />
                  )}
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="block w-full pl-10 pr-3 py-2 sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Adresse email"
                  value={credentials.email}
                  onChange={(e) =>
                    setCredentials({ ...credentials, email: e.target.value })
                  }
                />
              </div>
            </div>

            {/* Mot de passe */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Mot de passe
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="block w-full pl-10 pr-3 py-2 sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Mot de passe"
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                />
              </div>
            </div>

            {/* Message d'erreur */}
            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <p className="text-sm text-red-600 text-center">
                  {error}
                </p>
              </div>
            )}

            {/* Options de connexion */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className={`h-4 w-4 rounded border-gray-300 ${
                    selectedRole === 'admin'
                      ? 'text-primary-600 focus:ring-primary-500'
                      : selectedRole === 'staff'
                      ? 'text-staff-600 focus:ring-staff-500'
                      : 'text-client-600 focus:ring-client-500'
                  }`}
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Se souvenir de moi
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="#"
                  className={`font-medium ${
                    selectedRole === 'admin'
                      ? 'text-primary-600 hover:text-primary-500'
                      : selectedRole === 'staff'
                      ? 'text-staff-600 hover:text-staff-500'
                      : 'text-client-600 hover:text-client-500'
                  }`}
                >
                  Mot de passe oublié ?
                </a>
              </div>
            </div>

            {/* Bouton de connexion */}
            <div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  selectedRole === 'admin'
                    ? 'bg-primary-600 hover:bg-primary-700 focus:ring-primary-500'
                    : selectedRole === 'staff'
                    ? 'bg-staff-600 hover:bg-staff-700 focus:ring-staff-500'
                    : 'bg-client-600 hover:bg-client-700 focus:ring-client-500'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Connexion en cours...
                  </span>
                ) : (
                  'Se connecter'
                )}
              </button>
            </div>

            {/* Lien d'inscription */}
            {selectedRole !== 'admin' && (
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-600">
                  Pas encore inscrit ?{' '}
                  <Link
                    to="/register"
                    className={`font-medium ${
                      selectedRole === 'staff'
                        ? 'text-staff-600 hover:text-staff-500'
                        : 'text-client-600 hover:text-client-500'
                    }`}
                  >
                    Créer un compte
                  </Link>
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;