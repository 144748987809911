import React, { useState, useRef, useEffect } from 'react';
import { Send, Paperclip, User } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuth } from '../../../contexts/AuthContext';
import type { Thread, Message } from '../../../types/message';

interface MessageThreadProps {
  thread: Thread;
  messages: Message[];
  onSendMessage: (content: string) => void;
}

const MessageThread: React.FC<MessageThreadProps> = ({
  thread,
  messages,
  onSendMessage,
}) => {
  const { user } = useAuth();
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim()) return;
    
    onSendMessage(newMessage);
    setNewMessage('');
  };

  return (
    <>
      {/* En-tête */}
      <div className="p-4 border-b border-gray-200 bg-white">
        <div className="flex items-center space-x-3">
          {thread.participants[0].avatar ? (
            <img
              src={thread.participants[0].avatar}
              alt={thread.participants[0].name}
              className="h-10 w-10 rounded-full"
            />
          ) : (
            <div className="h-10 w-10 rounded-full bg-staff-100 flex items-center justify-center">
              <User className="h-5 w-5 text-staff-600" />
            </div>
          )}
          <div>
            <h2 className="text-lg font-medium text-gray-900">
              {thread.participants[0].name}
            </h2>
            <p className="text-sm text-gray-500">
              {thread.participants[0].role === 'admin' ? 'Administration' : 'Client'}
            </p>
          </div>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4">
        <div className="space-y-4">
          {messages.map((message) => {
            const isOwnMessage = message.senderId === user?.id;

            return (
              <div
                key={message.id}
                className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[70%] rounded-lg px-4 py-2 ${
                    isOwnMessage
                      ? 'bg-staff-600 text-white'
                      : 'bg-white text-gray-900'
                  }`}
                >
                  <p>{message.content}</p>
                  <p
                    className={`text-xs mt-1 ${
                      isOwnMessage ? 'text-staff-100' : 'text-gray-500'
                    }`}
                  >
                    {format(new Date(message.timestamp), 'HH:mm', { locale: fr })}
                  </p>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Formulaire d'envoi */}
      <form onSubmit={handleSubmit} className="p-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-4">
          <button
            type="button"
            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <Paperclip className="h-5 w-5" />
          </button>
          <input
            type="text"
            placeholder="Écrivez votre message..."
            className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:border-staff-500"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button
            type="submit"
            disabled={!newMessage.trim()}
            className="p-2 text-white bg-staff-600 rounded-full hover:bg-staff-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>
    </>
  );
};

export default MessageThread;