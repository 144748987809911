import React from 'react';
import { Calendar, Clock, MapPin, AlertTriangle, User } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { MissionRequest } from '../../../types/missionRequest';

interface MissionRequestListProps {
  requests: MissionRequest[];
  onEdit: (request: MissionRequest) => void;
  onCancel: (id: string) => void;
}

const MissionRequestList: React.FC<MissionRequestListProps> = ({
  requests,
  onEdit,
  onCancel,
}) => {
  const getStatusColor = (status: MissionRequest['status']) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: MissionRequest['status']) => {
    switch (status) {
      case 'pending':
        return 'En attente';
      case 'approved':
        return 'Approuvée';
      case 'rejected':
        return 'Refusée';
      case 'cancelled':
        return 'Annulée';
    }
  };

  const getPriorityColor = (priority: MissionRequest['priority']) => {
    switch (priority) {
      case 'high':
        return 'bg-red-100 text-red-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'low':
        return 'bg-green-100 text-green-800';
    }
  };

  return (
    <div className="space-y-6">
      {requests.map((request) => (
        <div
          key={request.id}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">{request.title}</h3>
              <div className="flex space-x-2 mt-1">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(request.status)}`}>
                  {getStatusLabel(request.status)}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityColor(request.priority)}`}>
                  {request.priority === 'high' ? 'Prioritaire' :
                   request.priority === 'medium' ? 'Normal' : 'Basse priorité'}
                </span>
              </div>
            </div>
            {request.assignedStaffId && (
              <div className="flex items-center space-x-2">
                <div className="h-8 w-8 rounded-full bg-client-100 flex items-center justify-center">
                  <User className="h-4 w-4 text-client-600" />
                </div>
                <span className="text-sm text-gray-600">Personnel assigné</span>
              </div>
            )}
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <span>
                Du {format(new Date(request.startDate), 'PPP', { locale: fr })} au{' '}
                {format(new Date(request.endDate), 'PPP', { locale: fr })}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>
                {request.schedule.days.map(day => format(new Date(2024, 0, day === 'sunday' ? 7 : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(day) + 1), 'EEEE', { locale: fr })).join(', ')}
                {' - '}
                {request.schedule.timeSlots.map(slot => `${slot.start} - ${slot.end}`).join(', ')}
              </span>
            </div>

            <div className="flex items-center space-x-2 text-gray-600">
              <MapPin className="h-5 w-5" />
              <span>
                {request.address.street}, {request.address.postalCode} {request.address.city}
              </span>
            </div>

            {request.requirements.notes && (
              <div className="flex items-start space-x-2 text-gray-600">
                <AlertTriangle className="h-5 w-5 mt-0.5" />
                <span>{request.requirements.notes}</span>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            {request.status === 'pending' && (
              <>
                <button
                  onClick={() => onEdit(request)}
                  className="btn btn-secondary"
                >
                  Modifier
                </button>
                <button
                  onClick={() => onCancel(request.id)}
                  className="btn text-red-600 hover:text-red-700"
                >
                  Annuler
                </button>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MissionRequestList;