import React from 'react';
import { Search, Filter } from 'lucide-react';
import type { StaffFilters } from '../../types/staff';

interface StaffFiltersProps {
  filters: StaffFilters;
  onFilterChange: (filters: StaffFilters) => void;
}

const StaffFilters: React.FC<StaffFiltersProps> = ({ filters, onFilterChange }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un collaborateur..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
              value={filters.searchQuery || ''}
              onChange={(e) =>
                onFilterChange({ ...filters, searchQuery: e.target.value })
              }
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
            value={filters.role || ''}
            onChange={(e) =>
              onFilterChange({
                ...filters,
                role: e.target.value as 'nurse' | 'caregiver' | undefined,
              })
            }
          >
            <option value="">Tous les rôles</option>
            <option value="nurse">Infirmiers</option>
            <option value="caregiver">Aides-soignants</option>
          </select>

          <select
            className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary-500"
            value={filters.status || ''}
            onChange={(e) =>
              onFilterChange({
                ...filters,
                status: e.target.value as 'active' | 'inactive' | undefined,
              })
            }
          >
            <option value="">Tous les statuts</option>
            <option value="active">Actif</option>
            <option value="inactive">Inactif</option>
          </select>

          <button
            onClick={() => onFilterChange({})}
            className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            title="Réinitialiser les filtres"
          >
            <Filter className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StaffFilters;