import React from 'react';
import { Search, Calendar } from 'lucide-react';

const BillingFilters = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une facture..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500"
            />
          </div>
        </div>

        <select className="pl-3 pr-10 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-client-500">
          <option value="">Tous les statuts</option>
          <option value="paid">Payée</option>
          <option value="pending">En attente</option>
          <option value="cancelled">Annulée</option>
        </select>
      </div>
    </div>
  );
};

export default BillingFilters;