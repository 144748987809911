import React from 'react';
import { Link } from 'react-router-dom';
import {
  Info,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Calendar,
  Trash2,
  CheckSquare,
  ExternalLink
} from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Notification } from '../../types/notification';

interface NotificationListProps {
  notifications: Notification[];
  onMarkAsRead: (id: string) => void;
  onDelete: (id: string) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  onMarkAsRead,
  onDelete,
}) => {
  const getIcon = (type: Notification['type']) => {
    switch (type) {
      case 'info':
        return <Info className="h-6 w-6 text-blue-500" />;
      case 'warning':
        return <AlertTriangle className="h-6 w-6 text-yellow-500" />;
      case 'success':
        return <CheckCircle className="h-6 w-6 text-green-500" />;
      case 'error':
        return <XCircle className="h-6 w-6 text-red-500" />;
    }
  };

  const getCategoryLabel = (category: Notification['category']) => {
    switch (category) {
      case 'mission':
        return 'Mission';
      case 'staff':
        return 'Collaborateur';
      case 'client':
        return 'Client';
      case 'document':
        return 'Document';
      case 'billing':
        return 'Facturation';
      case 'system':
        return 'Système';
    }
  };

  return (
    <div className="space-y-4">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`bg-white rounded-lg shadow-sm p-4 ${
            !notification.read ? 'border-l-4 border-indigo-500' : ''
          }`}
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              {getIcon(notification.type)}
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    {notification.title}
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {notification.message}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  {!notification.read && (
                    <button
                      onClick={() => onMarkAsRead(notification.id)}
                      className="p-1 text-gray-400 hover:text-gray-500"
                      title="Marquer comme lu"
                    >
                      <CheckSquare className="h-5 w-5" />
                    </button>
                  )}
                  <button
                    onClick={() => onDelete(notification.id)}
                    className="p-1 text-gray-400 hover:text-red-500"
                    title="Supprimer"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="mt-2 flex items-center space-x-4 text-sm text-gray-500">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {format(new Date(notification.timestamp), 'PPp', { locale: fr })}
                </div>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  {getCategoryLabel(notification.category)}
                </span>
              </div>
              {notification.actionUrl && (
                <div className="mt-2">
                  <Link
                    to={notification.actionUrl}
                    className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    Voir les détails
                    <ExternalLink className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationList;